'use client'

import { useStateLogger } from '@/features/dev-tools/components/StateLogger'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { getItineraryImageEndpoint } from '@/features/websphere/configs/external-endpoints'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect'
import { useEffect, useMemo } from 'react'
import { regionColors } from '@/utils/lib/utils'

export const PreviewSyncronizer: React.FC = () => {
	const environmentState = useEnvironmentState()
	const flyerBuilderState = useFlyerBuilderState((state) => state)
	useStateLogger('flyer-builder', flyerBuilderState)
	const sitecoreStore = useSitecoreState((state) => state)

	const defaultMscLogoFlyer = 'https://int.mscbook.com/images/sdl/msc-cruises-logo.svg'
	const textNextToShipIconLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-TEXT-NEXT-TO-SHIP-ICON', 'Book now with your travel advisor | Visit msccruises.com')
	const flyerBuilderLogo = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-MSC-LOGO', defaultMscLogoFlyer)
	const defaultPromoImageApplyOfferStep = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-DEFAULT-PROMO-LOGO', '')
	const fromLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-FROM', 'From')
	const toLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-TO', 'To')

	const updatePreview = (query: string, value: string | number | null | undefined) => {
		const domElement = document.querySelector(query)
		if (domElement) domElement.innerHTML = value?.toString() || ''
	}

	const updatePreviewImage = async (query: string, value: string | null | undefined, basePath: 'none' | 'websphere_images') => {
		const prefix = environmentState.getString('WEBSPHERE_IMAGES_ENDPOINT', '')
		const websphereResolvedEndpoint = value?.startsWith(prefix) ? value : `${prefix}/${value}`

		const domElement = document.querySelector(query) as HTMLImageElement
		if (!domElement) return

		if (!value) {
			domElement.src = 'https://archive.org/download/placeholder-image/placeholder-image.jpg'
			return
		}

		domElement.src = basePath === 'none' ? value : websphereResolvedEndpoint
	}

	// Destination step
	const destinationStep = flyerBuilderState.steps.destination
	useEffect(() => updatePreview('#destination-text', destinationStep.destination?.label.toUpperCase()), [destinationStep.destination])
	useEffect(() => {
		const textDestination = document.getElementById('destination-text')
		if (textDestination) {
			const destinationAreaCode: string | undefined = destinationStep.destination?.area
			const destinationTextColor = regionColors[destinationAreaCode ?? ''] ?? '#000000'
			textDestination.style.color = destinationTextColor
		}
	}, [destinationStep.destination?.area])

	useEffect(() => updatePreview('#flyer-title', destinationStep.headline || destinationStep.destination?.headline || ''), [destinationStep.headline, destinationStep.destination])
	useEffect(() => updatePreview('#flyer-description', destinationStep.copy || destinationStep.destination?.copy || ''), [destinationStep.copy, destinationStep.destination])

	// Itinerary step
	const currentItinerary = flyerBuilderState.steps.itinerary.itineraries[0]
	useEffect(() => updatePreview('#ship-text', currentItinerary?.ship?.label), [currentItinerary.ship])
	useEffect(() => updatePreview('#port-text', currentItinerary?.embarkationPort?.label), [currentItinerary.embarkationPort?.label])
	const currentItineraryDateRangeFormatted = useMemo(() => {
		if (!currentItinerary?.dateRange) return undefined
		const formattedDate = currentItinerary.dateRange.split('-')
		return `${fromLabel} ${formattedDate[0]} ${toLabel} ${formattedDate[1]}`
	}, [currentItinerary?.dateRange])
	useEffect(() => {
		updatePreview('#date-range', currentItineraryDateRangeFormatted)
	}, [currentItinerary?.dateRange])

	useEffect(() => updatePreview('#departure-day-text', currentItinerary.departureWeekDay), [currentItinerary.departureWeekDay])
	useEffect(() => updatePreview('#days-nights', currentItinerary.daysNights), [currentItinerary.daysNights])
	useEffect(() => {
		const { price, hsc } = currentItinerary.price ?? { price: 0, hsc: 0 }
		updatePreview('#price-without-hsc', price?.toString() || '0')
		updatePreview('#hsc-price', hsc?.toString() || '0')
		updatePreview('#full-price', (price + (hsc ?? 0)).toString() || '0')
	}, [currentItinerary.price])
	useAsyncEffect(() => updatePreviewImage('#flyer-itinerary-single .itinerary-image', currentItinerary.itineraryCode && getItineraryImageEndpoint(currentItinerary.itineraryCode), 'none'), [currentItinerary.itineraryCode])

	// Price list step
	const priceListStep = flyerBuilderState.steps.priceList
	useEffect(() => updatePreview('#below-price-paragraph', priceListStep.offer?.termsAndConditions), [priceListStep.offer?.termsAndConditions])
	useEffect(() => updatePreview('#promotion-text', priceListStep.priceList?.name.toUpperCase()), [priceListStep.priceList])
	useEffect(() => updatePreview('#flight-label', priceListStep.flightInformation), [priceListStep.flightInformation])
	useEffect(() => {
		const flightInformationFlightFromElement = document.getElementById('flight-from')
		if (flightInformationFlightFromElement) {
			//hide 'Flight from' if no text typed
			flightInformationFlightFromElement.style.display = ['<br>', undefined, ''].includes(priceListStep?.flightInformation) ? 'none' : 'block'
		}
	}, [priceListStep?.flightInformation])
	useAsyncEffect(() => updatePreviewImage('#flyer-visual-img', priceListStep.replaceWithHeroImage ? priceListStep.offer?.heroImage : destinationStep.imageSrc, 'websphere_images'), [destinationStep.imageSrc, priceListStep.replaceWithHeroImage])
	useAsyncEffect(() => updatePreviewImage('#promotion-image', priceListStep.promotionImage, 'websphere_images'), [priceListStep.promotionImage])
	useEffect(() => {
		// Delete previous icons
		const elements = document.querySelectorAll('.promotion-icon')
		elements.forEach((element) => element.remove())

		if (!priceListStep.priceList?.icons) return

		const iconContainer = document.getElementById('promotion-icons')
		const promotionText = document.getElementById('promotion-text')
		priceListStep.priceList.icons.forEach((icon) => {
			const img = document.createElement('img')
			img.className = 'promotion-icon'
			img.style.height = '20px'
			img.style.width = '20px'
			img.crossOrigin = 'anonymous'
			img.src = environmentState.getString('WEBSPHERE_BASE_ENDPOINT', 'mscbook.com') + icon.url
			iconContainer?.insertBefore(img, promotionText)
		})
	}, [priceListStep.priceList])

	// Notes step
	const additionalNoteStep = flyerBuilderState.steps.notes
	useEffect(() => updatePreview('#user-input-notes-step', additionalNoteStep.additionalNote), [additionalNoteStep.additionalNote])

	// Agent details step
	const agentDetailsStep = flyerBuilderState.steps.agentDetails
	useEffect(() => updatePreview('#agencyName', agentDetailsStep.name), [agentDetailsStep.name])
	useEffect(() => updatePreview('#email', agentDetailsStep.email), [agentDetailsStep.email])
	useEffect(() => updatePreview('#phone', agentDetailsStep.phoneNumber), [agentDetailsStep.phoneNumber])
	useAsyncEffect(() => updatePreviewImage('#agency-logo', agentDetailsStep.logo, 'none'), [agentDetailsStep.logo])
	useEffect(() => {
		const instagramLogo = document.getElementById('instagramLogo')
		const facebookLogo = document.getElementById('facebookLogo')
		if (instagramLogo && facebookLogo) {
			instagramLogo.style.display = agentDetailsStep.socialInfo?.includes('instagram') ? 'block' : 'none'
			facebookLogo.style.display = agentDetailsStep.socialInfo?.includes('facebook') ? 'block' : 'none'
		}
		// update href, to include it as a usable link in the pdf generation
		const facebookOrInstagram = document.getElementById('facebookOrInstagram')
		if (facebookOrInstagram instanceof HTMLAnchorElement) {
			facebookOrInstagram.href = agentDetailsStep.socialInfo || ''
		}

		updatePreview('#facebookOrInstagram', agentDetailsStep.socialInfo)
	}, [agentDetailsStep.socialInfo])

	// default updates
	useEffect(() => updatePreview('#textNextToShipIcon', textNextToShipIconLabel), [textNextToShipIconLabel])
	useAsyncEffect(() => updatePreviewImage('main img', flyerBuilderLogo || defaultMscLogoFlyer, 'websphere_images'), []) // MSC logo
	useAsyncEffect(() => {
		// default promo image if none selected
		if (flyerBuilderState.getActiveStep() === 'priceList') {
			priceListStep.promotionImage || updatePreviewImage('#promotion-image', defaultPromoImageApplyOfferStep, 'websphere_images')
		}
	}, [flyerBuilderState.getActiveStep()])

	return <></>
}
