'use client'

import { useEffect } from 'react'
import { StateToShow } from '../models/dev-tools-model'
import { useDevToolsState } from '../store/useDevToolsState'

export interface StateLoggerProps {
	name: StateToShow
	state: any
}

export const useStateLogger = (name: StateToShow, state: any) => {
	const store = useDevToolsState()

	useEffect(() => {
		if (store.showState !== name) return
		console.log(`state: ${name}`, state)
	}, [state])
}
