'use client'

import { Step } from '@/features/flyer/configs/steps'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { AgentDetailsStep, DestinationStep, FlyerStep, ItineraryStep, NotesStep, SelectPriceListStep } from '../uiComponents'

export const FlyerBuilderActions = () => {
	const { getActiveStep } = useFlyerBuilderState((state) => state)

	const stepComponents: { [key in Step]: JSX.Element } = {
		destination: <DestinationStep />,
		itinerary: <ItineraryStep />,
		priceList: <SelectPriceListStep />,
		agentDetails: <AgentDetailsStep />,
		notes: <NotesStep />,
		flyer: <FlyerStep />,
	}

	return <div className="h-full py-8 px-6 shadow-md overflow-y-scroll pb-28">{stepComponents[getActiveStep()]}</div>
}
