const mockedFlyerTemplate = `<main style="display: flex; flex-direction: column; width: 100%" class="pangea-font">
    <style>
        .pangea-font {    
            font-family: var(--font-pangea);
        }
    </style>
    <section style="width: 100%; background-color: #182541;">
        <div>
            <img src="https://int.mscbook.com/images/sdl/msc-cruises-logo.svg" style="width: 180px; height: 72px; margin: 0 auto;" alt="MSC Cruises logo">
        </div>
    </section>
    <section id="flyer-visual" style="position: relative; width: 100%; height: 200px; background-size: cover; background-position: center center">
        <img id="flyer-visual-img" src="" style="width: 100%; border: 1px solid #add8e6; height: 100%; object-fit: cover; object-position: center center;" alt="Cruise Flyer promoting MSC Cruises">
    </section>
    <section id="title-section" style="text-align: center; margin: 0px 0; max-width: 640px">
        <p id="flyer-title" style="font-size: 20px; line-height: 1.1; color: #000033; margin-top: 8px; margin-bottom: 5px; text-transform: uppercase; padding: 0 16px; overflow-wrap: break-word; white-space: normal;"></p>  
        <p id="flyer-description" style="text-align: center; font-size: 14px;  line-height: 1.2; color: #000033; margin-bottom: 12px; padding: 0 16px; overflow-wrap: break-word; white-space: normal;"></p>
    </section>
    <!-- If only one itinerary -->
    <section id="flyer-itinerary-single">
        <div style="display: flex; gap: 16px">
            <img src="" alt="MSC Cruises itinerary" style="width: 225px; height: 150px; border: 1px solid #add8e6; border-radius: 8px; object-fit: cover; background-position: center center;" class="itinerary-image">
            <div style="flex: 1">
                <p id="destination-text" style="color: #EE732F; padding-bottom: 5px; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;"></p>
                <p id="ship-text" style="color: #000033; padding-bottom: 5px; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;"></p>
                <p style="font-size: 14px; padding-bottom: 1px; line-height: 1.2;">Departure from<span id="port-text" style="margin-left: 5px;"></span></p>
                <p style="font-size: 14px; line-height: 1.2; padding-bottom: 1px;">On<span id="departure-day-text" style="margin-left: 5px;"></span></p>
                <p style="font-size: 14px; line-height: 1.2; padding-bottom: 1px;"><span id="date-range"></span></p>
                <p style="font-size: 14px; line-height: 1.2; padding-bottom: 1px;"><span id="days-nights"></span></p>
                <div id="promotion-icons" style="display: flex; align-items: center; justify-content: flex-start; gap: 6px">
                    <p id="promotion-text" style="color: #000033; font-weight: 700; text-transform: uppercase"></p>
                </div>
                <p id="flight-from" style="display: none; font-size: 14px; line-height: 1.2; padding-bottom: 1px;">Flight from &nbsp;<span id="flight-label"></span></p>
            </div>
        </div>
        <div style="display: flex; gap: 25px; align-items: center; justify-content: center; padding: 8px; margin-top: 12px; border-radius: 16px; border: 1px solid #000033;">
            <div style="padding: 0px 16px;">
                <img id="promotion-image" src="" style="background-color: #5ac5f3; height: 45px; width: 80px; border: 1px solid #add8e6;" alt="MSC Cruises special promotion logo">
            </div>
            <div id="hsc-price-section" style="display: flex; justify-content: center; align-items: center; flex: 3; text-align: center; border-left: 1px solid #000033; height: 47px;">
                <div style="text-align: center; font-size: 12px; line-height: 1.1; font-weight: 500; color: #000033;">
                    <p style="display: inline;">Starting from &nbsp;</p>
                    <p class="toLiftText currency" style="display: inline; font-size: 20px; line-height: 1.1;">€ &nbsp;</p>
                    <p class="toLiftText" id="price-without-hsc" style="display: inline; font-size: 20px; line-height: 1.1;">0.000</p>
                    <p style="display: inline;">p.p.</p>
                    <p style="display: inline; margin-left: 5px">+</p>
                    <div style="height: 5px;"></div>
                    <p style="display: inline;">service charge &nbsp;</p>
                    <p class="toLiftText currency" style="display: inline; font-size: 12px; line-height: 1.1;">€ &nbsp;</p>
                    <p id="hsc-price" class="toLiftText" style="display: inline; font-size: 12px; line-height: 1.1;">00</p>
                    <p style="display: inline;">p.p.</p>
                </div>
            </div>
            <div  id="total-Price" style="display: block; flex: 3; text-align: center; border-left: 1px solid #000033; height: 47px;">
                <div style="text-align: center; font-size: 13px; line-height: 1.1; font-weight: 700; color: #000033;">
                    <p style="display: inline; text-transform: uppercase;">TOTAL PRICE FROM</p>
                    <br>
                    <p class="toLiftText currency" style="display: inline; font-size: 30px; line-height: 1.1;">€ &nbsp;</p>
                    <p id="full-price" class="toLiftText" style="display: inline; font-size: 30px; line-height: 1.1;">0.000</p>
                    <p style="display: inline;">p.p.</p>
                </div>
            </div>
        </div>
        <div style="font-size: 8px; line-height: 1.2; color: #000033; text-align: center; margin-top: 5px; margin-bottom: 5px">
            <p id="below-price-paragraph" style="display: inline; margin: 0;"></p>
            <p id="user-input-notes-step" style="display: inline; overflow-wrap: break-word; white-space: normal;"></p>
        </div>
    </section>
   
    <section style="width: 100%; background-color: #182541; padding: 8px 0">
        <div style="display: flex; height: 10px; margin: 8px auto; justify-content: space-around; align-items: center;">
            <img src="https://www.mscbook.com/images/sdl/en-GB/B2B_DOCS_BYF_EU_miniature_sid.svg" style="width: 140px; height: 56px; margin-top: 25px;" alt="MSC Cruises ship">
            <p id="textNextToShipIcon" style="color: white; font-size: 12px;"></p>
        </div>
    </section>
   
    <section id="last-paragraph">
        <div style="display: flex; width: 100%; padding: 5px; box-sizing: border-box; min-height: 90px;">
            <div style="flex: 1; display: flex; align-items: center; justify-content: center; padding: 5px;">
                <img id="agency-logo" src="" style="width: 70px;" alt="MSC Cruises partner agency logo">
            </div>
            <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding: 5px;">
                <p style="margin-bottom: 2px; font-size: 12px;"> <span id="agencyName"></span></p>
                <p style="margin-bottom: 2px; font-size: 12px;"> <span id="email"></span></p>
                <p style="margin-bottom: 2px; font-size: 12px;"> <span id="phone"></span></p>
                <div style="margin-bottom: 2px; display: flex; align-items: center; gap: 5px;">
                    <img id="instagramLogo" width="12" height="12" src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg" crossorigin="anonymous" style="width: 12px; height: 12px; object-fit: contain; display: none" alt="instagram logo">
                    <img id="facebookLogo" width="12" height="12" src="https://upload.wikimedia.org/wikipedia/commons/b/b9/2023_Facebook_icon.svg" crossorigin="anonymous" style="width: 12px; height: 12px; object-fit: contain; display: none" alt="facebook logo">                     
                    <p>
                        <a href="" target="_blank" class="lowerDownloadTranslation" id="facebookOrInstagram" style="font-size: 12px; color: #0000EE; line-height: 12px; display: flex; align-items: center; margin: 0; padding-top: 2px"></a>
                    </p>
                </div>
            </div>
        </div>    
    </section>
    <!-- If multiple itinerary -->
    <section id="flyer-itinerary-multiple" style="min-height:152px; display: none; margin-bottom: 15px">
        <div style="display: flex; flex-direction: row; align-items: center; gap: 24px">
            <img class="itinerary-image" src=""
                style="flex: 1; border: 2px solid #add8e6; border-radius: 8px; background-color: #d4edfc; background-size: cover; background-position: center center; width: 220px"
                alt="MSC Cruises itinerary">
            <div style="flex: 1.5">
                <p class="destination-text"
                    style="color: #EE732F; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">
                </p>
                <p class="ship-text"
                    style="color: #000033; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">
                </p>
                <p style="font-size: 14px; line-height: 1.2;">
                    <span class="day-start"></span>
                    <span class="nights-length-text"></span>
                </p>
                <div>
                    <p style="font-size: 14px; line-height: 1.2; font-weight: 700; text-transform: uppercase;">
                        <span class="promotion-text" style="color: #000033; font-weight: 700; text-transform: uppercase"></span>     
                    </p>
                </div>
                <div style="display: flex; align-items: center; padding: 8px; margin-top: 8px; border-radius: 16px; border: 1px solid #000033;">
                    <div style="flex: 2; padding: 0px 16px; text-align: center">
                        <p style="text-align: center; font-size: 13px; line-height: 1.1; font-weight: 700; color: #000033; display: inline">TOTAL PRICE FROM</p>
                        <p style="font-weight: 700">
                            <span class="currency" style="font-size: 30px; line-height: 1.1;">€</span>
                            <span style="font-size: 30px; line-height: 1.1;" id="total-price">0.000</span>
                            p.p.
                        </p>
                        <p style="font-size: 11px; line-height: 1;"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- If multiple itinerary -->
</main>`

export default mockedFlyerTemplate
