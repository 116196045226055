import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as Seasons from './actions/seasons'

// eslint-disable-next-line prettier/prettier
// prettier-ignore
const FlyerService = createProxiedActions<
	typeof Seasons
>({
	modules: [
		Seasons,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default FlyerService
