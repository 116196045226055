'use client'

import FormFactory, { SchemaItem } from '@/components/factory/formFactory'
import AgentService from '@/features/agent/agent-service'
import AuthService from '@/features/auth/auth-service'
import { Result } from '@/features/auth/models/authenticate-service-response'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { Form, FormProps } from '@/utils/components/Form'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import { useRouter, useSearchParams } from 'next/navigation'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Alert, Button, HelpIcon, SeparatorText } from '../uiComponents'

interface LoginFormInputs {
	username: string
	password: string
}

export interface LoginModalTemplateProps {
	nextUrl?: string
}

export const LoginModalTemplate = (props: LoginModalTemplateProps) => {
	const searchParams = useSearchParams()
	const market = useCurrentMarket()
	const router = useRouter()
	const { pushLabelClickedEvent } = useGoogleAnalytics()
	const form = useForm<LoginFormInputs>()

	const [error, setError] = useState<string | undefined>(undefined)
	const [redirectToConsentFormProps, setRedirectToConsentFormProps] = useState<FormProps | null>(null)

	const sitecoreStore = useSitecoreState((state) => state)
	const genericErrorDuringLogin = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-ERROR_ON_FIELD', 'Login error. Please check your username and password.')
	const resetPasswordError = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-GENERIC-ERROR-DURING-RESET-PASSWORD', 'An error has occured during the reset password')
	const invalidUsername = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-INVALID-USERNAME', 'Invalid username')
	const loginLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-LOGIN-BUTTON-LABEL', 'Login')
	const usernamePlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-USERNAME-PLACEHOLDER', 'Username')
	const passwordPlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-PASSWORD-PLACEHOLDER', 'Password')
	const forgotPasswordLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-FORGOT-PASSWORD-LABEL', 'Forgot password ?')
	const contactUsLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-CONTACT-MSC-LABEL', 'Contact us')
	const sendPasswordLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-SEND_PASSWORD', 'Send password')
	const noAccountLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-NO-ACCOUNT', "Don't have an account")
	const defaultLogInModalInfo = `If your agency is already registered, please contact the agency administrator for assistance with your credentials.If the agency is not yet registered, you'll need to register before accessing.`
	const logInModalInfoLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-REGISTER-INFO-FOOTER', defaultLogInModalInfo)
	const beginRegistrationProcessLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOG_IN-BEGIN-REGISTRATION', 'Click here to begin the registration process')
	const loggedSince = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-USER_ALREADY_LOGGED_SINCE', 'User already logged in since')
	const loggedSinceConfim = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP-ENFORCE_LOGIN', 'Do you want to enforce login?')

	const loginSchema: SchemaItem[] = [
		{
			type: 'text',
			name: 'username',
			label: usernamePlaceholderLabel,
			required: true,
			autoComplete: 'username',
		},
		{
			type: 'password',
			name: 'password',
			label: passwordPlaceholderLabel,
			required: true,
			autoComplete: 'current-password',
		},
	]

	const loginSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
		const existValidSession = await checkIfExistValidSession(data)
		if (!existValidSession) await invokeLogon(data)
	}

	const checkIfExistValidSession: SubmitHandler<LoginFormInputs> = async (data): Promise<boolean> => {
		const { username, password } = data
		const existingSession = await AuthService.isUserAlreadyLogged(username.toLowerCase(), password)

		if (existingSession === null) return false

		useModalState.getState().openInfoModal({
			variant: 'confirm-abort',
			confirmBehavior: async () => invokeLogon(data),
			children: `${loggedSince} ${existingSession.lastLoginDate}.\n${loggedSinceConfim}`,
		})
		return true
	}

	const invokeLogon: SubmitHandler<LoginFormInputs> = async (data) => {
		const { username, password } = data
		const loginResult = await AuthService.login(username.toLowerCase(), password)

		if (loginResult.result === Result.PASSOWORD_EXPIRED) return useModalState.getState().openChangePasswordModal({ agentInfo: loginResult.changePassword! })

		if (loginResult.result === Result.CONSENT_TO_ACCEPT) {
			const ssoUrl = `/api/${market.locale}/sso?username=${data.username.toLowerCase()}&password=${data.password}&nextUrl=home`
			return setRedirectToConsentFormProps({
				action: '/shop/ConsentView',
				sendMarket: true,
				params: {
					URLAfterConsent: ssoUrl,
					pwdForConsent: loginResult.consentToAccept?.passwordForConsent,
					marketCode: market.code,
					consentStatus: 'MUST_TO_BE_ACCEPTED',
					logonId: data.username.toLowerCase(),
					reLogonURL: 'LogonForm',
					marketCodeForExternal: market.code,
				},
			})
		}

		if (loginResult.result == Result.SUCCESS) {
			useModalState.getState().close()
			return navigateToNextPageOrWelcome()
		}

		setError(genericErrorDuringLogin)
		pushLabelClickedEvent({ event: 'modalerrors', eventName: 'modal_errors', label_clicked: 'Wrong Usernm or Psw' })
	}

	const navigateToNextPageOrWelcome = async () => {
		const nextUrl: string | null = props.nextUrl || searchParams.get('nextUrl')
		if (nextUrl) {
			router.push(decodeURIComponent(nextUrl))
		} else {
			router.push(`/${market.locale}/home`)
		}
	}

	const resetPassword = async () => {
		const username = form.getValues().username?.toLowerCase()
		if (!username) return setError(invalidUsername)
		try {
			await AgentService.forgotPassword(username)
			useModalState.getState().openInfoModal({
				variant: 'ok',
				children: sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-CONFIRMATIONPAGE-CONFIRMATION_SENDING-HOME_PAGE', 'Email has been sent successfully'),
			})
		} catch {
			setError(resetPasswordError)
		}
	}

	return (
		<>
			{redirectToConsentFormProps && <Form {...redirectToConsentFormProps} />}
			<form className="flex flex-col gap-6 w-full z-50" noValidate onSubmit={form.handleSubmit(loginSubmit)}>
				{loginSchema.map((schemaItem, index) => (
					<div className="w-full" key={index}>
						<FormFactory control={form.control} errors={form.formState.errors} {...schemaItem} />
					</div>
				))}

				<p className="text-light-purple mt-2">
					{forgotPasswordLabel}
					<a //left a tag here instead of Link since we are not using href to navigate
						className="font-semibold underline cursor-pointer pl-2"
						onClick={async () => {
							await resetPassword()
							pushLabelClickedEvent({ event: 'modalogin', eventName: 'modal_login_btn', label_clicked: 'Reset Psw' })
						}}
					>
						{sendPasswordLabel}
					</a>
				</p>

				<Button type="submit" className="w-full mt-4" onClick={() => pushLabelClickedEvent({ event: 'modalogin', eventName: 'modal_login_btn', label_clicked: 'Login btn' })} isSubmitting={form.formState.isSubmitting}>
					{loginLabel}
				</Button>

				{error && <Alert text={error} />}

				<div className="mt-2">
					<SeparatorText color="light-purple">{noAccountLabel}</SeparatorText>
				</div>

				<div className="block border border-light-grey rounded-lg p-4">
					<p className="text-sm text-msc-blue pr-2 inline" dangerouslySetInnerHTML={{ __html: logInModalInfoLabel }}></p>
					<button
						className="text-secondary underline inline"
						onClick={() => {
							useModalState.getState().openRegisterYourAgencyModal()
							pushLabelClickedEvent({ event: 'modalogin', eventName: 'modal_login_btn', label_clicked: 'Link Registration' })
						}}
					>
						{beginRegistrationProcessLabel}
					</button>
				</div>
				<div className="flex w-full justify-center align-items gap-1">
					<HelpIcon />{' '}
					<p
						className="text-secondary"
						onClick={() => {
							useModalState.getState().openContactUsModal()
							pushLabelClickedEvent({ event: 'modalogin', eventName: 'modal_login_btn', label_clicked: 'Contact us' })
						}}
					>
						<a href="#" className="font-bold underline">
							{contactUsLabel}
						</a>{' '}
					</p>
				</div>
			</form>
		</>
	)
}
