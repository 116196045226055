'use client'

import AuthService from '@/features/auth/auth-service'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { createConfigForAuthSession } from '@/features/caching/config/tanstack'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { CurrentMarket } from '@/features/markets/models/market'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import { useQueryClient } from '@tanstack/react-query'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import React from 'react'
import { FaUser } from 'react-icons/fa'
import { Button, ChangeLanguage, CountryFlag, CountrySelectionLink, NotificationButton } from '../uiComponents'

interface HeaderButtonsProps {
	countryFlag: React.ReactElement<typeof CountryFlag>
}

export const HeaderButtons = (props: HeaderButtonsProps) => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const environment = useEnvironmentState()
	const provideUrl = useUrlProvider()
	const queryClient = useQueryClient()
	const router = useRouter()
	const userAccountUrl = provideUrl('user-account')
	const { pushLabelClickedEvent } = useGoogleAnalytics()

	const userIcon = <FaUser color="white" size={18} />

	const logout = async (market: CurrentMarket) => {
		pushLabelClickedEvent({ event: 'privatemenu', eventName: 'private_right_menu', label_clicked: 'Signout' })
		await AuthService.websphereLogout(market)
		await signOut({ redirect: false })
		queryClient.refetchQueries(createConfigForAuthSession())
		router.push(`/${market.locale}/welcome`)
	}

	// translation labels
	const sitecoreStore = useSitecoreState((state) => state)
	const registerYourAgencyLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-REGISTER-BUTTON-LABEL', 'Register your agency')
	const loginLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-LOGIN-BUTTON-LABEL', 'Login')
	const logoutLabel = sitecoreStore.getLabel(TranslationBundles.HEADER, 'MSC-HEADER-SIGN_OUT', 'Logout')
	const agencyDirectLabel = sitecoreStore.getLabel(TranslationBundles.HEADER, 'MSC-HEADER-DIRECTPAYMENT-LABEL', 'Agency Direct')
	const agencyCollectionLabel = sitecoreStore.getLabel(TranslationBundles.HEADER, 'MSC-HEADER-AGENCYCOLLECTION-LABEL', 'Agency Collection')

	const isAgencyTypeLabelActive = environment.isFeatureEnabled('ENABLE_SHOW_AGENCY_TYPE_DIRECT', market)
	const agencyType = user?.agencyType
	const isAgencyTypeAgencyDirect = agencyType?.toLowerCase() === 'direct'

	const loggedUI = (
		<>
			<ChangeLanguage className="hidden lg:flex" />
			<Link prefetch={false} href={userAccountUrl} className="text-xs hidden md:block">
				{user?.name}&nbsp;{user?.surname}
				{isAgencyTypeLabelActive && (isAgencyTypeAgencyDirect ? <p className="pt-2 text-right">{agencyDirectLabel}</p> : <p className="pt-2 text-right">{agencyCollectionLabel}</p>)}
			</Link>
			<Button onClick={() => logout(market)} variant="filled" bgColor="primary" className="flex justify-center items-center gap-2 text-xs lg:text-sm">
				{logoutLabel}
			</Button>
		</>
	)

	const notLoggedUI = (
		<div className="flex gap-6 lg:text-sm">
			<ChangeLanguage className="hidden lg:flex" />
			<Button
				onClick={() => {
					useModalState.getState().openRegisterYourAgencyModal()
					pushLabelClickedEvent({ event: 'menuClick', eventName: 'menu_click', label_clicked: 'Registration' })
				}}
				isIconRight={false}
				variant="outlinedSecondary"
				className="hidden lg:block text-white normal-case border-2 border-[#EE732F] px-3 py-2 flex justify-center items-center desktop-sm:text-xs desktop-xsm:hidden"
			>
				{registerYourAgencyLabel}
			</Button>
			<Button
				onClick={() => {
					useModalState.getState().openLoginModal()
					pushLabelClickedEvent({ event: 'menuClick', eventName: 'menu_click', label_clicked: 'Login access' })
				}}
				icon={userIcon}
				variant="filled"
				bgColor="primary"
				className="flex justify-center items-center gap-2 desktop-sm:text-sm desktop-xsm:text-xs"
			>
				<span className="hidden md:block">{loginLabel}</span>
			</Button>
		</div>
	)

	const isNotificationsEnabled = environment.isFeatureEnabled('ENABLE_NOTIFICATIONS', market)

	return (
		<div className="flex justify-center items-center mr-6 desktop-min-1200px:mr-0 gap-3 md:gap-6 desktop-xsm:gap-2">
			{user !== undefined && isNotificationsEnabled && <NotificationButton sendHeaderButtonsGA4Event={(label_clicked, event, eventName) => pushLabelClickedEvent({ event, eventName, label_clicked })} />}
			<div className="hidden lg:block">
				<CountrySelectionLink onClick={() => pushLabelClickedEvent({ event: 'menuClick', eventName: 'menu_click', label_clicked: 'Flag' })}>{props.countryFlag}</CountrySelectionLink>
			</div>
			{user !== undefined ? loggedUI : notLoggedUI}
		</div>
	)
}
