'use client'

import { createFlyerBuilderState, FlyerBuilderActions, FlyerBuilderState } from '@/features/flyer/store/flyer-builder-state'
import { createContext, useContext, useRef } from 'react'
import { StoreApi, useStore } from 'zustand'

interface FlyerBuilderProviderProps {
	children: React.ReactNode
	initialState: FlyerBuilderState
}

const FlyerBuilderStateContext = createContext<StoreApi<FlyerBuilderState & FlyerBuilderActions> | null>(null)

export function FlyerBuilderStateProvider(props: FlyerBuilderProviderProps) {
	const storeRef = useRef<StoreApi<FlyerBuilderState & FlyerBuilderActions> | null>(null)

	if (!storeRef.current) {
		storeRef.current = createFlyerBuilderState(props.initialState)
	}

	return <FlyerBuilderStateContext.Provider value={storeRef.current}>{props.children}</FlyerBuilderStateContext.Provider>
}

export function useFlyerBuilderState<T>(selector: (state: FlyerBuilderState & FlyerBuilderActions) => T): T {
	const store = useContext(FlyerBuilderStateContext)

	if (!store) throw new Error('Missing ElasticFilterProvider')

	return useStore(store, selector)
}

export function useReadonlyFlyerBuilderState() {
	const store = useContext(FlyerBuilderStateContext)
	if (!store) throw new Error('Missing FlyerBuilderStateProvider')
	return store
}
