export type CurrencyCode = 'EUR' | 'USD' | 'GBP' | 'AUD' | 'BRL' | 'CAD' | 'CHF' | 'DKK' | 'NOK' | 'NZD' | 'SEK' | 'ZAR' | 'CNY'

export const currencySymbolMap: { [key in CurrencyCode]: string } = {
	EUR: '€',
	USD: '$',
	GBP: '£',
	AUD: 'A$',
	BRL: 'R$',
	CAD: 'C$',
	CHF: 'CHF',
	DKK: 'kr',
	NOK: 'kr',
	NZD: 'NZ$',
	SEK: 'kr',
	ZAR: 'R',
	CNY: '¥',
}
