'use client'

import { Button, OrangeCheckbox, RichedTextArea, SwiperWrapperBYF } from '@/components/uiComponents'
import { createConfigForHSCConfig } from '@/features/caching/config/tanstack'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { HscConfig } from '@/features/elastic/configs/pricesb2b'
import { getMinPriceToShow, Options } from '@/features/elastic/lib/min-price-calculation'
import { getPriceEntryIcons } from '@/features/elastic/lib/price-entry-icons'
import { PriceList, PriceListEntry } from '@/features/elastic/model/elastic-search'
import { getOffersFromSitecore } from '@/features/flyer/lib/utils'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { ContentEditableEvent } from 'react-simple-wysiwyg'

export const SelectPriceListStep = () => {
	const flyerBuilderState = useFlyerBuilderState((state) => ({
		itinerary: state.steps.itinerary.itineraries[0],
		priceListStep: state.steps.priceList,
		updateItinerary: state.updateItinerary,
		updateStepData: state.updateStepData,
	}))

	const environment = useEnvironmentState()
	const market = useCurrentMarket()
	const hscConfig = useQuery(createConfigForHSCConfig())
	const catEntryPriceList = flyerBuilderState.itinerary.pricesB2BResponse?.Results.result.find((result) => result.cruiseID === flyerBuilderState.itinerary.cruise?.cruiseId)?.catEntryPriceList || []
	const [priceListBoxes, setPriceListBoxes] = useState<PriceList[]>([])

	const { pushLabelClickedEvent } = useGoogleAnalytics()
	useEffect(() => pushLabelClickedEvent({ event: 'stepView', eventName: 'step_view', label_clicked: 'Apply offer' }), [])

	const sitecoreStore = useSitecoreState((state) => state)
	const applyPromotionLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-APPLY-PROMOTION', 'Apply offer')
	const replaceWithPromoImageLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-REPLACE-DEST-WITH-PROMO-IMG', 'Replace the destination image with promotional image')
	const selectPromoLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-SELECT-PROMO-IMG', 'Select promo logo')
	const offersBundle = sitecoreStore.getBundle(TranslationBundles.BUILD_YOUR_FLYER_OFFERS)
	const excludedPriceTypes = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-EXCLUDED-PRICETYPES', '').split(',')
	const flightInformationTitleLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-FLIGHT-TEXT-EDITOR-TITLE', 'Add departure airport(s)')
	const flightInformationMaxLength = 30
	const textCountLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADDITIONAL-TEXT', 'Text count')

	const groupedPriceIdsOfSelectedCruise = useMemo(
		() =>
			catEntryPriceList.reduce(
				(acc, priceListEntry) => {
					if (!acc[priceListEntry.priceId]) acc[priceListEntry.priceId] = []
					acc[priceListEntry.priceId].push(priceListEntry)
					return acc
				},
				{} as { [key: string]: PriceListEntry[] }
			),
		[flyerBuilderState.itinerary.pricesB2BResponse]
	)

	const offers = useMemo(
		() =>
			getOffersFromSitecore(
				offersBundle,
				Object.values(groupedPriceIdsOfSelectedCruise).map((priceList) => priceList[0].priceName)
			),
		[flyerBuilderState.itinerary.pricesB2BResponse]
	)

	const minPriceOptions = (hscConfig: HscConfig): Options => ({
		isHSCEnabledForCurrentMarket: hscConfig.enabled,
		showHscSummedToBasePrice: hscConfig.summedToBasePrice,
		considerOnlyAvailableCabins: true,
		pricePer: environment.isFeatureEnabled('ENABLE_BYF_PRICE_PER_CABIN', market) ? 'cabin' : 'adult',
	})

	const extractPriceListData = (hscConfig: HscConfig): PriceList[] =>
		Object.values(groupedPriceIdsOfSelectedCruise)
			.filter((priceList) => !excludedPriceTypes.includes(priceList[0].priceType))
			.map((priceList) => {
				const priceData = getMinPriceToShow(priceList, minPriceOptions(hscConfig))
				return {
					id: priceList[0].priceId,
					name: priceList[0].priceName,
					minPrice: priceData ? priceData : { price: 0, hsc: 0 },
					icons: getPriceEntryIcons(priceList[0]),
					availableCabins: priceList.reduce((acc, curr) => acc + curr.availability, 0) > 0,
				}
			})

	useEffect(() => {
		if (hscConfig.data) {
			const priceListData = extractPriceListData(hscConfig.data)
			setPriceListBoxes(priceListData)
			if (!flyerBuilderState.priceListStep.priceList) {
				const priceListWithMinimum = priceListData.find((priceList) => priceList.minPrice.price === flyerBuilderState.itinerary.price?.price)
				if (!priceListWithMinimum) return
				flyerBuilderState.updateStepData('priceList', { priceList: priceListWithMinimum, offer: offers[priceListWithMinimum.name] })
			}
		}
	}, [hscConfig.data, groupedPriceIdsOfSelectedCruise])

	const handlePriceNameClick = (priceList: PriceList) => {
		flyerBuilderState.updateItinerary(0, { price: priceList.minPrice })
		flyerBuilderState.updateStepData('priceList', { priceList, offer: offers[priceList.name] })
	}

	const handlePromotionImageClick = (promotionImage: string) => {
		flyerBuilderState.updateStepData('priceList', { promotionImage })
	}

	const handleReplaceWithHeroImageClick = () => {
		const replaceWithHeroImage = !flyerBuilderState.priceListStep.replaceWithHeroImage
		flyerBuilderState.updateStepData('priceList', { replaceWithHeroImage })
	}

	const handleFlightInformationOnChange = (event: ContentEditableEvent) => {
		const currentValue = event.target.value
		const actualLength = currentValue.replace(/<\/?[^>]+(>|$)/g, '').length
		if (actualLength > flightInformationMaxLength) return
		flyerBuilderState.updateStepData('priceList', { flightInformation: event.target.value })
	}

	return (
		<div className="flex flex-col gap-10 w-[372px]">
			<h2 className="text-sm font-bold uppercase">{applyPromotionLabel}</h2>
			<div className="grid grid-cols-2 gap-4">
				{priceListBoxes?.map((promotion, index) => (
					<Button key={index} onClick={() => handlePriceNameClick(promotion)} variant={promotion.id === flyerBuilderState.priceListStep.priceList?.id ? 'filledSecondary' : 'outlinedTertiary'} className="h-14 w-full" disabled={!promotion.availableCabins}>
						<span className="text-sm leading-5">{promotion.name}</span>
					</Button>
				))}
			</div>
			<p className="text-sm font-bold uppercase">{selectPromoLabel}</p>
			<div>
				<SwiperWrapperBYF selectedImage={flyerBuilderState.priceListStep.promotionImage} images={flyerBuilderState.priceListStep.offer?.promotionImages || []} onImageSelected={handlePromotionImageClick} />
			</div>
			<RichedTextArea label={flightInformationTitleLabel} characterCounterText={textCountLabel} maxLength={flightInformationMaxLength} value={flyerBuilderState.priceListStep.flightInformation || ''} onChange={handleFlightInformationOnChange} onDeleteButtonClicked={() => flyerBuilderState.updateStepData('priceList', { flightInformation: undefined })} />
			{flyerBuilderState.priceListStep.offer?.heroImage && (
				<span className="text-sm max-w-[263px]">
					<OrangeCheckbox isChecked={flyerBuilderState.priceListStep.replaceWithHeroImage} handleIsChecked={() => handleReplaceWithHeroImageClick()} textNextToCheckbox={replaceWithPromoImageLabel} />
				</span>
			)}
		</div>
	)
}
