'use client'

import { useDevToolsState } from '@/features/dev-tools/store/useDevToolsState'
import { createSitecoreStore, SitecoreState, SitecoreStoreInitialState } from '@/features/sitecore/store/sitecore-state'
import React, { createContext, useContext, useEffect, useRef } from 'react'
import { StoreApi, useStore } from 'zustand'

interface SitecoreProviderProps {
	children: React.ReactNode
	initialState: SitecoreStoreInitialState
}

const SitecoreStoreContext = createContext<StoreApi<SitecoreState> | null>(null)

export function SitecoreProvider(props: SitecoreProviderProps) {
	const storeRef = useRef<StoreApi<SitecoreState> | null>(null)
	const devToolsState = useDevToolsState()

	if (!storeRef.current) {
		storeRef.current = createSitecoreStore(props.initialState.bundles)
	}

	useEffect(() => {
		if (storeRef.current) {
			storeRef.current.setState({
				...storeRef.current.getState(),
				devToolsState,
			})
		}
	}, [
		devToolsState.enabled,
		devToolsState.sitecoreKeysOverride,
	])

	return <SitecoreStoreContext.Provider value={storeRef.current}>{props.children}</SitecoreStoreContext.Provider>
}

export function useSitecoreState<T>(selector: (state: SitecoreState) => T): T {
	const store = useContext(SitecoreStoreContext)

	if (!store) throw new Error('useSitecoreState must be used within SitecoreProvider')

	return useStore(store, selector)
}
