import { PriceEntryIconKeys, priceEntryIcons } from '../configs/price-entry-icons'
import { HotelInfo, PriceListEntry } from '../model/elastic-search'
import { CabinPrice } from './min-price-calculation'

export interface PriceEntryIcon {
	type: PriceEntryIconKeys
	value: string
	url: string
	urlVariantWhite: string
}

export function getPriceEntryIcons(priceEntry: PriceListEntry): PriceEntryIcon[] {
	const items = priceEntry.includedServices.split(';')

	let content = ''
	const list: PriceEntryIcon[] = []
	items.forEach((item) => {
		let [type, value] = item.split('_')

		if (type === 'HTL') type = getHotelIcon(value, priceEntry.hotelInfo)

		if (type === '*' || !content.includes(value)) {
			const isOBS = type == 'OBS'
			type = isOBS ? value : type
			value = isOBS ? type : value
			content += value
		}

		if (priceEntryIcons[type as PriceEntryIconKeys]) {
			const config = priceEntryIcons[type as PriceEntryIconKeys]
			list.push({ type: type as PriceEntryIconKeys, value, url: config.icon, urlVariantWhite: config.iconVariantWhite })
		}
	})
	return list
}

function getHotelIcon(value: string, hotel: HotelInfo[]): string {
	if (value === 'HTL1' || value === 'HTL2') {
		return value
	}

	try {
		const hotelInfo = hotel[0]
		const checkinDate = new Date(hotelInfo.checkinDate)
		const checkoutDate = new Date(hotelInfo.checkoutDate)
		const nights = (checkoutDate.getTime() - checkinDate.getTime()) / (1000 * 60 * 60 * 24)

		return nights === 1 ? 'HTL1' : 'HTL2'
	} catch {
		return 'HTL'
	}
}

export interface PriceList {
	id: string
	name: string
	minPrice: CabinPrice
	icons: PriceEntryIcon[]
}
