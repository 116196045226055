export type PriceEntryIconKeys = 'CRUISE' | 'AIR' | 'TRF' | 'HTL' | 'HTL1' | 'HTL2' | 'TPT' | 'MISC' | 'OBS' | 'MSC' | 'ACT' | 'ALLINC' | 'BCARNE' | 'COMM' | 'FORFAI' | 'HONEY' | 'LAUNDR' | 'PHOTO' | 'SBC' | 'SERVICEC' | 'SPA' | 'WCARNE' | 'GIFT' | 'PARK'

export const priceEntryIcons: { [key in PriceEntryIconKeys]: { icon: string; iconVariantWhite: string } } = {
	CRUISE: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/ship_blu_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/ship_white_small.png',
	},
	/* "AIR": {
		"icon": "/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/air_blue_small.png",
		"iconVariantWhite": "/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/air_white_small.png"
	}, */
	AIR: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/air_blue_small_new.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/air_white_small_new.png',
	},
	/* "TRF": {
		"icon": "/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/trf_blue_small.png",
		"iconVariantWhite": "/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/trf_white_small.png"
	}, */
	TRF: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/trf_blue_small_new.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/trf_white_small_new.png',
	},
	HTL: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/htl_blue_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/htl_white_small.png',
	},
	HTL1: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/htl_blue_small_x1.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/htl_white_small_x1.png',
	},
	HTL2: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/htl_blue_small_x2.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/htl_white_small_x2.png',
	},
	TPT: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/tpt_blue_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/tpt_white_small.png',
	},
	MISC: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/misc_blue_icon.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/misc_white_icon.png',
	},
	OBS: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_blue_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_white_small.png',
	},
	MSC: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_blue_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_white_small.png',
	},
	ACT: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/act_blue_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/act_white_small.png',
	},
	ALLINC: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_allinc_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/37x37_all_inclusive_icon.png',
	},
	BCARNE: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_bcarne_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/37x37_OBS_bcarne_icon.png',
	},
	COMM: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_comm_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/37x37_OBS_comm_icon.png',
	},
	FORFAI: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_forfai_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/37x37_forfai_icon.png',
	},
	HONEY: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_honey_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_honey_w_small.png',
	},
	LAUNDR: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_laundr_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_laundr_w_small.png',
	},
	PHOTO: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_photo_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_photo_w_small.png',
	},
	SBC: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_sbc_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/37x37_sbc_icon.png',
	},
	SERVICEC: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_servicec_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_servicec_w_small.png',
	},
	SPA: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_spa_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/37x37_OBS_spa_icon.png',
	},
	WCARNE: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/obs_wcarne_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/37x37_OBS_wcarne_icon.png',
	},
	GIFT: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/gift.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/gift.png',
	},
	PARK: {
		icon: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/park_blue_small.png',
		iconVariantWhite: '/wcsstore/MSCB2BStoreFrontAssetStore/images/Custom/icon/park_white_small.png',
	},
} as const
