import React from 'react'
import { Radio } from '../uiComponents'

import { IOption } from '@/features/elastic/store/types'

interface RadioGroupProps {
	values: IOption[]
	onChange?: (...args: any) => void
}

export const RadioGroup = ({ values, onChange }: RadioGroupProps) => {
	const handleRadioChange = (value: IOption) => {
		onChange?.(value)
	}

	return (
		<div className="flex flex-wrap gap-2 pt-2">
			{values.map((radioValue, index) => {
				return (
					<React.Fragment key={index}>
						<Radio checked={radioValue.checked} onChange={() => handleRadioChange(radioValue)} id={radioValue.value} label={radioValue.label} />
					</React.Fragment>
				)
			})}
		</div>
	)
}
