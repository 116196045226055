import { QueryClient } from '@tanstack/query-core'
import { ElasticFilter } from '../model/elastic-filter'

export interface IOption {
	label: string // Testo mostrato a schermo
	value: string // codice del filtro, corrisponde a name nella response
	checked: boolean // true se l'opzione è selezionata
	disabled: boolean // true se l'opzione è disabilitata
	hidden?: boolean // true se l'opzione è nascosta
	highlighted?: boolean // true se l'opzione è in evidenza
}

export interface IOptionGroup {
	options: IOption[]
	groupName?: string
}

export enum FILTER_TYPE {
	ADULT = 'adults',
	CHILDREN = 'children',
	JUNIOR_CHILDREN = 'juniorChildren',
	INFANT = 'infants',
	SHIP = 'ship',
	EMBARK_PORT = 'embkPort',
	AREA = 'area',
	REGION = 'region',
	CABIN_CATEGORY = 'cabinCategory',
	DURATION = 'duration',
	DEPARTURE_FROM = 'departureFrom',
	DEPARTURE_TO = 'departureTo',
	RATES = 'rates',
	DEPARTURE_LIST = 'departureList',
}

export type OccupancyFilterType = FILTER_TYPE.ADULT | FILTER_TYPE.CHILDREN | FILTER_TYPE.JUNIOR_CHILDREN | FILTER_TYPE.INFANT
export type ArrayFilterType = FILTER_TYPE.SHIP | FILTER_TYPE.EMBARK_PORT | FILTER_TYPE.AREA | FILTER_TYPE.CABIN_CATEGORY | FILTER_TYPE.DURATION | FILTER_TYPE.RATES | FILTER_TYPE.DEPARTURE_LIST | FILTER_TYPE.REGION
export type DateFilterType = FILTER_TYPE.DEPARTURE_FROM | FILTER_TYPE.DEPARTURE_TO

export interface AdditionalFilterData {
	areaAggregations: string[]
}

export interface ElasticFilterState extends ElasticFilter.IView {
	additionalData?: AdditionalFilterData
	setOccupancy: (type: OccupancyFilterType, count: number) => void
	getCanUpdateOccupancy: (type: OccupancyFilterType) => { add: boolean; remove: boolean }
	toggleArrayFilter: (type: ArrayFilterType, value: IOption | IOptionGroup | string, preventCall?: boolean) => void
	toggleArrayMultipleFilters: (filters: { type: ArrayFilterType; value: IOption | IOptionGroup | string }[]) => void
	setDateFilter: (dateFrom: Date | undefined, dateTo: Date | undefined) => void
	setPriceRange: (min: number, max: number) => void
	fetchUpdatedFilters: (typeChanged?: FILTER_TYPE) => Promise<void>
	resetDuration: () => void
	init: () => void
	queryClient: QueryClient | undefined
	getSearchParams: () => { [key: string]: string }
	resetAllFilters: () => void
}
