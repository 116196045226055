'use client'

import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import exploraLogo from '@public/images/explora_logo.png'
import Image from 'next/image'
import Link from 'next/link'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const ExploraLink = () => {
	const { pushLabelClickedEvent } = useGoogleAnalytics()

	const sitecoreStore = useSitecoreState((state) => state)
	const footerExploraLink = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-MSC-GROUP-LINK-URL-1', '#')
	const footerExploraLinkText = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-MSC-GROUP-LINK-TEXT-1', 'Introducing the new Luxury cruise brand')

	return (
		<Link prefetch={false} className="flex flex-col items-center justify-center lg:flex-row justify-center" href={footerExploraLink}>
			<div className="flex border-0 pb-2 lg:after:content-['|'] after:pl-2 lg:pb-0">
				<Image src={exploraLogo} alt="explora logo"></Image>
			</div>
			<div className="flex items-center gap-1">
				<span
					className="pl-2 cursor-pointer"
					onClick={() =>
						pushLabelClickedEvent({
							event: 'footerLink',
							eventName: 'footer_Link',
							label_clicked: 'Introducing the new Luxury cruise brand',
						})
					}
				>
					{footerExploraLinkText}
				</span>
				<MdOutlineKeyboardArrowRight size={22} />
			</div>
		</Link>
	)
}

export { ExploraLink }
