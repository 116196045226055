'use client'

import { DynamicPicture, FloatingPlaceholderSelect } from '@/components/uiComponents'
import { createConfigForHSCConfig, createConfigForPricesB2B, createConfigForSeasons, createElasticFilterForFlyerBuilder } from '@/features/caching/config/tanstack'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { getMinPriceToShow, Options } from '@/features/elastic/lib/min-price-calculation'
import { PricesB2BResponse } from '@/features/elastic/model/elastic-search'
import { IOption } from '@/features/elastic/store/types'
import { Season } from '@/features/flyer/model/season'
import { CruiseOption, Steps } from '@/features/flyer/store/flyer-builder-state'
import { useFlyerBuilderState, useReadonlyFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { getItineraryImageEndpoint } from '@/features/websphere/configs/external-endpoints'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { useModalState } from '@/utils/hooks/useModalState'
import { convertIsoToMarket, getWeekDayFromIsoDate } from '@/utils/lib/date-utils'
import delete_icon_black_background from '@public/images/delete_icon_black_background.png'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { formatDate } from 'date-fns/format'
import Image from 'next/image'
import { SingleValue } from 'react-select'

type SingleItineraryProps = {
	itineraryIndex: number
	onRemove: () => void
}

const SingleItinerary = (props: SingleItineraryProps) => {
	const market = useCurrentMarket()
	const queryClient = useQueryClient()
	const modalState = useModalState()
	const environment = useEnvironmentState()
	const hscConfig = useQuery(createConfigForHSCConfig()).data
	const _readonly_fullFlyerBuilderState = useReadonlyFlyerBuilderState().getState()
	const elasticFilterResponse = useQuery(createElasticFilterForFlyerBuilder(_readonly_fullFlyerBuilderState, props.itineraryIndex))

	const sitecoreStore = useSitecoreState((state) => state)

	const shipSelectPlaceholder = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-SELECT-A-SHIP', 'Select a ship')
	const seasonSelectPlaceholder = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-SELECT-A-SEASON', 'Select a season')
	const embarkationPortSelectPlaceholder = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-SELECT-AN-EMBARKATION-PORT', 'Select an embarkation port')
	const selectADateRangePlaceholder = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-SELECT-A-DATE-RANGE-PLACEHOLDER', 'Select a date range')
	const itineraryLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ITINERARY', 'Itinerary')
	const progressLostLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROGRESS-LOST', 'If you make changes at this stage, all progress in the following steps will be lost. Are you sure you want to continue?')

	const flyerBuilderState = useFlyerBuilderState((state) => ({
		updateItinerary: state.updateItinerary,
		itineraryStep: state.steps.itinerary,
		destination: state.steps.destination.destination?.area,
		avaiableShips: state.steps.destination.avaiableShips,
		areStepsAlreadyFilledAfter: state.areStepsAlreadyFilledAfter,
		eraseStepsAfter: state.eraseStepsAfter,
	}))

	const currentItinerary = flyerBuilderState.itineraryStep.itineraries[props.itineraryIndex]
	const seasons = useQuery(createConfigForSeasons()).data

	const handleChangesEraseLaterSteps = async (step: keyof Steps, onConfirm: () => void) => {
		if (!flyerBuilderState.areStepsAlreadyFilledAfter(step)) {
			return await onConfirm()
		}

		modalState.openInfoModal(
			{
				variant: 'confirm-abort',
				children: progressLostLabel,
				confirmBehavior: async () => {
					modalState.close()
					flyerBuilderState.eraseStepsAfter(step)
					await onConfirm()
				},
			},
			{
				title: {
					bundle: TranslationBundles.BUILD_YOUR_FLYER,
					keyName: 'MSC-BUILD_YOUR_FLYER-RESET_STEPS_TITLE',
					defaultValue: 'Warning: Changes will reset later steps',
				},
			}
		)
	}

	const handleShipSelect = async (ship: SingleValue<IOption>) =>
		handleChangesEraseLaterSteps('itinerary', async () => {
			if (!ship) return

			const updatedState = flyerBuilderState.updateItinerary(props.itineraryIndex, { ship })

			const filterb2bResponse = await queryClient.fetchQuery(createElasticFilterForFlyerBuilder(updatedState))
			const parsedDepartureDates = filterb2bResponse.departureList.flatMap((dep) => new Date(dep.label))
			const filteredSeasons = seasons?.filter((season) => season.ship === ship.value && parsedDepartureDates.some((date) => date >= new Date(season.startDate) && date <= new Date(season.endDate)))
			flyerBuilderState.updateItinerary(props.itineraryIndex, { avaiableSeasons: filteredSeasons || [] })
		})

	const handleSeasonSelect = async (season: SingleValue<Season>) =>
		handleChangesEraseLaterSteps('itinerary', async () => {
			if (!season?.ship) return
			const updatedState = flyerBuilderState.updateItinerary(props.itineraryIndex, { season })

			const filterb2bResponse = await queryClient.fetchQuery(createElasticFilterForFlyerBuilder(updatedState))
			const avaiableEmbkPorts = filterb2bResponse.departurePortGroups.flatMap((portGroup) => portGroup.options)
			flyerBuilderState.updateItinerary(props.itineraryIndex, { avaiableEmbkPorts })
		})

	const handlePortSelect = async (embarkationPort: SingleValue<IOption>) =>
		handleChangesEraseLaterSteps('itinerary', async () => {
			if (!embarkationPort) return

			const pricesB2BRequest = {
				departureDateFrom: formatDate(currentItinerary.season?.startDate || new Date(), 'dd/MM/yyyy'),
				departureDateTo: formatDate(currentItinerary.season?.endDate || new Date(), 'dd/MM/yyyy'),
				shipCd: currentItinerary.ship?.value,
				embkPort: embarkationPort?.value,
				area: flyerBuilderState.destination,
			}

			const pricesB2BResponse = (await queryClient.fetchQuery(createConfigForPricesB2B(pricesB2BRequest)))?.data

			const availableCruises: CruiseOption[] =
				pricesB2BResponse?.Results.result
					.toSorted((a, b) => new Date(a.productAttributes.Embkdt.value).getTime() - new Date(b.productAttributes.Embkdt.value).getTime())
					.map((result) => ({
						cruiseId: result.cruiseID,
						embarkationDate: result.productAttributes.Embkdt.value,
						disembarkationDate: result.productAttributes.Dismbkdt.value,
						available: result.catEntryPriceList.reduce((acc, curr) => acc + curr.availability, 0) > 0,
					})) || []

			flyerBuilderState.updateItinerary(props.itineraryIndex, { embarkationPort, pricesB2BResponse, availableCruises })
		})

	const minPriceCalculationOptions: Options = {
		isHSCEnabledForCurrentMarket: hscConfig?.enabled ?? false,
		showHscSummedToBasePrice: hscConfig?.summedToBasePrice ?? false,
		considerOnlyAvailableCabins: true,
		pricePer: environment.isFeatureEnabled('ENABLE_BYF_PRICE_PER_CABIN', market) ? 'cabin' : 'adult',
	}

	const handleDateRangeSelect = (option: SingleValue<CruiseOption>) =>
		handleChangesEraseLaterSteps('itinerary', async () => {
			const pricesB2BResponse = currentItinerary.pricesB2BResponse
			const cruiseElement = pricesB2BResponse?.Results.result.find((result) => result.cruiseID === option?.cruiseId)
			const cruiseLength = +(cruiseElement?.productAttributes.CruiseLen.value || '-1')
			const price = cruiseElement ? getMinPriceToShow(cruiseElement.catEntryPriceList, minPriceCalculationOptions) : undefined

			const filteredPricesB2BResponse = { ...pricesB2BResponse } as PricesB2BResponse
			if (filteredPricesB2BResponse?.Results?.result && cruiseElement) {
				filteredPricesB2BResponse.Results = Object.assign({}, filteredPricesB2BResponse.Results, { result: [cruiseElement] })
			}

			const daysNights = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-DAYS-NIGHTS', '{0} days - {1} nights', [cruiseLength + 1, cruiseLength])
			flyerBuilderState.updateItinerary(props.itineraryIndex, {
				cruise: option as CruiseOption | undefined,
				itineraryCode: cruiseElement?.itinCd || undefined,
				dateRange: printDateRange(option as CruiseOption),
				departureWeekDay: option ? getWeekDayFromIsoDate(option.embarkationDate, market) : undefined,
				daysNights,
				price,
			})
		})

	const printDateRange = (option: CruiseOption) => {
		const embarkDate = convertIsoToMarket(option.embarkationDate, market)
		const disembarkDate = convertIsoToMarket(option.disembarkationDate, market)
		return `${embarkDate} - ${disembarkDate}`
	}

	const shipSelectLoading = elasticFilterResponse.isLoading
	const seasonSelectLoading = elasticFilterResponse.isLoading || currentItinerary.avaiableSeasons.length === 0
	const embarkationPortSelectLoading = elasticFilterResponse.isLoading || currentItinerary.avaiableEmbkPorts.length === 0
	const dateRangeSelectLoading = elasticFilterResponse.isLoading || currentItinerary.availableCruises.length === 0
	return (
		<div className="flex flex-col gap-6 mb-10 h-full">
			<div className="flex flex-col">
				<div className="flex items-center justify-between mb-10">
					<p className="font-bold">{itineraryLabel}</p>
					{props.itineraryIndex !== 0 && <Image src={delete_icon_black_background} alt="delete icon" onClick={props.onRemove} className="cursor-pointer"></Image>}
				</div>
				<div className="flex flex-col gap-6">
					{/*Ship select*/}
					{/* eslint-disable-next-line prettier/prettier */}
					{/* prettier-ignore */}
					<FloatingPlaceholderSelect
						isDisabled={shipSelectLoading}
						isLoading={shipSelectLoading}
						className={clsx({ 'cursor-wait': shipSelectLoading })}
						options={flyerBuilderState.avaiableShips}
						placeholder={shipSelectPlaceholder}
						required={true}
						value={currentItinerary.ship ?? null}
						onChange={handleShipSelect}
					/>

					{/*Season select*/}
					{/* eslint-disable-next-line prettier/prettier */}
					{/* prettier-ignore */}
					<FloatingPlaceholderSelect
						isDisabled={seasonSelectLoading}
						isLoading={seasonSelectLoading}
						className={clsx({ 'cursor-wait': seasonSelectLoading })}
						getOptionLabel={(option) => option.description}
						getOptionValue={(option) => option.description}
						options={currentItinerary.avaiableSeasons}
						placeholder={seasonSelectPlaceholder}
						required={true}
						value={currentItinerary.season ?? null}
						onChange={handleSeasonSelect}
					/>

					{/*Embarkation port select*/}
					<FloatingPlaceholderSelect
						isDisabled={embarkationPortSelectLoading}
						isLoading={embarkationPortSelectLoading}
						className={clsx({
							hidden: !currentItinerary.ship || !currentItinerary.season,
							'cursor-wait': embarkationPortSelectLoading,
						})}
						options={currentItinerary.avaiableEmbkPorts}
						placeholder={embarkationPortSelectPlaceholder}
						required={true}
						value={currentItinerary.embarkationPort ?? null}
						onChange={handlePortSelect}
					/>
				</div>
			</div>

			<div className={clsx('flex flex-col', { hidden: !currentItinerary.ship || !currentItinerary.season || !currentItinerary.embarkationPort })}>
				<div className="flex flex-col">
					{/* eslint-disable-next-line prettier/prettier */}
					{/* prettier-ignore */}
					<FloatingPlaceholderSelect
						isDisabled={dateRangeSelectLoading}
						isLoading={dateRangeSelectLoading}
						options={currentItinerary.availableCruises}
						placeholder={selectADateRangePlaceholder}
						required={true}
						onChange={handleDateRangeSelect}
						value={currentItinerary.cruise || null}
						isOptionDisabled={(option) => !option.available}
						getOptionLabel={(option) => option && printDateRange(option)}
					/>
				</div>
				<p className="mb-6"></p>
				{currentItinerary.itineraryCode && <DynamicPicture image={getItineraryImageEndpoint(currentItinerary.itineraryCode)} basepath="included" alt={''} height={193} width={340} className="rounded border-8 border-black inset-0 pointer-events-none z-0 mb-6"></DynamicPicture>}
			</div>
		</div>
	)
}
export { SingleItinerary }
