import { Step } from '@/features/flyer/configs/steps'
import React from 'react'
import { AgentsDetailsIcon, DestinationIcon, DownloadIcon, ItineraryIcon, NotesIcon, PromoIcon } from '../uiComponents'

interface FlyerBuilderNavigationButtonProps {
	isActive: boolean
	isDisabled: boolean
	buttonId: Step
	children: string
	onClick: () => void
}

const iconMap: { [key in Step]: React.FC<{ color: string }> } = {
	destination: DestinationIcon,
	itinerary: ItineraryIcon,
	priceList: PromoIcon,
	agentDetails: AgentsDetailsIcon,
	notes: NotesIcon,
	flyer: DownloadIcon,
}

const FlyerBuilderNavigationButton: React.FC<FlyerBuilderNavigationButtonProps> = ({ isActive, isDisabled, buttonId, children, onClick }) => {
	const IconComponent = iconMap[buttonId]
	const iconColor = isActive ? '#4892E0' : '#000033'

	return (
		<div className={`flex w-fit max-w-28 gap-1 ${!isActive ? '' : 'opacity-100'} ${isDisabled ? 'opacity-30 cursor-not-allowed' : 'cursor-pointer opacity-100 hover:opacity-100'} transition-opacity`} onClick={!isDisabled ? onClick : undefined}>
			<div className="w-6">
				<IconComponent color={iconColor} />
			</div>
			<p className={`${isActive ? 'text-secondary' : ''} text-xs`}>{children}</p>
		</div>
	)
}

export { FlyerBuilderNavigationButton }
