'use client'

import delete_remove_backspace from '@public/images/delete_remove_backspace.png'
import Image from 'next/image'
import { BtnBold, BtnItalic, BtnStrikeThrough, BtnUnderline, ContentEditableEvent, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg'

interface RichedTextAreaProps {
	label: string
	maxLength: number
	value: string
	rows?: number
	characterCounterText: string
	onChange: (event: ContentEditableEvent) => void
	onDeleteButtonClicked?: () => void
}

// disable enter key
const handleEnterKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
	if (event.key === 'Enter') {
		event.preventDefault()
	}
}

export const RichedTextArea = (props: RichedTextAreaProps) => {
	//remove html tags to get the actual text length
	const textOnly = props.value.replace(/<\/?[^>]+(>|$)/g, '')
	const cuttedValue = textOnly.length > props.maxLength ? props.value.slice(0, props.maxLength) : props.value

	return (
		<div>
			<div className="flex items-center justify-between relative mb-4">
				<label htmlFor="additional-paragraph">{props.label}</label>
				<div className="flex items-center justify-between gap-2">
					<Image src={delete_remove_backspace} alt="delete remove backspace icon" width={24} height={24} onClick={props.onDeleteButtonClicked} className="cursor-pointer" />
				</div>
			</div>
			<div className="flex flex-col gap-2.5">
				<EditorProvider>
					<Editor value={cuttedValue} onChange={props.onChange} style={{ whiteSpace: 'pre-wrap' }} onKeyDown={handleEnterKey}>
						<Toolbar>
							<BtnBold />
							<BtnItalic />
							<BtnUnderline />
							<BtnStrikeThrough />
						</Toolbar>
					</Editor>
				</EditorProvider>
				<div className="flex justify-between">
					<p>{props.characterCounterText}</p>
					<p className="font-bold">
						{textOnly.length}/{props.maxLength}
					</p>
				</div>
			</div>
		</div>
	)
}
