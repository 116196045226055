'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { Step, stepOrder } from '@/features/flyer/configs/steps'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useEffect } from 'react'
import { Button } from '../uiComponents'

const FlyerBuilderStepperControls = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const nextLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-NEXT', 'Next')
	const backLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-BACK', 'Back')

	const flyerBuilderState = useFlyerBuilderState((state) => ({
		isPolicyAccepted: state.steps.destination.isPolicyAccepted,
		activeStep: state.activeStep,
		getActiveStep: state.getActiveStep,
		setActiveStep: state.setActiveStep,
		nextButtonEnabled: state.nextButtonEnabled,
		setNextButtonEnabled: state.setNextButtonEnabled,
		itineraries: state.steps.itinerary.itineraries,
		destinationLabel: state.steps.destination.destination?.label,
		applyOfferLabel: state.steps.priceList.priceList?.name,
	}))

	const handleBack = () => {
		if (flyerBuilderState.activeStep > 0) flyerBuilderState.setActiveStep(flyerBuilderState.activeStep - 1)
		flyerBuilderState.setNextButtonEnabled(false)
	}

	const handleNext = () => {
		sendNextButtonGA4Event(flyerBuilderState.getActiveStep())
		if (flyerBuilderState.activeStep < stepOrder.length) flyerBuilderState.setActiveStep(flyerBuilderState.activeStep + 1)
		flyerBuilderState.setNextButtonEnabled(false)
	}

	const nextConditions: { [key in Step]: boolean } = {
		destination: flyerBuilderState.isPolicyAccepted,
		itinerary: flyerBuilderState.itineraries.every((itinerary) => itinerary.cruise?.cruiseId),
		priceList: true,
		agentDetails: true,
		notes: true,
		flyer: true,
	}

	useEffect(() => {
		flyerBuilderState.setNextButtonEnabled(nextConditions[flyerBuilderState.getActiveStep()])
	}, [flyerBuilderState])

	// GA4
	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendNextButtonGA4Event = (step: Step) => {
		if (!step) return
		const stepMap: { [key in Step]: Record<'eventName' | 'sectionBYF' | 'labelClicked', string> } = {
			destination: { eventName: 'next_byf_st1', sectionBYF: 'Destination', labelClicked: flyerBuilderState.destinationLabel || '' },
			itinerary: { eventName: 'next_byf_st2', sectionBYF: 'Itinerary', labelClicked: flyerBuilderState.itineraries[0].ship?.label || '' },
			priceList: { eventName: 'next_byf_st3', sectionBYF: 'Apply offer', labelClicked: flyerBuilderState.applyOfferLabel || '' },
			notes: { eventName: 'next_byf_st4', sectionBYF: 'Additional notes', labelClicked: '' },
			agentDetails: { eventName: 'next_byf_st5', sectionBYF: 'Add travel agency details', labelClicked: '' },
			flyer: { eventName: '', sectionBYF: '', labelClicked: '' }, // never happens
		}

		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'nextBYF',
			eventName: stepMap[step].eventName,
			label_clicked: stepMap[step].labelClicked,
			section_byf: stepMap[step].sectionBYF,
			...staticParams,
		})
	}

	return (
		<div className="container">
			<div className="flex flex-col justify-center py-6 gap-6">
				<div className="flex justify-end gap-4 ml-auto">
					{flyerBuilderState.activeStep !== 1 && (
						<Button variant="outlined" onClick={handleBack}>
							{backLabel}
						</Button>
					)}
					{flyerBuilderState.activeStep !== 6 && (
						<Button variant="filled" disabled={!flyerBuilderState.nextButtonEnabled} onClick={handleNext}>
							{nextLabel}
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}

export { FlyerBuilderStepperControls }
