'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { MarketingTool } from '@/features/sitecore/actions/marketing-tools'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import { Fragment, useState } from 'react'
import { TextLink } from '../atoms/TextLink'

interface MarketingToolsProps {
	title: string
	marketingTools: MarketingTool[]
}

const MarketingTools = ({ title, marketingTools }: MarketingToolsProps) => {
	const user = useLoggedUser()
	const [show, setShow] = useState(false)
	const { pushLabelClickedEvent } = useGoogleAnalytics()

	const sitecoreStore = useSitecoreState((state) => state)
	const readMoreLabel = sitecoreStore.getLabel(TranslationBundles.ORDER_MANAGEMENT_REVIEW, 'MSC-ORDERMANAGEMENT_BOOKING-SHOW-ALL', 'View all')
	const readLessLabel = sitecoreStore.getLabel(TranslationBundles.ORDER_MANAGEMENT_REVIEW, 'MSC-ORDERMANAGEMENT_BOOKING-SHOW-LESS', 'View less')

	const viewMoreTextStyle = 'text-light-purple font-medium leading-5'
	const viewMore = <span className={viewMoreTextStyle}>{readMoreLabel}</span>
	const viewLess = <span className={viewMoreTextStyle}>{readLessLabel}</span>

	const maxMarketingToolsToShow = useSitecoreState((state) => state).getNumber(TranslationBundles.WELCOME, 'MAX_MARKETING_TOOLS_TO_SHOW', 3)

	const moreThenParial = marketingTools.length > maxMarketingToolsToShow
	const partialMarketingTools = marketingTools?.slice(0, maxMarketingToolsToShow)
	const marketingList = show ? marketingTools : partialMarketingTools

	const handleMarketingToolsCardClick = (event: any, marketingTool: MarketingTool) => {
		event.preventDefault()
		pushLabelClickedEvent({ event: 'mktgtools', eventName: 'hp_marketing_tools', label_clicked: marketingTool.title })
		if (marketingTool.logged && !user) {
			useModalState.getState().openLoginModal({ nextUrl: marketingTool.link })
			return
		}
		setTimeout(() => {
			window.location.href = marketingTool.link
		}, 300)
	}

	return (
		<div>
			<h3 className="font-extrabold text-msc-blue text-xl">{title}</h3>
			<ul className="flex flex-col gap-6 items-start py-6">
				{marketingList.map((marketingTool, index) => {
					return (
						<Fragment key={index}>
							<TextLink bundleName={marketingTool.bundleName} imageUrl={marketingTool.image} href={marketingTool.link} text={marketingTool.title} onClick={(event) => handleMarketingToolsCardClick(event, marketingTool)} />
						</Fragment>
					)
				})}
			</ul>
			{moreThenParial && (
				<button
					onClick={() => {
						setShow((value) => !value)
						pushLabelClickedEvent({ event: 'mktgtools', eventName: 'hp_marketing_tools', label_clicked: 'view more' })
					}}
				>
					{show ? viewLess : viewMore}
				</button>
			)}
		</div>
	)
}

export { MarketingTools }
