import AuthService from '@/features/auth/auth-service'
import ElasticService from '@/features/elastic/elastic-service'
import { callElasticFilterForBYF } from '@/features/elastic/lib/filterb2b-utils'
import { PricesB2BParams } from '@/features/elastic/model/elastic-search'
import FlyerService from '@/features/flyer/flyer-service'
import { FlyerBuilderState } from '@/features/flyer/store/flyer-builder-state'
import HeaderMenuService from '@/features/header-menu/header-menu-service'
import MarketService from '@/features/markets/markets-service'
import { hashMD5 } from '@/utils/lib/utils'

export function createConfigForCurrentMarket() {
	return {
		queryKey: ['market'],
		queryFn: () => MarketService.getCurrentMarket(),
	}
}

export function createConfigForCurrentMarketLanguages() {
	return {
		queryKey: ['market-languages'],
		queryFn: () => MarketService.getCurrentMarketLanguages(),
		staleTime: 90000,
	}
}

export function createConfigForAuthSession() {
	return {
		queryKey: ['auth'],
		queryFn: () => AuthService.session(),
	}
}

export function createConfigForHeaderMenu(options: { cache: boolean }) {
	return {
		queryKey: ['header-menu'],
		queryFn: () => HeaderMenuService.getHeaderMenu({ cache: options.cache }),
	}
}

export function createConfigForPricesB2B(params: PricesB2BParams) {
	return {
		queryKey: ['pricesb2b', params],
		queryFn: () => ElasticService.callPricesB2B(params),
	}
}

export function createConfigForHSCConfig() {
	return {
		queryKey: ['hsc-config'],
		queryFn: () => ElasticService.retrieveHscConfig(),
	}
}

export function createConfigForSeasons() {
	return {
		queryKey: ['seasons'],
		queryFn: () => FlyerService.retrieveSeasons(),
	}
}

export const createElasticFilterForFlyerBuilder = (flyerBuilderState: FlyerBuilderState, itineraryIndex = 0) => {
	return {
		queryKey: ['filterb2b', 'flyerBuilder', hashMD5(flyerBuilderState)],
		queryFn: () => callElasticFilterForBYF(flyerBuilderState, itineraryIndex),
	}
}
