import React from 'react'
import { Checkbox } from '../uiComponents'

import { IOption } from '@/features/elastic/store/types'

interface CheckboxGroupProps {
	values: IOption[]
	onChange?: (option: IOption) => void
}

export const CheckboxGroup = ({ values, onChange }: CheckboxGroupProps) => {
	const checkboxClickBehavior = (value: IOption) => {
		onChange && onChange(value)
	}
	return (
		<div className="flex flex-wrap gap-2 pt-2">
			{values.map((el, index) => {
				return (
					<React.Fragment key={index}>
						<Checkbox checked={el.checked} name={`${index}-${el?.value}`} label={el?.label} onChange={() => checkboxClickBehavior(el)} />
					</React.Fragment>
				)
			})}
		</div>
	)
}
