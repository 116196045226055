import { auth } from '@/features/auth/configs/auth'
import ElasticService from '@/features/elastic/elastic-service'
import { elasticFilterInitialState, mapToOptions } from '@/features/elastic/store/elastic-filter-store-utils'
import { noChecks } from '@/features/elastic/store/utils/option-utils'
import { FlyerBuilderState } from '@/features/flyer/store/flyer-builder-state'
import { getCurrentMarket } from '@/features/markets/actions/utils'
import { formatDate } from 'date-fns/format'
import { MSC_CABIN_CLASSES } from '../configs/cabin-categories'
import { ElasticFilter } from '../model/elastic-filter'
import { FILTER_TYPE, IOption, IOptionGroup } from '../store/types'
import { mapCabinClassToCodes } from '../store/utils/cabin-utils'

// Type guard per IOption
export function isIOption(obj: any): obj is IOption {
	return typeof obj === 'object' && obj.value !== undefined && obj.groupName === undefined
}

// Type guard per IOptionGroup
export function isIOptionGroup(obj: any): obj is IOptionGroup {
	return typeof obj === 'object' && obj.value === undefined && obj.groupName !== undefined && obj.options.every(isIOption)
}

// Type guards for arrays
export function isIOptionArray(arr: IOption[] | IOptionGroup[]): arr is IOption[] {
	return arr.length > 0 && arr.every(isIOption)
}

export function isIOptionGroupArray(arr: IOption[] | IOptionGroup[]): arr is IOptionGroup[] {
	return arr.length > 0 && arr.every(isIOptionGroup)
}

function mapJoinAndFilterChecked(area: IOption[] | IOptionGroup[]): string {
	const options: IOption[] = []
	area.forEach((group) => {
		if (isIOption(group) && group.checked) options.push(group)
		else if (isIOptionGroup(group)) options.push(...group.options.filter((option) => option.checked))
	})

	if (options.length > 0) return options.map((option) => option.value).join(',')
	else return ''
}

export async function toElasticFilterRequest(currentFilter: ElasticFilter.ISelectedFilter): Promise<ElasticFilter.Service.IRequest> {
	const market = await getCurrentMarket()
	const session = await auth()
	const noFiltersApplied = noChecks(currentFilter.area) && noChecks(currentFilter.ship) && noChecks(currentFilter.embkPort) && noChecks(currentFilter.cabinCategory)
	const noDatesApplied = !currentFilter.departureFrom && !currentFilter.departureTo

	if (!session) throw new Error('Unauthorized request: callElasticFilter')

	return {
		officeCode: market.office,
		languageCode: market.language.dtsLanguage,
		marketCode: market.code,
		agencyCode: session?.user.agency || '',
		noofAdults: currentFilter.adults,
		noofChildren: currentFilter.children,
		noofInfant: currentFilter.infants,
		noofJuniorChildren: currentFilter.juniorChildren,
		isQuickSearch: null,
		departureDateFrom: currentFilter.departureFrom || null,
		departureDateTo: currentFilter.departureTo || null,
		area: mapJoinAndFilterChecked(currentFilter.area),
		region: mapJoinAndFilterChecked(currentFilter.region),
		embkPort: mapJoinAndFilterChecked(currentFilter.embkPort),
		shipCd: mapJoinAndFilterChecked(currentFilter.ship),
		duration: mapJoinAndFilterChecked(currentFilter.duration),
		cabinCategory: mapCabinClassToCodes(mapJoinAndFilterChecked(currentFilter.cabinCategory) as keyof typeof MSC_CABIN_CLASSES),
		priceTypes: null,
		priceCodes: null,
	}
}

export async function sortResponseFields(responseBody: ElasticFilter.Service.IResponse): Promise<ElasticFilter.Service.IResponse> {
	const sortByAttributeValue = (a: any, b: any) => a.attributeValue.localeCompare(b.attributeValue)

	return {
		results: {
			shipList: responseBody.results.shipList.sort(sortByAttributeValue),
			departurePortList: responseBody.results.departurePortList.sort(sortByAttributeValue),
			departureMonthList: responseBody.results.departureMonthList.sort(sortByAttributeValue),
			areaList: responseBody.results.areaList.sort(sortByAttributeValue),
			cabinCategory: responseBody.results.cabinCategory.sort((a, b) => a.cabinList.localeCompare(b.cabinList)),
			durationList: responseBody.results.durationList.sort(sortByAttributeValue),
			departureList: responseBody.results.departureList.sort(sortByAttributeValue),
		},
	}
}

export function createEmptyElasticFilterResponse(): ElasticFilter.Service.IResponse {
	return {
		results: {
			shipList: [],
			departurePortList: [],
			departureMonthList: [],
			areaList: [],
			cabinCategory: [],
			durationList: [],
			departureList: [],
		},
	}
}
export async function callElasticFilterForBYF(
	flyerBuilderState: FlyerBuilderState,
	itineraryIndex: number
): Promise<{
	durationOptions: IOption[]
	departurePortGroups: IOptionGroup[]
	shipOptions: IOption[]
	departureMonthOptions: IOption[]
	cabinListOptions: IOption[]
	areaOptions: IOption[]
	departureList: IOption[]
}> {
	const state: ElasticFilter.ISelectedFilter = { ...elasticFilterInitialState.options }
	const baseOption: IOption = { value: '', label: '', checked: true, disabled: false }
	const itinerary = flyerBuilderState.steps.itinerary.itineraries

	const startDate = itinerary[itineraryIndex]?.season?.startDate
	const endDate = itinerary[itineraryIndex]?.season?.endDate

	const map: Partial<Record<keyof ElasticFilter.ISelectedFilter, string | undefined>> = {
		[FILTER_TYPE.AREA]: flyerBuilderState.steps.destination.destination?.area,
		[FILTER_TYPE.REGION]: flyerBuilderState.steps.destination.destination?.region,
		[FILTER_TYPE.SHIP]: itinerary[itineraryIndex]?.ship?.value,
		[FILTER_TYPE.EMBARK_PORT]: itinerary[itineraryIndex]?.embarkationPort?.value || flyerBuilderState.steps.destination.destination?.port,
	}

	state.departureFrom = startDate ? formatDate(startDate, 'dd/MM/yyyy') : undefined
	state.departureTo = endDate ? formatDate(endDate, 'dd/MM/yyyy') : undefined

	for (const [key, value] of Object.entries(map)) {
		if (value) {
			const stateAsAny = state as any
			stateAsAny[key] = [{ ...baseOption, value }]
		}
	}

	const results = await ElasticService.callElasticFilter(state)
	return mapToOptions({
		fetchedResults: results.data || {
			shipList: [],
			departurePortList: [],
			departureMonthList: [],
			areaList: [],
			cabinCategory: [],
			durationList: [],
			departureList: [],
		},
	})
}
