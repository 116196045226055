'use client'

import React from 'react'

interface TabListProps {
	reversedTabs?: boolean
	children: React.ReactNode
}

export const TabList = ({ reversedTabs, children }: TabListProps) => {
	return <div className={`flex flex-wrap gap-y-2 gap-x-6 mb-5 ${reversedTabs ? 'flex-row-reverse' : ''}`}>{children}</div>
}
