'use client'

import { DynamicPicture } from '@/components/atoms/DynamicPicture'
import forward_arrow_white from '@public/images/forward_arrow_white.png'
import Image from 'next/image'
import { useEffect, useMemo, useRef, useState } from 'react'
import SwiperCore, { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

interface SwiperWrapperBYFProps {
	images: string[]
	imgPadding?: string
	selectedImage?: string
	onImageSelected: (src: string) => void
}

const SwiperWrapperBYF = (props: SwiperWrapperBYFProps) => {
	const [isBeginning, setIsBeginning] = useState(true)
	const [isEnd, setIsEnd] = useState(false)
	const swiperRef = useRef<SwiperCore | null>(null)

	const handlePrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev()
		}
	}
	const handleNextSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext()
		}
	}

	const onSwiperInit = (swiper: SwiperType) => {
		swiperRef.current = swiper
		setIsBeginning(swiper.isBeginning)
		setIsEnd(swiper.isEnd)
	}

	const onSlideChange = () => {
		if (swiperRef.current) {
			setIsBeginning(swiperRef.current.isBeginning)
			setIsEnd(swiperRef.current.isEnd)
		}
	}
	// forcefully update swiper when image container changes
	useEffect(() => {
		if (swiperRef.current) {
			setTimeout(() => {
				swiperRef.current?.update()
				swiperRef.current?.slideTo(0)
				setIsBeginning(swiperRef.current!.isBeginning)
				setIsEnd(swiperRef.current!.isEnd)
			}, 0)
		}
	}, [props.images.length])

	const slidesPerView = useMemo(() => {
		return props.images.length > 2 ? 3 : 'auto'
	}, [props.images.length])

	const imgShadeCover = <div className="absolute inset-0 z-10 rounded border-4 border-black inset-0 bg-gradient-to-b from-transparent via-[rgba(0,0,0,0.197621)] to-[#000033] pointer-events-none" style={{ width: '115px', height: '77px' }}></div>

	if (!props.images || props.images.length === 0) {
		return null
	}
	return (
		<Swiper onInit={onSwiperInit} onSlideChange={onSlideChange} slidesPerView={slidesPerView} spaceBetween={8} navigation={props.images.length > 3 ? { prevEl: '.custom-prev', nextEl: '.custom-next' } : false} watchOverflow={true} className="flex justify-center items-center w-full">
			{props.images.map((src: string, index: number) => (
				<SwiperSlide key={index} className="relative w-fit">
					<DynamicPicture basepath="websphere-images" style={{ width: '115px', height: '77px', padding: props.imgPadding }} width={115} height={77} image={src} alt="" className="cursor-pointer border-2 rounded" onClick={() => props.onImageSelected(src)} />
					{props.selectedImage === src && imgShadeCover}
				</SwiperSlide>
			))}

			{props.images.length > 3 && !isBeginning && (
				<button className="custom-prev h-[73px] w-[58px] bg-gradient-byf-left absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
					<Image src={forward_arrow_white} onClick={handlePrevSlide} alt="Previous" className="w-8 h-8 rotate-180" />
				</button>
			)}

			{props.images.length > 3 && !isEnd && (
				<button className="custom-next h-[73px] w-[58px] bg-gradient-byf-right absolute right-[3px] top-1/2 transform -translate-y-1/2 z-10">
					<Image src={forward_arrow_white} onClick={handleNextSlide} alt="Next" className="w-8 h-8 ml-[27px] " />
				</button>
			)}
		</Swiper>
	)
}

export { SwiperWrapperBYF }
