'use client'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import React, { useRef, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { AngleDownIcon, NumberInput } from '../uiComponents'

interface GuestOption {
	label: string
	value: number
	onIncrement: () => void
	onDecrement: () => void
	isDecrementDisabled: boolean
	isIncrementDisabled: boolean
}

interface GuestCabinPickerProps {
	options: GuestOption[]
	totalPassengers: number
	cabins: number
	summaryLabel: string
	cabinLabel: string
}

const GuestCabinPicker = ({ options, totalPassengers, cabins, summaryLabel, cabinLabel }: GuestCabinPickerProps) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const [isOpen, setIsOpen] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		setIsOpen(!isOpen)
	}

	const handleBlur = (e: React.FocusEvent) => {
		// Check if the related target is a child of the container
		if (containerRef.current && !containerRef.current.contains(e.relatedTarget as Node)) {
			setIsOpen(false)
		} else {
			// If the related target is a child of the container, focus the container again
			containerRef.current?.focus()
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Escape') {
			setIsOpen(false)
		}
	}

	const passangersCanBeAddedLaterLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-SEARCH-INFORMATIVE-NOTE', 'Aditonal passangers can be added later.')

	return (
		<div className="relative w-full flex-1 z-40" ref={containerRef} onBlur={handleBlur} onKeyDown={handleKeyDown} tabIndex={0}>
			<button type="button" className="flex justify-between items-center w-full text-light-purple rounded-sm border-msc-blue border py-2 px-5 bg-white text-sm font-medium focus:outline-none" onClick={handleClick} aria-expanded={isOpen} aria-haspopup="true">
				<span className="flex items-center justify-center text-msc-blue py-1 gap-2">
					<FaUser color="msc-blue" /> {totalPassengers} {summaryLabel}, {cabins} {cabinLabel}
				</span>
				<div className={`ml-2 h-5 w-5 ${isOpen ? 'rotate-180' : 'rotate-0'} transition-all`}>
					<AngleDownIcon />
				</div>
			</button>

			{isOpen && (
				<div className="origin-top-left absolute left-0 md:left-auto md:right-0 2xl:right-auto 2xl:left-0 mt-2 w-full min-w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu">
					<div className="flex flex-col py-1 px-4" role="none">
						{options.map((option, index) => (
							<div key={index} className="flex flex-row items-center justify-between gap-3 py-2">
								<label className="block text-sm font-medium text-gray-700">{option.label}</label>
								<NumberInput value={option.value} onIncrement={option.onIncrement} onDecrement={option.onDecrement} isDecrementDisabled={option.isDecrementDisabled} isIncrementDisabled={option.isIncrementDisabled} />
							</div>
						))}
						<p className="text-xs border-t border-t-light-purple pt-2 mt-2">{passangersCanBeAddedLaterLabel}</p>
					</div>
				</div>
			)}
		</div>
	)
}

export { GuestCabinPicker }
