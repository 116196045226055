'use client'

import { AgentLogoUploader, Input } from '@/components/uiComponents'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useEffect } from 'react'

export const AgentDetailsStep = () => {
	const flyerBuilderState = useFlyerBuilderState((state) => ({
		updateStepData: state.updateStepData,
		agentDetails: state.steps.agentDetails,
	}))

	const { pushLabelClickedEvent } = useGoogleAnalytics()
	useEffect(() => pushLabelClickedEvent({ event: 'stepView', eventName: 'step_view', label_clicked: 'Add travel agency details' }), [])

	const sitecoreStore = useSitecoreState((state) => state)
	const agencyContactLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD-YOUR-AGENCY-CONTACT', 'Add your Agency Contact (Otional)')
	const namePlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-NAME-PLACEHOLDER', 'Name')
	const emailPlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-EMAIL-PLACEHOLDER', 'Email')
	const phoneNumberPlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PHONE-NUMBER-PLACEHOLDER', 'Phone number')
	const facebookOrInstaPlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-FB_INSTA-PLACEHOLDER', 'Facebook or Instagram')

	const handleInputChange = (name: string, value: string) => {
		flyerBuilderState.updateStepData('agentDetails', { [name]: value })
	}

	return (
		<div className="flex flex-col gap-6 w-[372px]">
			<AgentLogoUploader />
			<div className="flex items-center justify-between relative">
				<label htmlFor="additional-paragraph">{agencyContactLabel}</label>
			</div>
			<Input type="text" label={namePlaceholderLabel} maxLength={50} customClasses="!rounded-md" onChange={(e) => handleInputChange('name', e.target.value)} value={flyerBuilderState.agentDetails.name || ''} />
			<Input type="email" label={emailPlaceholderLabel} maxLength={50} customClasses="!rounded-md" onChange={(e) => handleInputChange('email', e.target.value)} value={flyerBuilderState.agentDetails.email || ''} />
			<Input type="tel" label={phoneNumberPlaceholderLabel} maxLength={50} customClasses="!rounded-md" onChange={(e) => handleInputChange('phoneNumber', e.target.value)} value={flyerBuilderState.agentDetails.phoneNumber || ''} />
			<Input type="url" pattern="https://.*" label={facebookOrInstaPlaceholderLabel} maxLength={50} customClasses="!rounded-md" onChange={(e) => handleInputChange('socialInfo', e.target.value)} value={flyerBuilderState.agentDetails.socialInfo || ''} />
		</div>
	)
}
