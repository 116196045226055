'use client'

import { Step, stepOrder } from '@/features/flyer/configs/steps'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useModalState } from '@/utils/hooks/useModalState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import { useRouter } from 'next/navigation'
import React from 'react'
import { ActionLink, Button, FlyerBuilderNavigationButton, InfoIcon } from '../uiComponents'

const FlyerBuilderStepper = () => {
	const modalState = useModalState()
	const router = useRouter()
	const provideUrl = useUrlProvider()
	const { activeStep, setActiveStep } = useFlyerBuilderState((state) => state)
	const sitecoreStore = useSitecoreState((state) => state)

	const titles: { [key in Step]: string } = {
		destination: sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-STEP-DESTINATION', 'Choose a destination'),
		itinerary: sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-STEP-ITINERARY', 'Select an itinerary'),
		priceList: sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-STEP-PROMOTIONS', 'Apply a promotion'),
		agentDetails: sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-STEP-AGENT_DETAILS', 'Add Travel Agents details'),
		notes: sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-STEP-NOTES', 'Additional notes'),
		flyer: sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-STEP-FLYER', 'Download flyer'),
	}

	const closeLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-CLOSE', 'Close')
	const buildYourFlyerLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-MSC-BUILD_YOUR_FLYER', 'Build your flyer')
	const readInstructionsLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-READ-INSTRUCTIONS', 'Read instructions')

	const handleClose = () => {
		modalState.openInfoModal({
			variant: 'confirm-abort',
			children: sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-CLOSE-MESSAGE', 'Are you sure you want to close the flyer builder?'),
			confirmBehavior: () => {
				router.push(provideUrl('home'))
				modalState.close()
			},
		})
	}

	return (
		<div className="flex items-center px-6 bg-white shadow-sm">
			<div className="flex flex-col justify-center py-6 gap-6">
				<div className="flex gap-4">
					<h2 className="uppercase text-msc-blue text-xl font-bold">{buildYourFlyerLabel}</h2>
					<ActionLink
						icon={<InfoIcon size="12" color="#035FBF" />}
						onClick={() => {
							modalState.openBuildYourFlyerInstructionTemplate()
						}}
					>
						{readInstructionsLabel}
					</ActionLink>
				</div>
				<div className="flex flex-col xl:flex-row gap-4">
					<div className="flex gap-6">
						{stepOrder.map((child, index) => (
							<React.Fragment key={index}>
								<FlyerBuilderNavigationButton isActive={index + 1 === activeStep} isDisabled={index + 1 > activeStep} buttonId={child} onClick={() => setActiveStep(index + 1)}>{`${index + 1}. ${titles[child]}`}</FlyerBuilderNavigationButton>
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
			<div className="ml-auto">
				<Button variant="outlined" onClick={handleClose}>
					{closeLabel}
				</Button>
			</div>
		</div>
	)
}

export { FlyerBuilderStepper }
