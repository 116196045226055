'use client'

import { customStyles } from '@/components/atoms/SelectWrapper'
import Select from 'react-select'
import { DevTools } from '../configs/devToolsConfigs'
import { States } from '../models/dev-tools-model'
import { useDevToolsState } from '../store/useDevToolsState'

export const StateSelectActivator = () => {
	const store = useDevToolsState()

	const changeValue = async (value: string | undefined) => {
		store.set(DevTools.SHOW_STATE, value)
	}

	return (
		<div className="flex gap-3">
			{/* eslint-disable-next-line prettier/prettier */}
			{/* prettier-ignore */}
			<Select
				isClearable={true}
				options={[...States.map((state) => ({ value: state, label: state }), { value: undefined, label: 'None' })]}
				placeholder="Show zustand state"
				onChange={(option) => changeValue(option?.value)}
				styles={customStyles}
				className="w-full"
				defaultValue={store.showState ? { label: store.showState, value: store.showState } : null}
			/>
		</div>
	)
}
