'use client'

import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useEffect } from 'react'
import { ContentEditableEvent } from 'react-simple-wysiwyg'
import { RichedTextArea } from '../_exporter'

export const NotesStep = () => {
	const flyerBuilderState = useFlyerBuilderState((state) => ({
		updateStepData: state.updateStepData,
		notesStep: state.steps.notes,
	}))

	const { pushLabelClickedEvent } = useGoogleAnalytics()
	useEffect(() => pushLabelClickedEvent({ event: 'stepView', eventName: 'step_view', label_clicked: 'Additional notes' }), [])

	const sitecoreStore = useSitecoreState((state) => state)
	const addParagraphLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD_PARAGRAPH-ADDITIONAL_NOTES_STEP', 'Add an additional note')
	const addAdditionalNotesLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD-ADDITIONAL-NOTES', 'Add additional notes')
	const textCountLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADDITIONAL-TEXT', 'Text count')
	const notesMaxLength = sitecoreStore.getNumber(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADDITIONAL-NOTES-MAX-LENGTH', 200)

	const handleAdditionalParagraphOnChange = (event: ContentEditableEvent) => {
		const currentValue = event.target.value
		const actualLength = currentValue.replace(/<\/?[^>]+(>|$)/g, '').length
		if (actualLength > notesMaxLength) return
		flyerBuilderState.updateStepData('notes', { additionalNote: event.target.value })
	}

	return (
		<div className="w-[372px]">
			<h2 className="text-sm font-bold uppercase mb-[72px]">{addAdditionalNotesLabel}</h2>
			<RichedTextArea label={addParagraphLabel} characterCounterText={textCountLabel} maxLength={notesMaxLength} value={flyerBuilderState.notesStep.additionalNote ?? ''} onChange={handleAdditionalParagraphOnChange} onDeleteButtonClicked={() => flyerBuilderState.updateStepData('notes', { additionalNote: undefined })} />
		</div>
	)
}
