'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { FaLock } from 'react-icons/fa'
import destination from '../../../public/images/destinations.png'
import styles from './NavDropdown.module.css'

interface HeaderLinks {
	label: string
	logged: boolean
	url: string
	openInNewPage: boolean
	flags: string[]
	analiticsKey: string[]
	subMenu: HeaderLinks[]
}

interface NavDropdownProps {
	headerLinks: HeaderLinks[]
	navMenuItemId: string
	navMenuItem: string
}

const mapImage = <Image src={destination} alt="destination map" width={400} className="h-fit ml-1 my-10 hidden desktop-l:inline"></Image>

const NavDropdown = ({ headerLinks, navMenuItemId, navMenuItem }: NavDropdownProps) => {
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const { pushLabelClickedEvent } = useGoogleAnalytics()

	const isUsa = market.code === 'USA'
	let isShipAndDestinationTab
	if (isUsa) {
		isShipAndDestinationTab = navMenuItemId === 'destination' && headerLinks[0].label !== 'Fleet Overview' //do not show map if Ship&Destination in usa market
	} else {
		isShipAndDestinationTab = navMenuItemId === 'destination' //check if Ship&Destination
	}
	let excludeShips = false //flag used to to only get the destinations

	const isShipAndDestinationTabUsa = navMenuItemId === 'destination' ? 'columns-4' : ''
	const cssToSplitCol = isUsa ? isShipAndDestinationTabUsa : isShipAndDestinationTab ? 'columns-2' : ''
	const mapButtons: string[] = []

	const [hoveredIndex, sethoveredIndex] = useState<number>(-1)

	const userLogged = user !== undefined

	const sitecoreStore = useSitecoreState((state) => state)
	const loginRequiredLabel = sitecoreStore.getLabel(TranslationBundles.WELCOME, 'MSC-EXTERNAL-WELCOMEPAGE-LOGIN-REQUIRED', 'Login required')

	return (
		<div className="w-full flex justify-center">
			<ul className="flex flex-wrap w-[1300px] pt-5 pb-10 desktop-xsm:pl-64 desktop-sm:pl-56 desktop-m:pl-44 desktop-l:pl-[170px]">
				{headerLinks.map((value, subMenuTitleIndex) => (
					<div key={subMenuTitleIndex} className="inline-block px-4 py-2 text-gray-700">
						<div className="font-bold uppercase mb-2 pb-1 border-b">{value?.label}</div>
						<div className={`${subMenuTitleIndex === 0 ? cssToSplitCol : ''} w-full relative right-4`}>
							{value?.subMenu?.map((item, linkIndex) => {
								if (item.analiticsKey[1] === 'MSC-HEADER_MENU-BOOKING-BOOK_NOW') {
									item.logged = true
								} //manually set the flag to true, header menu service not correct for individual booking
								if (isShipAndDestinationTab) {
									if (excludeShips || !item.label.includes('MSC')) {
										//get destinations' name to create the buttons for the map
										mapButtons.push(item.label)
										excludeShips = true
									}
								}
								return (
									<li key={item.label} className={`pb-1 flex items-center ${userLogged ? 'ml-4' : ''} ${!item.logged ? 'ml-4' : ''}`}>
										{!userLogged && item.logged && <FaLock size={16} className="pr-1" />}
										<Link
											prefetch={false}
											onClick={(e) => {
												pushLabelClickedEvent({ event: 'menuClick', eventName: 'menu_click', label_clicked: `${navMenuItem} > ${value.label} > ${item.label}` })
												if (item.url.toLowerCase().includes('group_quotator')) {
													pushLabelClickedEvent({ event: 'new_group_request', eventName: 'new_group_request', label_clicked: `${navMenuItem} > ${value.label} > ${item.label}` })
												}
												if (!userLogged && item.logged) {
													e.preventDefault()
													useModalState.getState().openLoginModal({ nextUrl: item.url })
												}
											}}
											onMouseEnter={() => sethoveredIndex(mapButtons.includes(item.label) ? linkIndex : -1)}
											onMouseLeave={() => sethoveredIndex(-1)}
											href={item.url}
											className={clsx('block text-gray-900 hover:text-gray-400 lg:text-md w-48 whitespace-normal break-words', styles[`destinationAreaTextColor-${hoveredIndex}`], {
												'text-nowrap': isShipAndDestinationTab,
											})}
										>
											{item.label}

											{mapButtons.length > 0 &&
												mapButtons.map((mapButton, index) => {
													return item.label === mapButton ? (
														<button key={index} className={clsx('relative border border-[#babdcf] rounded-[3px] px-[10px] py-[3px] text-xs invisible desktop-l:visible', styles[`destinationArea-${index}`], hoveredIndex === index ? styles[`destinationAreaHoverDestinationList-${hoveredIndex}`] : 'bg-white')}>
															{mapButton}
														</button>
													) : null
												})}
										</Link>
									</li>
								)
							})}
						</div>
					</div>
				))}
				{isShipAndDestinationTab && mapImage}
				<div>
					<div className="flex items-center justify-center gap-1 absolute right-40 bottom-4">
						{!userLogged && (
							<>
								<FaLock size={16} fill="black" className="w-4 h-4 pr-1" />
								<span className="text-gray-700 text-xs">{loginRequiredLabel}</span>
							</>
						)}
					</div>
				</div>
			</ul>
		</div>
	)
}

export { NavDropdown }
