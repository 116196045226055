import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as ElasticFilterActions from './actions/filterb2b-actions'
import * as ElasticSearchActions from './actions/pricesb2b-actions'

// eslint-disable-next-line prettier/prettier
const ElasticService = createProxiedActions<typeof ElasticFilterActions & typeof ElasticSearchActions>({
	modules: [
		ElasticFilterActions,
		ElasticSearchActions,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default ElasticService
