// Migrated Configuration from the old format

import { SearchCruise } from '../model/search-cruise'
import IOccupancyConfig = SearchCruise.Occupancy.IOccupancyConfig

export const OCCUPANCY_CONFIG: IOccupancyConfig = {
	defaultOccupancy: { adults: 2, children: 0, juniorChildren: 0, infants: 0 },
	durationChunks: [5, 7],
	shipCapacities: {
		10: ['SE', 'SV', 'BE', 'GR', 'VI', 'SH', 'MR', 'SC', 'EU', 'ER'],
		6: ['PR', 'DI'],
		5: ['MA', 'MX'],
	},
	occupancyRules: {
		allowedCombinations: [
			{ adults: 1, children: 0, juniorChildren: 0, infants: 0 },
			{ adults: 1, children: 1, juniorChildren: 0, infants: 0 },
			{ adults: 1, children: 1, juniorChildren: 1, infants: 0 },
			{ adults: 1, children: 0, juniorChildren: 1, infants: 0 },
			{ adults: 1, children: 2, juniorChildren: 0, infants: 0 },
			{ adults: 1, children: 0, juniorChildren: 2, infants: 0 },
			{ adults: 2, children: 0, juniorChildren: 0, infants: 0 },
			{ adults: 2, children: 1, juniorChildren: 0, infants: 0 },
			{ adults: 2, children: 1, juniorChildren: 1, infants: 0 },
			{ adults: 2, children: 0, juniorChildren: 0, infants: 1 },
			{ adults: 2, children: 0, juniorChildren: 1, infants: 0 },
			{ adults: 2, children: 0, juniorChildren: 1, infants: 1 },
			{ adults: 2, children: 2, juniorChildren: 0, infants: 0 },
			{ adults: 2, children: 0, juniorChildren: 2, infants: 0 },
			{ adults: 3, children: 0, juniorChildren: 0, infants: 0 },
			{ adults: 3, children: 1, juniorChildren: 0, infants: 0 },
			{ adults: 3, children: 0, juniorChildren: 1, infants: 0 },
			{ adults: 4, children: 0, juniorChildren: 0, infants: 0 },
			{ adults: 2, children: 0, juniorChildren: 3, infants: 0 },
			{ adults: 2, children: 3, juniorChildren: 0, infants: 0 },
			{ adults: 5, children: 0, juniorChildren: 0, infants: 0 },
		],
		additionalRules: {
			maxAdults: 4,
			maxChildren: 3,
			maxTotal: 5,
			infantWithAdult: true,
		},
	},
}
