import { createLoggingProxy } from '@/utils/services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '@/utils/services/actions/proxies/performance-proxy'
import { createProxiedActions } from '@/utils/services/services'
import * as SitecoreContent from './actions/bundle-of-bundles'
import * as SitecoreActions from './actions/label-provider'
import * as SitecoreBundleActions from './actions/services/sitecore-bundle'

const SitecoreService = createProxiedActions<typeof SitecoreActions & typeof SitecoreContent & typeof SitecoreBundleActions>({
	modules: [
		SitecoreActions,
		SitecoreContent,
		SitecoreBundleActions,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default SitecoreService
