import React from 'react'

interface ColoredCheckboxIconInterface {
	color?: string
}

export const ColoredCheckboxIcon = ({ color = '#EE732F' }: ColoredCheckboxIconInterface) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="0.5" width="19" height="19" rx="3.5" fill={color} stroke={color} />
			<path d="M15.5915 6.95197L15.5867 6.94607L15.5813 6.94057C15.4008 6.75356 15.1103 6.75315 14.9293 6.93931L9.66201 12.2296L7.08507 9.63469C6.90401 9.44841 6.61351 9.44879 6.43289 9.63584C6.2557 9.81933 6.2557 10.1087 6.43289 10.2922L6.43288 10.2923L6.43485 10.2942L9.08046 12.9583C9.23676 13.1196 9.44983 13.2 9.6473 13.2C9.86196 13.2 10.0596 13.1177 10.2141 12.9584L15.5468 7.60234C15.7436 7.4199 15.7421 7.1339 15.5915 6.95197ZM9.7327 12.3008L9.7317 12.2998C9.73206 12.3001 9.73237 12.3005 9.73269 12.3008L9.7327 12.3008Z" fill="white" stroke="white" strokeWidth="0.4" />
		</svg>
	)
}
