'use client'

import { ReactElement, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useViewportType } from '../ui-hooks/useViewportType'
import { DeparturesIcon, TabButton, TabList } from '../uiComponents'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { compareAsc, endOfDay, endOfMonth, isValid, max, min, parse, startOfMonth } from 'date-fns'
import { enGB } from 'date-fns/locale/en-GB'
import { startOfToday } from 'date-fns/startOfToday'
import { useElasticFilterState } from '@/features/elastic/store/useElasticFilterState'
import { ElasticFilterState, IOption } from '@/features/elastic/store/types'
import { convertDateString, getLocaleByMarket, LocaleKey, localesMap } from '@/utils/lib/date-utils'

/**
 * 	IMPORTANT INFORMATION ABOUT THE CALENDAR
 * 	When in month view, the datepicker calendar accepts as valid dates only the first day of the month.
 * 	That's why we have distinct variables for displayedStart, displayedEnd and finalStart, finalEnd.
 * 	While the first 2 are used to display the selected date in the input field following business rules,
 * 	the other 2 are used only for the correct functioning of the calendar
 */

type DateRange = {
	start: Date
	end: Date
}

const generateDateRanges = (dates: Date[]): DateRange[] => {
	if (dates.length === 0) return []

	const sortedDates = [...dates].sort((a, b) => a.getTime() - b.getTime())
	const ranges: DateRange[] = []
	let currentRangeStart = sortedDates[0]

	for (let i = 1; i < sortedDates.length; i++) {
		const currentDate = sortedDates[i]
		const nextDay = new Date(currentRangeStart)
		nextDay.setDate(nextDay.getDate() + 1)

		if (currentDate.getTime() !== nextDay.getTime()) {
			ranges.push({
				start: new Date(currentRangeStart.setHours(0, 0, 0, 0)), // Start of the range at midnight
				end: new Date(sortedDates[i - 1].setHours(23, 59, 59, 999)), // End of the range at 23:59:59.999 of the previous day
			})
			currentRangeStart = currentDate
		}
	}

	// Push the last range
	ranges.push({
		start: new Date(currentRangeStart.setHours(0, 0, 0, 0)),
		end: new Date(sortedDates[sortedDates.length - 1].setHours(23, 59, 59, 999)),
	})

	return ranges
}

const generateMonthRanges = (dates: Date[]): Date[] => {
	if (dates.length === 0) return []

	const monthMap = new Map<string, Date>()

	dates.forEach((date) => {
		const monthKey = `${date.getFullYear()}-${date.getMonth()}`
		if (!monthMap.has(monthKey)) {
			monthMap.set(monthKey, endOfMonth(date))
		}
	})

	return Array.from(monthMap.values()).sort((a, b) => a.getTime() - b.getTime())
}

interface DatePickerWrapperProps {
	departureList: IOption[]
	pickersWrapperRef?: HTMLDivElement | null
}

const DatePickerWrapper = ({ departureList, pickersWrapperRef }: DatePickerWrapperProps) => {
	const viewport = useViewportType()
	const today = startOfToday()

	const { departureTo, departureFrom, setDateFilter } = useElasticFilterState((state: ElasticFilterState) => ({
		departureTo: state.options.departureTo,
		departureFrom: state.options.departureFrom,
		setDateFilter: state.setDateFilter,
	}))

	const market = useCurrentMarket()
	const sitecoreStore = useSitecoreState((state) => state)

	const departureFromDate = departureFrom && new Date(convertDateString(departureFrom))
	const departureToDate = departureTo && new Date(convertDateString(departureTo))
	const currentYear = new Date().getFullYear()

	//TODO: Create label
	const dateFormatLabel = 'dd/mm/yyyy' //no one asked for config but just in case -> MSC-CRUISERESULTS_DATE
	const returnLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_RETURN', 'Return')
	const departureLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_PLACEHOLDER_DATES', 'Departure dates')
	const searchCalendarNoteLabel = sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-SENTENCE_BEFORE_CRUISE_RESULTS', 'The maximum search period is 4 months')
	const isMonthViewEnabled = 'true'
	const isMonthTabDefault = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_MONTH_TAB_DEFAULT', 'false')
	const isCalendarMultiple = 'true'
	const monthsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_MONTHS', 'Months')
	const daysLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_DAYS', 'Days')
	// const resetLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_RESET', 'Reset')

	const [monthsToShow, setMonthsToShow] = useState(3)
	const [includeDates, setIncludeDates] = useState<DateRange[]>([])
	const [includeMonths, setIncludeMonths] = useState<Date[]>([])
	const [startDate, setStartDate] = useState<Date | null>(null)
	const [endDate, setEndDate] = useState<Date | null>(null)
	const [isCalendarOpen, setIsCalendarOpen] = useState(false)
	const [locale, setLocale] = useState<string>(getLocaleByMarket(market) || 'en-GB')
	const [shouldShowMonthsView, setShouldShowMonthsView] = useState(isMonthTabDefault === 'true' && isMonthViewEnabled === 'true')
	const [isDropdownJustOpened, setIsDropdownJustOpened] = useState(false)

	useEffect(() => {
		console.log('calendar state: startDateChanged or endDateChanged')
		console.log(startDate)
		console.log(endDate)
	}, [startDate, endDate])

	useEffect(() => {
		console.log('elastic state: departureto or departurefrom')
		console.log(departureTo)
		console.log(departureFrom)
	}, [departureTo, departureFrom])

	//Used for manual input
	const maxDate = useMemo(() => max([departureList.map((option) => new Date(convertDateString(option.label))).reduce((latest, current) => (latest > current ? latest : current), new Date(0))]), [departureList])
	const minDate = useMemo(() => min([departureList.map((option) => new Date(convertDateString(option.label))).reduce((latest, current) => (latest < current ? latest : current), new Date(9999999999999))]), [departureList])

	const calendarRef = useRef<HTMLDivElement>(null)
	const departureInputRef = useRef<HTMLInputElement>(null)
	const calendarSwitchRef = useRef<HTMLDivElement>(null)
	const calendarLabelRef = useRef<HTMLParagraphElement>(null)

	const dayLangOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	}

	// useEffect(() => {
	// 	if (startDate && endDate) {
	// 		setDateFilter(startDate, endDate)
	// 	}
	// }, [startDate, endDate])

	useEffect(() => {
		if (departureFromDate) {
			setStartDate(departureFromDate)
		}
		if (departureToDate) {
			setEndDate(departureToDate)
		}
		const marketLocale = getLocaleByMarket(market)
		setLocale(marketLocale)
	}, [])

	useEffect(() => {
		if (viewport === 'isMobile') {
			setMonthsToShow(2)
		} else if (viewport === 'isTablet') {
			setMonthsToShow(2)
		} else if (viewport === 'isDesktop') {
			isCalendarMultiple === 'true' ? setMonthsToShow(3) : setMonthsToShow(2)
		} else if (viewport === 'isDesktopZoomed') {
			setMonthsToShow(2)
		} else if (viewport === 'isDesktopLarge') {
			isCalendarMultiple === 'true' ? setMonthsToShow(3) : setMonthsToShow(2)
		} else {
			isCalendarMultiple === 'true' ? setMonthsToShow(4) : setMonthsToShow(2)
		}
	}, [viewport])

	useEffect(() => {
		const convertedDates = generateDateRanges(departureList.map((el) => new Date(el.label)))
		const convertedMonths = generateMonthRanges(departureList.map((el) => new Date(el.label)))
		setIncludeDates(convertedDates)
		setIncludeMonths(convertedMonths)
	}, [departureList])

	/**
	 * Retrieves the last available date in a specified month from the departure list.
	 * Falls back to the end of month if no dates are available.
	 *
	 * @param {Date} month - The month to search for available dates
	 * @returns {Date} The last available date in the month
	 */
	const getLastAvailableDateInMonth = (month: Date): Date => {
		const filteredDates = departureList.filter((el) => new Date(el.label).getMonth() === month.getMonth() && new Date(el.label).getFullYear() === month.getFullYear())

		return filteredDates.length ? new Date(filteredDates[filteredDates.length - 1].label) : endOfMonth(month)
	}

	/**
	 * Retrieves the first available date in a specified month from the departure list.
	 * Falls back to the first day of the month if no dates are available.
	 *
	 * @param {Date} month - The month to search for available dates
	 * @returns {Date} The first available date in the month
	 */
	const getFirstAvailableDateInMonth = (month: Date): Date => {
		const filteredDates = departureList.filter((el) => new Date(el.label).getMonth() === month.getMonth() && new Date(el.label).getFullYear() === month.getFullYear())

		return filteredDates.length ? new Date(filteredDates[0].label) : startOfMonth(month)
	}

	/**
	 * Calculates the maximum allowed end date, which is exactly 4 months from the start date.
	 *
	 * @param {Date} startDate - The starting date
	 * @returns {Date} The maximum allowed end date (4 months minus 1 day from start)
	 */
	const calculateMaxEndDate = (startDate: Date): Date => {
		const maxEndDate = new Date(startDate.getTime())
		maxEndDate.setMonth(maxEndDate.getMonth() + 4)
		maxEndDate.setDate(maxEndDate.getDate() - 1) // Subtract one day to make it exactly 4 months
		return maxEndDate
	}

	/**
	 * Formats a date range as a string using locale-specific formatting.
	 *
	 * @param {Date} start - The start date
	 * @param {Date} end - The end date
	 * @returns {string} Formatted date range string (e.g., "01/01/2025 - 31/01/2025")
	 */
	const formatDateRange = (start: Date, end: Date): string => {
		return `${start.toLocaleDateString(locale, dayLangOptions)} - ${end.toLocaleDateString(locale, dayLangOptions)}`
	}

	/**
	 * Updates the departure input field with formatted date range and applies date filtering.
	 * Only updates if the input is not currently focused.
	 *
	 * @param {Date} start - The start date to set
	 * @param {Date} end - The end date to set
	 */
	const updateInputAndFilter = (start: Date, end: Date): void => {
		if (departureInputRef.current && departureInputRef.current !== document.activeElement) {
			departureInputRef.current.value = formatDateRange(start, end)
			setDateFilter(start, end)
		}
	}

	/**
	 * Handles date selection changes when in Day view.
	 * Processes day-specific logic including constraints and UI updates.
	 *
	 * @param {Date | null} start - Selected start date
	 * @param {Date | null} end - Selected end date
	 */
	const handleDayViewDateChange = (start: Date | null, end: Date | null) => {
		let displayedEnd: Date | undefined

		// Handle start date
		let finalStart: Date | null = start
		if (start) {
			// In day view, use today if selecting current date or earlier
			finalStart = max([start, today])
		}

		// Handle end date
		let finalEnd: Date | null = end
		if (finalStart && end) {
			const maxEndDate = calculateMaxEndDate(finalStart)
			// For day view, ensure we don't exceed maxEndDate
			finalEnd = min([end, maxEndDate])
		} else if (finalStart) {
			displayedEnd = endOfDay(finalStart)
		}

		// Update state
		setStartDate(finalStart)
		setEndDate(finalEnd)

		// Update UI if input is not focused
		if (departureInputRef.current && departureInputRef.current !== document.activeElement && finalStart) {
			if (finalEnd) {
				updateInputAndFilter(finalStart, finalEnd)
			} else if (displayedEnd) {
				updateInputAndFilter(finalStart, endOfDay(displayedEnd))
			}
		}
	}

	/**
	 * Handles date selection changes when in Month view.
	 * Processes month-specific logic including constraints and UI updates.
	 *
	 * @param {Date | null} start - Selected start date (month)
	 * @param {Date | null} end - Selected end date (month)
	 */
	const handleMonthViewDateChange = (start: Date | null, end: Date | null) => {
		let displayedStart: Date | undefined
		let displayedEnd: Date | undefined

		// Handle start date
		const finalStart: Date | null = start
		if (start) {
			if (end && compareAsc(end, start) > 0) {
				displayedStart = max([startOfMonth(start), today])
			} else {
				//If there's no end date, use the first available date in the selected month (accounting for filters)
				displayedStart = max([startOfMonth(start), today, getFirstAvailableDateInMonth(start)])
			}
		}

		// Handle end date
		const finalEnd: Date | null = end
		if (finalStart && end) {
			const maxEndDate = calculateMaxEndDate(finalStart)
			const lastAvailableDateCurrentMonth = getLastAvailableDateInMonth(end)

			// Handle same month selection
			if (start && start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
				displayedEnd = min([endOfMonth(end), maxEndDate])
				if (lastAvailableDateCurrentMonth && displayedEnd > lastAvailableDateCurrentMonth) {
					displayedEnd = lastAvailableDateCurrentMonth
				}
			} else {
				// Different month selection
				const endOfSelectedMonth = endOfMonth(end)
				displayedEnd = min([endOfSelectedMonth, maxEndDate])
			}
		} else if (finalStart) {
			// Auto-complete date range when only start date is selected
			const lastAvailableDate = getLastAvailableDateInMonth(finalStart)

			if (lastAvailableDate) {
				displayedEnd = lastAvailableDate
			}
		}

		// Update state calendar
		setStartDate(finalStart)
		setEndDate(finalEnd)

		// Update UI if input is not focused
		if (departureInputRef.current && departureInputRef.current !== document.activeElement && finalStart && displayedStart) {
			if (finalEnd && displayedEnd) {
				updateInputAndFilter(displayedStart, displayedEnd)
			} else {
				const lastDate = getLastAvailableDateInMonth(finalStart)
				if (lastDate) {
					updateInputAndFilter(displayedStart, lastDate)
				}
			}
		}
	}

	/**
	 * Main date change handler that delegates to the appropriate specialized handler
	 * based on the current view mode (month or day).
	 *
	 * @param {[Date | null, Date | null]} dates - Array containing selected start and end dates
	 */
	const handleDateChange = useMemo(
		() => (dates: [Date | null, Date | null]) => {
			const [start, end] = dates

			if (shouldShowMonthsView) {
				handleMonthViewDateChange(start, end)
			} else {
				handleDayViewDateChange(start, end)
			}

			if (isDropdownJustOpened) {
				setIsDropdownJustOpened(false)
			}
		},
		[shouldShowMonthsView, handleMonthViewDateChange, handleDayViewDateChange, isDropdownJustOpened]
	)

	//called when writing to input textbox for dates
	const handleDateInputChange = (input: string) => {
		// Remove non-numeric characters
		const numericInput = input.replace(/\D/g, '')

		// Add slashes between day, month, and year
		let formattedInput = numericInput
		if (numericInput.length > 2) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2)}`
		}
		if (numericInput.length > 4) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2, 4)}/${numericInput.slice(4)}`
		}
		if (numericInput.length > 8) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2, 4)}/${numericInput.slice(4, 8)} - ${numericInput.slice(8, 10)}`
		}
		if (numericInput.length > 10) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2, 4)}/${numericInput.slice(4, 8)} - ${numericInput.slice(8, 10)}/${numericInput.slice(10, 12)}`
		}
		if (numericInput.length > 12) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2, 4)}/${numericInput.slice(4, 8)} - ${numericInput.slice(8, 10)}/${numericInput.slice(10, 12)}/${numericInput.slice(12)}`
		}

		// Update the input field value~
		if (departureInputRef.current) {
			departureInputRef.current.value = formattedInput
		}

		// Parse the date
		const localeObj = localesMap[locale as LocaleKey] || enGB
		const formats = ['dd/MM/yyyy', 'd MMMM yyyy']
		const inputWithYear = formattedInput.match(/\d{4}/) ? formattedInput : `${formattedInput} ${currentYear}`

		let parsedStartDate = undefined
		let parsedEndDate = undefined
		for (const format of formats) {
			try {
				parsedStartDate = parse(inputWithYear.split(' - ')[0], format, new Date(), { locale: localeObj })
				parsedEndDate = parse(inputWithYear.split(' - ')[1], format, new Date(), { locale: localeObj })
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
			} catch (e) {
				continue
			}
			if (isValid(parsedStartDate) && isValid(parsedEndDate)) break
		}

		if (!parsedStartDate || !parsedEndDate || !isValid(parsedStartDate) || !isValid(parsedEndDate)) return
		console.log(parsedStartDate, parsedEndDate)

		if (compareAsc(minDate, parsedStartDate) > 0) {
			parsedStartDate = minDate
		} else if (compareAsc(parsedStartDate, maxDate) > 0) {
			parsedStartDate = maxDate
		}

		//Start Date
		if (parsedEndDate && compareAsc(parsedEndDate, parsedStartDate) > 0) {
			parsedStartDate = max([startOfMonth(parsedStartDate), today])
		} else {
			//If there's no end date, use the first available date in the selected month (accounting for filters)
			parsedStartDate = max([startOfMonth(parsedStartDate), today, getFirstAvailableDateInMonth(parsedStartDate)])
		}

		//End Date
		if (compareAsc(minDate, parsedEndDate) > 0) {
			parsedEndDate = minDate
		} else if (compareAsc(parsedEndDate, maxDate) > 0) {
			parsedEndDate = maxDate
		}
		if (compareAsc(parsedEndDate, calculateMaxEndDate(parsedStartDate)) > 0) {
			parsedEndDate = calculateMaxEndDate(parsedStartDate)
		}
		if (compareAsc(parsedStartDate, parsedEndDate) > 0) {
			parsedEndDate = parsedStartDate
		}
		// if (compareAsc(parsedEndDate, maxDate) > 0) {
		// 	parsedEndDate = maxDate
		// }
		// parsedEndDate = min([])
		// parsedEndDate = min([max([parsedEndDate, today, parsedStartDate]), maxEndDate, lastAvailableDateCurrentMonth])

		setStartDate(parsedStartDate)
		setEndDate(parsedEndDate)
		setDateFilter(parsedStartDate, parsedEndDate)
	}

	/**
	 * Handles blur event for the date input field.
	 * Called when text input field becomes out of focus
	 * Updates the input value based on selected dates when calendar is open.
	 *
	 * @param {RefObject<HTMLInputElement>} input - Reference to the input element
	 */
	const handleBlur = (input: RefObject<HTMLInputElement>) => {
		if (!input.current || !isCalendarOpen) return

		if (startDate && !endDate) {
			input.current.value = startDate?.toLocaleDateString(locale, dayLangOptions) || ''
		} else if (startDate && endDate) {
			input.current.value = formatDateRange(startDate, endDate)
		}
	}

	//called when text input field is focused
	const handleFocus = (input: RefObject<HTMLInputElement>) => {
		if (input.current) {
			input.current.value = ''
		}
		setStartDate(null)
		setEndDate(null)
	}

	const customInput = useMemo(() => {
		const labelClasses = 'absolute left-11 transform top-1  text-xxs transition-all duration-200 ease-in-out text-gray-500 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-dark peer-focus:top-1 peer-focus:transform peer-focus:-translate-y-0 peer-focus:text-xxs peer-focus:text-dark'
		let defaultValue = ''
		if (startDate) {
			defaultValue = startDate?.toLocaleDateString(locale, dayLangOptions)
		}
		if (startDate && endDate) {
			defaultValue = (startDate?.toLocaleDateString(locale, dayLangOptions) || '') + ' - ' + (endDate?.toLocaleDateString(locale, dayLangOptions) || '')
		}
		return (
			<div className="flex flex-1 justify-around py-2 px-5 text-sm text-light-purple border-msc-blue border focus:outline-none rounded-sm placeholder-transparent peer focus:ring-3 focus:ring-focus-blue">
				<div className="flex flex-1 items-center py-1 cursor-pointer">
					<div className="absolute pointer-events-none">
						<DeparturesIcon />
					</div>
					<input id={'dateInput'} autoComplete={'off'} ref={departureInputRef} className="peer text-sm w-full text-msc-blue placeholder-msc-blue border-0 shadow-none pl-6 cursor-pointer focus:outline-none" placeholder={''} defaultValue={defaultValue} onFocus={() => handleFocus(departureInputRef)} onBlur={() => handleBlur(departureInputRef)} type="text" maxLength={23} onChange={(e) => handleDateInputChange(e.target.value)} />
					<label htmlFor={'dateInput'} className={labelClasses}>
						<span className={'font-bold'}>{departureLabel}</span>{' '}
						<span>
							{dateFormatLabel} - {dateFormatLabel}
						</span>
					</label>
				</div>
			</div>
		)
	}, [startDate, endDate, locale, departureLabel, returnLabel])

	useEffect(() => {
		if (isCalendarOpen) {
			const datePickerWrapper = calendarRef.current?.querySelector('.datePickerPopperWrapper')
			if (datePickerWrapper) {
				const computedStyle = window.getComputedStyle(datePickerWrapper)
				const transformMatrix = computedStyle.transform

				if (transformMatrix && transformMatrix !== 'none') {
					const matrixValues = transformMatrix.match(/matrix.*\((.+)\)/)
					if (matrixValues && matrixValues[1]) {
						matrixValues[1].split(', ')
					}
				}
			}
		}
	}, [isCalendarOpen])

	const CalendarContainer = ({ className, children }: { className: string; children: ReactElement }) => {
		return (
			<div
				className={`relative ${isMonthViewEnabled === 'true' ? 'datePickerPadding' : ''} ${className}`}
				style={{
					maxWidth: monthsToShow === 2 && pickersWrapperRef?.clientWidth ? `${pickersWrapperRef?.clientWidth + 32}px` : 'auto',
				}}
			>
				{isMonthViewEnabled === 'true' && (
					<div ref={calendarSwitchRef} className="absolute top-4">
						<TabList reversedTabs={isMonthTabDefault !== 'true'}>
							<TabButton isActive={shouldShowMonthsView} onClick={() => setShouldShowMonthsView(true)}>
								<p className="text-sm">{monthsLabel}</p>
							</TabButton>
							<TabButton isActive={!shouldShowMonthsView} onClick={() => setShouldShowMonthsView(false)}>
								<p className="text-sm">{daysLabel}</p>
							</TabButton>
						</TabList>
					</div>
				)}
				{children}
				{<p ref={calendarLabelRef} className={`absolute  left-0 bottom-0 translate-y-full w-full text-xs bg-white px-6 pb-5 text-right`} dangerouslySetInnerHTML={{ __html: searchCalendarNoteLabel }} />}
			</div>
		)
	}

	const enableOnlyFourMonthsAfterSelectedDate = (date: Date) => {
		//clone start date
		const startingDate = startDate
		if (!startingDate || isDropdownJustOpened) return true

		const fourMonthsAfter = new Date(startingDate)
		fourMonthsAfter.setMonth(fourMonthsAfter.getMonth() + 4)

		if (shouldShowMonthsView) {
			return date >= (startOfMonth(startingDate) || new Date()) && date <= fourMonthsAfter
		}
		return date >= (startDate || new Date()) && date <= fourMonthsAfter
	}

	const popperModifiers = useMemo(
		() => [
			{
				name: 'offset',
				fn(state: any) {
					if (state.placement === 'top-start' && calendarLabelRef.current) {
						state.y = state.y - calendarLabelRef.current.clientHeight
					}
					return state
				},
			},
		],
		[calendarLabelRef]
	)

	const calcOpeningDate = useMemo(
		() => () => {
			return max([startDate || new Date(), new Date(), minDate])
		},
		[startDate, minDate]
	)

	const [calendarOpeningDate, setCalendarOpeningDate] = useState(new Date())
	useEffect(() => {
		if (startDate) setCalendarOpeningDate(calcOpeningDate())
	}, [startDate, endDate])

	const MemoizedCalendarContainer = useMemo(() => CalendarContainer, [isMonthViewEnabled, monthsToShow, pickersWrapperRef, isMonthTabDefault, shouldShowMonthsView, monthsLabel, daysLabel, searchCalendarNoteLabel])

	return (
		<div ref={calendarRef} className="flex-1  w-full z-50 ">
			<DatePicker
				selected={startDate}
				shouldCloseOnSelect={true}
				onCalendarOpen={() => {
					setIsCalendarOpen(true)
					setIsDropdownJustOpened(true)
					setStartDate(null)
					setEndDate(null)
					setDateFilter(undefined, undefined)
					departureInputRef.current && departureInputRef.current.focus()
				}}
				onCalendarClose={() => {
					setIsCalendarOpen(false)
				}}
				onChange={handleDateChange}
				openToDate={calendarOpeningDate}
				startDate={startDate}
				endDate={endDate}
				calendarContainer={MemoizedCalendarContainer}
				popperClassName="w-full datePickerPopperWrapper"
				includeDateIntervals={shouldShowMonthsView ? undefined : includeDates}
				includeDates={shouldShowMonthsView ? includeMonths : undefined}
				popperPlacement="bottom-start"
				wrapperClassName="w-full"
				customInput={customInput}
				showPreviousMonths={false}
				dateFormat="dd/MM/yyyy"
				filterDate={enableOnlyFourMonthsAfterSelectedDate}
				monthsShown={monthsToShow}
				selectsRange={true}
				locale={locale}
				minDate={new Date()}
				maxDate={maxDate}
				placeholderText="Departure from"
				popperModifiers={popperModifiers}
				showMonthYearPicker={shouldShowMonthsView}
				showFullMonthYearPicker={shouldShowMonthsView}
			/>
		</div>
	)
}

export { DatePickerWrapper }
