// Add here custom properties
export enum DevTools {
	SITECORE_KEYS_OVERRIDE = 'sitecoreKeysOverride',
	SHOW_BUNDLE_NAMES = 'showBundleNames',
	SHOW_STATE = 'showState',
}

export const DevToolsStyling = {
	[DevTools.SHOW_BUNDLE_NAMES]: 'bg-cyan-500',
}
