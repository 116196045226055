import { ElasticFilter } from '@/features/elastic/model/elastic-filter'
import { hashMD5 } from '@/utils/lib/utils'
import { QueryClient } from '@tanstack/react-query'
import ElasticService from '../elastic-service'

const key = 'callElasticFilter'
//tanstack query config
export const getElasticFilterConfig = (selected: ElasticFilter.ISelectedFilter) => {
	const hashedKey = hashMD5(selected)
	return {
		queryFn: () => ElasticService.callElasticFilter(selected),
		queryKey: [key, hashedKey],
	}
}

export const fetchQueryElasticFilter = async (client: QueryClient, selected: ElasticFilter.ISelectedFilter) => {
	return await client.fetchQuery(getElasticFilterConfig(selected))
}
