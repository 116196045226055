import { Bundle } from '@/features/sitecore/actions/services/sitecore-bundle'

export interface Destination {
	label: string
	area?: string
	region?: string
	port?: string
	images?: string[]
	headline: string
	copy: string
}

/**
 * Retrieve a list of destinations from the Sitecore bundle `TranslationBundles.BUILD_YOUR_FLYER_DESTINATIONS`.
 *
 * @returns {Destination[]} An array of destinations with their label, area, region, and checked status.
 */
export function getDestinationsFromSitecore(destinationsBundle: Bundle): Destination[] {
	const destinationCount = Object.keys(destinationsBundle).filter((key) => key.match(/DESTINATION_(\d*)_LABEL/) != null).length

	const destinations: Destination[] = []
	for (let i = 0; i < destinationCount; i += 1) {
		const label = destinationsBundle[`DESTINATION_${i}_LABEL`]
		const area = destinationsBundle[`DESTINATION_${i}_AREA`]
		const region = destinationsBundle[`DESTINATION_${i}_REGION`]
		const port = destinationsBundle[`DESTINATION_${i}_PORT`]
		const images = destinationsBundle[`DESTINATION_${i}_IMAGES`]
		const headline = destinationsBundle[`DESTINATION_${i}_HEADLINE`]
		const copy = destinationsBundle[`DESTINATION_${i}_COPY`]

		destinations.push({
			label,
			area: area || undefined, // Empty string is falsy
			region: region || undefined,
			port: port || undefined,
			images: images?.split(',') || [],
			headline: headline || '',
			copy: copy || '',
		})
	}

	return destinations
}

export interface Offer {
	priceNames: string[]
	promotionImages: string[]
	heroImage: string | undefined
	termsAndConditions: string
}

/*
 * Retrieve the offers from the Sitecore bundle for each priceName provided.
 *
 * @returns {Object} An object with an offer for each priceName.
 */
export function getOffersFromSitecore(offersBundle: Bundle, priceNames: string[]): { [key: string]: Offer } {
	const priceNameGroups = Object.keys(offersBundle).filter((key) => key.match(/MSC-BUILD_YOUR_FLYER-OFFER_\d_PRICENAMES/) != null)
	const defaultOffer = mapOffer(offersBundle, 0)
	const offers: { [key: string]: Offer } = {}
	priceNames.forEach((priceName) => {
		const foundPriceNamesKey = priceNameGroups.find((key) => offersBundle[key].toUpperCase().match(`${priceName.toUpperCase()}(\\||$)`) != null)
		const index = foundPriceNamesKey ? +(foundPriceNamesKey.match(/MSC-BUILD_YOUR_FLYER-OFFER_(\d)_PRICENAMES/)?.[1] || '0') : 0
		offers[priceName] = index === 0 ? defaultOffer : mapOffer(offersBundle, index)
	})

	return offers
}

function mapOffer(bundle: Bundle, i: number): Offer {
	const priceNames = bundle[`MSC-BUILD_YOUR_FLYER-OFFER_${i}_PRICENAMES`]
	const promotionImages = bundle[`MSC-BUILD_YOUR_FLYER-OFFER_${i}_IMAGES`]
	const heroImage = bundle[`MSC-BUILD_YOUR_FLYER-OFFER_${i}_HERO`]
	const termsAndConditions = bundle[`MSC-BUILD_YOUR_FLYER-OFFER_${i}_TC`]

	return {
		priceNames: priceNames?.split('|') || [],
		promotionImages: promotionImages && promotionImages?.trim() !== '' ? promotionImages.split('|') : [],
		heroImage: heroImage || undefined,
		termsAndConditions: termsAndConditions || '',
	}
}
