'use client'

import { ColoredCheckboxIcon } from '@/components/foundations/_exporter'
import React from 'react'
import { InfoIconWithPopover } from './InfoIconWithPopover'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

interface OrangeCheckboxType {
	isChecked: boolean
	handleIsChecked: (e: React.ChangeEvent<HTMLInputElement>) => void
	textNextToCheckbox?: string
}

const OrangeCheckbox = ({ isChecked, handleIsChecked, textNextToCheckbox }: OrangeCheckboxType) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const termsAndConditionsTitleLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-TERMS-AND-CONDITIONS-TITLE', 'Terms and conditions')
	const termsAndConditionsBodyLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-TERMS-AND-CONDITIONS-BODY', 'The MSC Cruises trademarks, the logo and all other distinguishing symbols of MSC Cruises S.A. are registered rights and therefore the exclusive property of MSC Cruises S.A. Any non-authorized use thereof is not permitted. The user undertakes not to sell or in any other way misuse the access to and/or the right to use the material and the visual assets included.​')
	return (
		<div className="flex gap-2.5 items-center">
			<div className="relative w-5 h-5">
				<input type="checkbox" name="terms-and-conditions" onChange={handleIsChecked} checked={isChecked} disabled={false} className={`cursor-pointer ${isChecked ? 'opacity-0' : 'opacity-100'}`} style={{ width: '20px', height: '20px' }} />
				{isChecked && (
					<div className="absolute bottom-0 right-0 pointer-events-none">
						<ColoredCheckboxIcon />
					</div>
				)}
			</div>
			<div className="relative w-full">
				<span className="text-sm max-w-sm">
					{textNextToCheckbox}
					<InfoIconWithPopover popoverMessageTitle={termsAndConditionsTitleLabel} popoverMessageBody={termsAndConditionsBodyLabel} className="inline" />
				</span>
			</div>
		</div>
	)
}

export { OrangeCheckbox }
