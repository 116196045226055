import { CABIN_CLASS_TO_LETTER, CABIN_CODE_TO_CLASS, MSC_CABIN_CLASSES } from '../../configs/cabin-categories'
import { IElasticFilterLabels } from '../elastic-filter-store-utils'
import { IOption } from '../types'

export function mapAnyCabinClassToLetter(cabinClass: string): string {
	return (CABIN_CLASS_TO_LETTER as Record<string, string>)[cabinClass] || cabinClass.charAt(0).toUpperCase()
}

export function createCabinListOptions(cabinList: string, labels?: IElasticFilterLabels): IOption[] {
	if (!cabinList) {
		return []
	}
	const cabinCodes = cabinList?.split(',') || []

	// Group cabin codes by their class
	const groupedCabins: { [className: string]: string[] } = {}
	for (const code of cabinCodes) {
		const className = CABIN_CODE_TO_CLASS[code]
		if (className) {
			if (!groupedCabins[className]) {
				groupedCabins[className] = []
			}
			groupedCabins[className].push(code)
		}
	}

	// Create IOption array from grouped cabins
	return Object.entries(groupedCabins).map(([className]) => ({
		label: labels ? translateCabins(className, labels) : className,
		value: className,
		checked: false,
		disabled: false,
	}))
}
export function mapCabinClassToCodes(cabinOptions: keyof typeof MSC_CABIN_CLASSES): string {
	const cabinClasses = cabinOptions.split(',')
	const cabinCodes = cabinClasses.map((cabinClass) => MSC_CABIN_CLASSES[cabinClass as keyof typeof MSC_CABIN_CLASSES] as string[])
	return cabinCodes.join(',')
}

function translateCabins(cabinClass: string, labels: IElasticFilterLabels | undefined): string {
	let label = cabinClass
	switch (cabinClass) {
		case 'OUT':
			label = labels?.cabin.oceanView.toUpperCase() || 'Oceanview'
			break
		case 'OUB':
			label = labels?.cabin.balcony.toUpperCase() || 'Balcony'
			break
		case 'INS':
			label = labels?.cabin.interior.toUpperCase() || 'Interior'
			break
		case 'YCB':
			label = labels?.cabin.yacht.toUpperCase() || 'Yacht'
			break
		case 'SUI':
			label = labels?.cabin.suite.toUpperCase() || 'Suite'
			break
	}
	return label
}
