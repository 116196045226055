'use client'

import { IMPORTANT_INFORMATION_COOKIE } from '@/features/agent/model/cookies'
import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect'
import { setTextCookie } from '@/utils/http-headers/actions/text-cookies'

export interface ImportantInformationsModalTemplateProps {
	page: string
}

export const ImportantInformationsModalTemplate = (props: ImportantInformationsModalTemplateProps) => {
	const endpoint = useEnvironmentState().getStringOrThrow('WEBSPHERE_BASE_ENDPOINT')

	useAsyncEffect(async () => {
		await setTextCookie(IMPORTANT_INFORMATION_COOKIE.name, IMPORTANT_INFORMATION_COOKIE.defaultValue)
	}, [])

	return (
		<>
			<iframe src={`${endpoint}/${props.page}`} data-page={props.page} className="w-full h-full min-h-450" />
		</>
	)
}
