import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { Highlight } from '@/features/sitecore/actions/highlights'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import React from 'react'
import { CardInformative, CardsWrapper } from '../uiComponents'

interface HighlightSectionProps {
	title: string
	highlights: Highlight[]
}

const HighlightSection = ({ title, highlights }: HighlightSectionProps) => {
	const { pushLabelClickedEvent } = useGoogleAnalytics()
	const user = useLoggedUser()

	const sendClickPromotionsGA4Event = (index: number) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({ ecommerce: null })
		window.dataLayer.push({
			event: 'select_promotion',
			eventName: 'select_promotion',
			user_id: user?.agency || '',
			network_id_u: user?.consortiumCode || '',
			agency_code_u: user?.agency || '',
			ecommerce: {
				items: [
					{
						promotion_id: highlights[index].bundleName?.split('_').pop(),
						promotion_name: highlights[index].title,
						creative_name: highlights[index].title,
						creative_slot: index + 1,
						location_id: 'Body',
						time_left: '',
						section: user ? 'Private' : 'Public',
					},
				],
			},
		})
	}

	const handleCardClick = (event: any, highlight: Highlight, index: number) => {
		pushLabelClickedEvent({ event: 'highlightedlink', eventName: 'highlighted_link', label_clicked: highlight.title })
		sendClickPromotionsGA4Event(index)
		if (highlight.logged && !user) {
			event.preventDefault()
			useModalState.getState().openLoginModal({ nextUrl: highlight.link })
		}
	}

	return (
		<CardsWrapper title={title} variant="highlights">
			{highlights.map((highlight, index) => (
				<React.Fragment key={index}>
					<CardInformative variant="highlights" bundleName={highlight.bundleName} image={highlight.image} title={highlight.title} description={highlight.subtitle} link={highlight.link} onClick={(event) => handleCardClick(event, highlight, index)} />
				</React.Fragment>
			))}
		</CardsWrapper>
	)
}

export { HighlightSection }
