'use client'
import AgentService from '@/features/agent/agent-service'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import { enableScrollBehavior } from '@/utils/lib/utils'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import blueDiamondCard from '../../../public/images/card_blue_diamond.png'
import classicCard from '../../../public/images/card_classic.png'
import diamondCard from '../../../public/images/card_diamond.png'
import goldCard from '../../../public/images/card_gold.png'
import silverCard from '../../../public/images/card_silver.png'
import welcomeCard from '../../../public/images/card_welcome.png'
import { Button } from '../uiComponents'

export interface VoyagerCardTemplateProps {
	nome: string
	cognome: string
	Data_Nascita: string
	cardType: string
	cardnr: string
	punti: string
}

export const VoyagerCardTemplate = ({ nome, cognome, Data_Nascita, cardType, cardnr, punti }: VoyagerCardTemplateProps) => {
	const state = useModalState()
	const provideUrl = useUrlProvider()
	const { pushLabelClickedEvent } = useGoogleAnalytics()
	const sitecoreStore = useSitecoreState((state) => state)
	const searchCruisesLabel = sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-UPDATE_NEW', 'Search Cruises')
	const closeLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_FAVOURITES-CLOSE_FAVOUR', 'Close')
	const totalPointsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_TOTAL_POINTS', 'Total points')
	const searchCruiseUrl = provideUrl('search-cruise', {
		noSearch: 'true',
		duration1: 'any',
		ship: 'any',
		itinerary1: 'any',
		destination2: 'any',
		destination1: 'any',
		specialProduct: 'false',
		mscClubNumber: cardnr,
		mscClubCardType: cardType,
	})

	const viewPrivilegesLink = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOME-MSC_VIEW_PRIVILEGES_URL', '#')
	const viewPrivilegesLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOME-MSC_VIEW_PRIVILEGES', 'View Privileges')

	type CardType = { template: StaticImageData; textColor: 'text-msc-blue' | 'text-white' }
	const cardTypes: { [key: string]: CardType } = {
		gold: { template: goldCard, textColor: 'text-msc-blue' },
		bluediamond: { template: blueDiamondCard, textColor: 'text-white' },
		classic: { template: classicCard, textColor: 'text-msc-blue' },
		diamond: { template: diamondCard, textColor: 'text-msc-blue' },
		silver: { template: silverCard, textColor: 'text-white' },
		welcome: { template: welcomeCard, textColor: 'text-msc-blue' },
	}

	const card = cardTypes[cardType.toLowerCase().replace(' ', '')] || cardTypes.welcome

	const closeBehavior = () => {
		enableScrollBehavior()
		state.close()
	}

	const handleVoyagerCardCruiseSearchButtonClick = () => {
		AgentService.createVoyagerCardCookie({ nome, cognome, Data_Nascita, cardType, cardnr })
		pushLabelClickedEvent({ event: 'membersearchcruise', eventName: 'member_search_cruise', label_clicked: 'MSC Voyagers Club Search Cruises' })
	}

	return (
		<div className="w-full flex-col flex items-center">
			<div className="relative w-fit">
				<Image src={card.template} alt="Voyager Card" />
				<div className="absolute w-fit top-0 right-0 bottom-0 flex flex-col justify-center p-5 md:p-7 mb-2">
					<p className={`text-xs md:text-sm font-bold ${card.textColor}`}>{`${nome} ${cognome}`}</p>
					<p className={`text-xs md:text-sm ${card.textColor}`}>{Data_Nascita}</p>
					<p className={`text-xxs md:text-xs ${card.textColor}`}>
						n° <strong>{cardnr}</strong>
					</p>
				</div>
				<div className="absolute w-fit left-0 -bottom-2 flex flex-col justify-center p-5 md:p-7 mb-2 md:mb-1">
					<p className={`text-xxs ml-2 md:ml-4 md:text-xs ${card.textColor}`}>
						<strong>{punti}</strong> {totalPointsLabel}{' '}
						<Link prefetch={false} href={viewPrivilegesLink} target="_blank" className={`${card.textColor} font-bold underline`}>
							{viewPrivilegesLabel}
						</Link>
					</p>
				</div>
			</div>
			<div className="w-full flex flex-col md:flex-row gap-4 mt-16">
				<Button className="flex-1" variant="outlined" onClick={closeBehavior}>
					{closeLabel}
				</Button>
				<Button className="flex-1" onClick={() => handleVoyagerCardCruiseSearchButtonClick()} link={searchCruiseUrl}>
					{searchCruisesLabel}
				</Button>
			</div>
		</div>
	)
}
