'use client'

import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { MouseEvent, useRef, useState } from 'react'
import { IoArrowDownOutline } from 'react-icons/io5'
import { useIsClient } from 'usehooks-ts'
import { ActionLink, InfoIcon } from '../uiComponents'

export interface EmergencyMessageProps {
	title: string
	description: string
	link: string
}

const EmergencyMessage = ({ title, description, link }: EmergencyMessageProps) => {
	const accordionRef = useRef<HTMLDivElement>(null)
	const { pushLabelClickedEvent } = useGoogleAnalytics()
	const [isAccordionOpen, setIsAccordionOpen] = useState(false)
	const [accordionHeight, setAccordionHeight] = useState('0px')

	const toggleAccordionOpen = (e: MouseEvent) => {
		e.preventDefault()
		const updatedAccordionHeight = accordionRef.current?.clientHeight

		if (!isAccordionOpen) {
			setAccordionHeight(`${updatedAccordionHeight}px`)
			setIsAccordionOpen(true)
		} else {
			setAccordionHeight(`0px`)
			setIsAccordionOpen(false)
		}
	}

	const sitecoreStore = useSitecoreState((state) => state)
	const readMoreLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-EMERGENCY-MESSAGE-SHOW', 'Read more')
	const emergencyMessageMainTextLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-EMERGENCY-MESSAGE-TEXT', 'Emergency message text')

	const isClient = useIsClient()
	if (!isClient) return null

	return (
		<div className={`relative bg-secondary-50`}>
			<div className="flex gap-4 py-4 px-6 border-l-6 border-l-secondary">
				<div className="flex items-center justify-center w-9 h-9 rounded-full bg-secondary">
					<InfoIcon />
				</div>
				<div className="flex flex-col gap-3 lg:flex-row flex-1">
					<div className="flex flex-col gap-1">
						<h2 className="text-sm text-dark font-semibold">{title}</h2>
						<p className="text-xs text-light-purple">{description}</p>
					</div>
					<ActionLink
						link={link}
						onClick={(e) => {
							toggleAccordionOpen(e)
							pushLabelClickedEvent({ event: 'notificationclick', eventName: 'notification_click', label_clicked: title.substring(0, 40) })
						}}
						icon={<IoArrowDownOutline size={16} />}
						customStyles={{ transform: `rotate(${!isAccordionOpen ? '0deg' : '180deg'})` }}
						customClasses="lg:ml-auto"
					>
						{readMoreLabel}
					</ActionLink>
				</div>
			</div>
			<div className="absolute w-full z-50 overflow-hidden transition-all" style={{ height: accordionHeight }}>
				<div ref={accordionRef} className="bg-secondary w-full py-4 px-6">
					<p dangerouslySetInnerHTML={{ __html: emergencyMessageMainTextLabel }}></p>
				</div>
			</div>
		</div>
	)
}

export { EmergencyMessage }
