'use client'

import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useModalState } from '@/utils/hooks/useModalState'
import { ReactNode } from 'react'
import { Button } from '../uiComponents'

export interface InfoModalTemplateProps {
	variant: 'confirm-abort' | 'ok' | 'close'
	children: ReactNode
	confirmBehavior?: () => void
}

export const InfoModalTemplate = (props: InfoModalTemplateProps) => {
	const modal = useModalState()
	const sitecoreStore = useSitecoreState((state) => state)

	const confirmButton = (
		<Button className="w-full" onClick={props.confirmBehavior || modal.close}>
			{sitecoreStore.getLabel(TranslationBundles.CHECKOUT, 'MSC-CHECKOUT-CONFIRM', 'Confirm')}
		</Button>
	)

	const cancelButton = (
		<Button className="w-full" variant="outlined" onClick={() => modal.close()}>
			{sitecoreStore.getLabel(TranslationBundles.CHECKOUT, 'MSC-CHECKOUT-CANCEL', 'Cancel')}
		</Button>
	)

	const closeButton = (
		<Button className="w-full" variant="filled" onClick={() => modal.close()}>
			{sitecoreStore.getLabel(TranslationBundles.CHECKOUT, 'MSC-CHECKOUT-CANCEL', 'Cancel')}
		</Button>
	)

	const okButton = (
		<Button className="w-full" variant="filled" onClick={props.confirmBehavior || modal.close}>
			{sitecoreStore.getLabel(TranslationBundles.CHECKOUT, 'MSC-EXTERNAL-WELCOMEPAGE-COOKIE-POLICY-OK', 'Ok')}
		</Button>
	)

	let buttons: ReactNode[] = []
	if (props.variant === 'confirm-abort') buttons = [cancelButton, confirmButton]
	if (props.variant === 'ok') buttons = [okButton]
	if (props.variant === 'close') buttons = [closeButton]

	return (
		<div className="flex flex-col w-full gap-6">
			<p>{props.children}</p>
			<div className="flex gap-6">
				{buttons.map((button, index) => (
					<div key={index} className="w-full">
						{button}
					</div>
				))}
			</div>
		</div>
	)
}
