'use client'

import { Button } from '@/components/uiComponents'
import { createElasticFilterForFlyerBuilder } from '@/features/caching/config/tanstack'
import { Destination, getDestinationsFromSitecore } from '@/features/flyer/lib/utils'
import { Steps } from '@/features/flyer/store/flyer-builder-state'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import { useQueryClient } from '@tanstack/react-query'
import React, { Fragment, useEffect, useState } from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { ContentEditableEvent } from 'react-simple-wysiwyg'
import { OrangeCheckbox, RichedTextArea, SwiperWrapperBYF } from '../_exporter'

const DestinationStep = () => {
	const queryClient = useQueryClient()
	const modalState = useModalState()
	const sitecoreStore = useSitecoreState((state) => state)

	const { pushLabelClickedEvent: labelClicked } = useGoogleAnalytics()
	useEffect(() => labelClicked({ event: 'stepView', eventName: 'step_view', label_clicked: 'Choose destination' }), [])

	// hide/show text editor
	const hideTitleEditorActivation = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-HIDE-HEADLINE-EDITOR', '')
	const hideTitleTexteditor = hideTitleEditorActivation === 'true' ? true : false
	const hideCopyEditorActivation = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-HIDE-COPY-EDITOR', '')
	const hideCopyTexteditor = hideCopyEditorActivation === 'true' ? true : false

	const visibleTermsAndConditionsLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-VISIBLE-TERMS-AND-CONDITIONS', 'I agree not to misuse or sell MSC Cruises trademarks, logos, or assets.')
	const copyLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-COPY', 'Copy')
	const headlineLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-HEADLINE', 'Edit Headline')
	const selectAnImageLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-SELECT-AN-IMAGE', 'Select an image')
	const chooseADestinationLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-CHOOSE-A-DESTINATION', 'Choose a destination')
	const textCountLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADDITIONAL-TEXT', 'Text count')
	const headlineMaxLength = sitecoreStore.getNumber(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-HEADLINE-MAX-LENGTH', 80)
	const copyMaxLength = sitecoreStore.getNumber(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-COPY-MAX-LENGTH', 180)
	const progressLostLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROGRESS-LOST', 'If you make changes at this stage, all progress in the following steps will be lost. Are you sure you want to continue?')
	const destinationBundle = sitecoreStore.getBundle(TranslationBundles.BUILD_YOUR_FLYER_DESTINATIONS)

	const flyerBuilderState = useFlyerBuilderState((state) => ({
		updateStepData: state.updateStepData,
		destinationStep: state.steps.destination,
		areStepsAlreadyFilledAfter: state.areStepsAlreadyFilledAfter,
		eraseStepsAfter: state.eraseStepsAfter,
	}))

	const [isImageSelectionVisible, setIsImageSelectionVisible] = useState<boolean>(true)
	const destinations = getDestinationsFromSitecore(destinationBundle)

	const handleChangesEraseLaterSteps = async (step: keyof Steps, onConfirm: () => void) => {
		if (!flyerBuilderState.areStepsAlreadyFilledAfter(step)) {
			return await onConfirm()
		}

		modalState.openInfoModal(
			{
				variant: 'confirm-abort',
				children: progressLostLabel,
				confirmBehavior: async () => {
					modalState.close()
					flyerBuilderState.eraseStepsAfter(step)
					await onConfirm()
				},
			},
			{
				title: {
					bundle: TranslationBundles.BUILD_YOUR_FLYER,
					keyName: 'MSC-BUILD_YOUR_FLYER-RESET_STEPS_TITLE',
					defaultValue: 'Warning: Changes will reset later steps',
				},
			}
		)
	}

	const handleDestinationClick = async (destination: Destination) =>
		handleChangesEraseLaterSteps('destination', async () => {
			await selectDestination(destination)
		})

	const selectDestination = async (destination: Destination) => {
		const updatedState = flyerBuilderState.updateStepData('destination', { destination })
		const res = await queryClient.fetchQuery(createElasticFilterForFlyerBuilder(updatedState))
		flyerBuilderState.updateStepData('destination', { avaiableShips: res.shipOptions, imageSrc: undefined })
	}

	const handleHeadlineChange = (event: ContentEditableEvent) => {
		const currentValue = event.target.value
		const actualLength = currentValue.replace(/<\/?[^>]+(>|$)/g, '').length
		if (actualLength > headlineMaxLength) return
		const upperCaseValue = currentValue.toUpperCase()
		flyerBuilderState.updateStepData('destination', { headline: upperCaseValue })
	}

	const handleCopyChange = (event: ContentEditableEvent) => {
		const currentValue = event.target.value
		const actualLength = currentValue.replace(/<\/?[^>]+(>|$)/g, '').length
		if (actualLength > copyMaxLength) return
		flyerBuilderState.updateStepData('destination', { copy: event.target.value })
	}

	const handlePolicyAcceptCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		flyerBuilderState.updateStepData('destination', { isPolicyAccepted: e.target.checked })
	}

	const handleImageClick = (imageSrc: string) => {
		flyerBuilderState.updateStepData('destination', { imageSrc })
	}

	return (
		<div className="flex flex-col gap-4 w-[372px]">
			<h2 className="text-sm font-bold uppercase">{chooseADestinationLabel}</h2>
			<div className={`grid grid-cols-2 gap-3 pb-6 ${!flyerBuilderState.destinationStep.destination ? 'border-b-[1px] border-[#BDBDBD]' : ''}`}>
				{destinations?.map((destination, index) => (
					<Button key={index} onClick={() => handleDestinationClick(destination)} variant={flyerBuilderState.destinationStep.destination?.label === destination.label ? 'filledSecondary' : 'outlinedTertiary'}>
						<span className="text-sm leading-5">{destination.label}</span>
					</Button>
				))}
			</div>
			<div className={!flyerBuilderState.destinationStep.destination ? 'hidden' : 'flex flex-col gap-4'}>
				<div className="flex items-center justify-between">
					<h2 className="text-sm font-bold uppercase">{selectAnImageLabel}</h2>
					<MdOutlineKeyboardArrowRight
						onClick={() => setIsImageSelectionVisible((value) => !value)}
						className="cursor-pointer"
						size={22}
						style={{
							transform: isImageSelectionVisible ? 'rotate(90deg)' : 'rotate(270deg)',
							transition: 'transform 0.3s ease',
						}}
					/>
				</div>
				<div className={isImageSelectionVisible ? 'flex gap-2' : 'hidden'}>
					<SwiperWrapperBYF images={flyerBuilderState.destinationStep.destination?.images || []} selectedImage={flyerBuilderState.destinationStep?.imageSrc} onImageSelected={handleImageClick} />
				</div>
				<div className="flex">
					<OrangeCheckbox isChecked={flyerBuilderState.destinationStep.isPolicyAccepted} handleIsChecked={handlePolicyAcceptCheckbox} textNextToCheckbox={visibleTermsAndConditionsLabel} />
				</div>

				{!hideTitleTexteditor && (
					<Fragment>
						<hr /> <RichedTextArea label={headlineLabel} characterCounterText={textCountLabel} maxLength={headlineMaxLength} value={flyerBuilderState.destinationStep.headline ?? flyerBuilderState.destinationStep.destination?.headline ?? ''} onChange={handleHeadlineChange} onDeleteButtonClicked={() => flyerBuilderState.updateStepData('destination', { headline: undefined })} />
					</Fragment>
				)}
				{!hideCopyTexteditor && (
					<Fragment>
						<hr /> <RichedTextArea label={copyLabel} characterCounterText={textCountLabel} maxLength={copyMaxLength} value={flyerBuilderState.destinationStep.copy ?? flyerBuilderState.destinationStep.destination?.copy ?? ''} onChange={handleCopyChange} onDeleteButtonClicked={() => flyerBuilderState.updateStepData('destination', { copy: undefined })} />
					</Fragment>
				)}
			</div>
		</div>
	)
}

export { DestinationStep }
