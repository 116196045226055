'use client'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { Promotion } from '@/features/sitecore/actions/promotions'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { calculateTimeLeft } from '@/utils/analytics/googleAnalyticsHelper'
import React, { ReactElement, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperInstance } from 'swiper/types'
import { CustomVideo, VisualPaginationTab, VisualPaginationWrapper } from '../uiComponents'

interface SlidesToShowProps {
	base?: number | 'auto'
	sm?: number | 'auto'
	md?: number | 'auto'
	lg: number | 'auto'
}

interface SwiperWrapperProps {
	type?: 'autoplay'
	children: ReactElement[]
	slidesToShow: SlidesToShowProps
	overflow?: 'visible' | 'hidden'
	pt?: string
	promotions?: Promotion[]
}

const SwiperWrapper = ({ type, children, slidesToShow, overflow = 'hidden', pt, promotions }: SwiperWrapperProps) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [isPaused, setIsPaused] = useState<boolean[]>(Array(children.length).fill(false))
	const autoplayPagination = useRef<HTMLDivElement>(null)
	const swiperRef = useRef<SwiperInstance | null>(null)
	const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
	const user = useLoggedUser()

	const sitecoreStore = useSitecoreState((state) => state)
	const durationMillis = useMemo(() => sitecoreStore.getNumber(TranslationBundles.HOME, 'MSC-WELCOME-HERO-DURATION-SECONDS', 10) * 1000, [sitecoreStore])

	useEffect(() => {
		if (swiperRef.current) {
			swiperRef.current.on('slideChange', (swiper) => {
				handleSlideChange(swiper)
			})
		}
	}, [])

	const handleSlideChange = useCallback((swiper: SwiperInstance) => {
		setActiveIndex(swiper.activeIndex)

		// Pause all videos and play only the active one
		videoRefs.current.forEach((video, index) => {
			if (video) {
				if (index === swiper.activeIndex) {
					video.play().catch((e) => {
						console.warn('Unable to play the video:', e)
					})
					setIsPaused((prev) => {
						const newIsPaused = [...prev]
						newIsPaused[index] = false
						return newIsPaused
					})
				} else {
					video.pause()
					video.currentTime = 0 // Reset video to the beginning
					setIsPaused((prev) => {
						const newIsPaused = [...prev]
						newIsPaused[index] = true
						return newIsPaused
					})
				}
			}
		})
	}, [])

	const handlePlayPause = useCallback((index: number) => {
		if (videoRefs.current[index]?.paused) {
			videoRefs.current[index]?.play?.()
			setIsPaused((prev) => {
				const newIsPaused = [...prev]
				newIsPaused[index] = false
				return newIsPaused
			})
		} else {
			videoRefs.current[index]?.pause?.()
			setIsPaused((prev) => {
				const newIsPaused = [...prev]
				newIsPaused[index] = true
				return newIsPaused
			})
		}
	}, [])

	// Handle the autoplay progress
	const onAutoplayTimeLeft = (swiper: SwiperInstance, _time: number, progress: number) => {
		const activeProgress = 100 - progress * 100
		if (autoplayPagination.current) {
			const tabElement = autoplayPagination.current.querySelector(`.tab-${swiper.activeIndex}`) as HTMLElement
			if (tabElement) {
				const progressElement = tabElement.querySelector('.progress') as HTMLElement
				if (progressElement) {
					progressElement.style.width = `${activeProgress}%`
				}
			}
		}
	}

	const handleMouseEnter = () => {
		if (swiperRef.current && !swiperRef.current.autoplay.paused) {
			swiperRef.current.autoplay.pause()
		}
	}

	const handleMouseLeave = () => {
		if (swiperRef.current && swiperRef.current.autoplay.paused) {
			swiperRef.current.autoplay.resume()
		}
	}

	const slideToClickedSlide = (index: number) => {
		if (swiperRef.current) {
			swiperRef.current.slideTo(index)
		}
	}

	function isReactElement(element: any): element is React.ReactElement {
		return React.isValidElement(element)
	}

	const sendClickPromotionsGA4Event = () => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({ ecommerce: null })
		const activeIndex = swiperRef.current?.activeIndex
		window.dataLayer.push({
			event: 'select_promotion',
			eventName: 'select_promotion',
			user_id: user?.agency || '',
			network_id_u: user?.consortiumCode || '',
			agency_code_u: user?.agency || '',
			ecommerce: {
				items: [
					{
						promotion_id: promotions?.[activeIndex || 0].bundleName?.split('_').pop(),
						promotion_name: promotions?.[activeIndex || 0].title,
						creative_name: promotions?.[activeIndex || 0].title,
						creative_slot: (activeIndex ?? 0) + 1,
						location_id: 'Head',
						time_left: calculateTimeLeft(promotions?.[activeIndex || 0].expireDate),
						section: user ? 'Private' : 'Public',
					},
				],
			},
		})
	}

	const createVideoTsx = (slide: React.ReactElement<any, string | React.JSXElementConstructor<any>>, index: number) => ({
		children: React.Children.map((slide as React.ReactElement<any>).props.children, (child) => {
			if (React.isValidElement(child) && child.type === Suspense) {
				return React.cloneElement(child as React.ReactElement<any>, {
					children: React.Children.map((child as React.ReactElement<any>).props.children, (suspenseChild) => {
						if (React.isValidElement(suspenseChild) && suspenseChild.type === CustomVideo) {
							return React.cloneElement(suspenseChild as React.ReactElement, {
								ref: (el: HTMLVideoElement | null) => {
									if (el) {
										videoRefs.current[index] = el
									}
								},
								muted: true,
								preload: 'auto',
							})
						}
						return suspenseChild
					}),
				})
			}
			if (React.isValidElement(child) && child.type === CustomVideo) {
				return React.cloneElement(child as React.ReactElement, {
					ref: (el: HTMLVideoElement | null) => {
						if (el) {
							videoRefs.current[index] = el
						}
					},
					muted: true,
					preload: 'auto',
				})
			}
			return child
		}),
	})

	return (
		<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => sendClickPromotionsGA4Event()}>
			<Swiper
				style={{ overflow: overflow, paddingTop: pt }}
				className="w-full"
				spaceBetween={type === 'autoplay' ? 0 : 24}
				slidesPerView={slidesToShow.lg || slidesToShow.md || slidesToShow.sm || slidesToShow.base}
				modules={[EffectFade, Autoplay]}
				onAutoplayTimeLeft={type === 'autoplay' ? onAutoplayTimeLeft : undefined}
				autoplay={type === 'autoplay' && durationMillis > 0 ? { delay: durationMillis } : false}
				onSwiper={(swiper) => (swiperRef.current = swiper)}
				breakpoints={{
					1: {
						slidesPerView: slidesToShow.base ?? 1,
					},
					519: {
						slidesPerView: slidesToShow.sm ?? slidesToShow.base ?? 2,
					},
					768: {
						slidesPerView: slidesToShow.md ?? slidesToShow.sm ?? 2,
					},
					1024: {
						slidesPerView: slidesToShow.lg,
					},
				}}
			>
				{children.map((slide: React.ReactElement<any>, index: number) => {
					const isVideoSlide = React.Children.toArray((slide as React.ReactElement<any>).props.children).some((child: React.ReactNode) => {
						if (React.isValidElement(child) && child.type === React.Suspense) {
							return React.Children.toArray((child as React.ReactElement<any>).props.children).some((suspenseChild) => React.isValidElement(suspenseChild) && suspenseChild.type === CustomVideo)
						}
						return React.isValidElement(child) && child.type === CustomVideo
					})

					return (
						<SwiperSlide key={index} style={{ height: 'auto' }}>
							{isVideoSlide ? React.cloneElement(slide, createVideoTsx(slide, index)) : slide}
						</SwiperSlide>
					)
				})}

				{type === 'autoplay' && (
					<VisualPaginationWrapper ref={autoplayPagination}>
						{React.Children.map(children, (child, index) => {
							if (!isReactElement(child)) {
								return null
							}

							const isVideo = React.Children.toArray((child as React.ReactElement<any>).props.children).some((child: React.ReactNode) => {
								if (React.isValidElement(child) && child.type === React.Suspense) {
									return React.Children.toArray((child as React.ReactElement<any>).props.children).some((suspenseChild) => React.isValidElement(suspenseChild) && suspenseChild.type === CustomVideo)
								}
								return React.isValidElement(child) && child.type === CustomVideo
							})

							return (
								<VisualPaginationTab
									key={index}
									variant={index === activeIndex ? 'active' : undefined}
									tabTitle={promotions && promotions[index]?.title}
									hasVideo={isVideo}
									isPaused={isPaused[index]}
									playVideo={() => handlePlayPause(index)}
									pauseVideo={() => handlePlayPause(index)}
									shouldShowProgrssBar={children.length > 1 && durationMillis >= 0}
									onClick={() => {
										slideToClickedSlide(index)
									}}
								/>
							)
						})}
					</VisualPaginationWrapper>
				)}
			</Swiper>
		</div>
	)
}

export { SwiperWrapper }
