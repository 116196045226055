'use client'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import { useModalState } from '@/utils/hooks/useModalState'
import { FaUser } from 'react-icons/fa6'
import { ChangeLanguage } from '../atoms/ChangeLanguage'
import { CountryFlag } from '../atoms/CountryFlag'
import { Button, CountrySelectionLink } from '../uiComponents'

const img = <FaUser color="white" size={18} />

interface MobileNavMenuButtonsProps {
	countryFlag: React.ReactElement<typeof CountryFlag>
}

const MobileNavMenuButtons = (props: MobileNavMenuButtonsProps) => {
	const user = useLoggedUser()
	const { pushLabelClickedEvent } = useGoogleAnalytics()
	const sitecoreStore = useSitecoreState((state) => state)

	const registerYourAgencyLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-REGISTER-BUTTON-LABEL', 'Register your agency')
	const loginLabel = sitecoreStore.getLabel(TranslationBundles.SIGNIN, 'MSC-LOGIN-MOBILE-LOGIN-BUTTON-LABEL', 'Login')

	return (
		<div className="flex flex-col gap-6 px-6 pb-11">
			<ChangeLanguage className="flex lg:hidden" />
			{!user && (
				<>
					<CountrySelectionLink onClick={() => pushLabelClickedEvent({ event: 'menuClick', eventName: 'menu_click', label_clicked: 'Flag' })}>{props.countryFlag}</CountrySelectionLink>
					<Button
						icon={img}
						variant="filled"
						bgColor="primary"
						className="flex justify-center items-center gap-2 w-full"
						onClick={() => {
							useModalState.getState().openLoginModal()
							pushLabelClickedEvent({ event: 'menuClick', eventName: 'menu_click', label_clicked: 'Login access' })
						}}
					>
						{loginLabel}
					</Button>
					<Button
						isIconRight={false}
						variant="outlinedSecondary"
						className="text-white border-2 border-[#EE732F] px-3 py-2 flex justify-center items-center w-full"
						onClick={() => {
							useModalState.getState().openRegisterYourAgencyModal()
							pushLabelClickedEvent({ event: 'menuClick', eventName: 'menu_click', label_clicked: 'Registration' })
						}}
					>
						{registerYourAgencyLabel}
					</Button>
				</>
			)}
		</div>
	)
}

export { MobileNavMenuButtons }
