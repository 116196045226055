'use client'
import React from 'react'
import { Button } from '../uiComponents'
import { useModalState } from '@/utils/hooks/useModalState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'

const BuildYourFlyerInstructionTemplate = () => {
	const modal = useModalState()

	const sitecoreStore = useSitecoreState((state) => state)
	const closeLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-CLOSE', 'Close')
	const byfInstructionLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-INSTRUCTIONS', 'This flyer builder is designed to convey specific commercial information about MSC offers. It allows you to select the itinerary, the visual, the ship, the map, and the timing of the departure you want to promote. You can customize the description of your offer, including packages and promotions. Additionally, you can include your logo and contact details.')

	return (
		<div className="flex flex-col gap-9">
			<p>{byfInstructionLabel}</p>
			<Button className="w-full" variant="filled" onClick={() => modal.close()}>
				{closeLabel}
			</Button>
		</div>
	)
}

export { BuildYourFlyerInstructionTemplate }
