'use client'

import { BundleNameViewActivator } from '@/features/dev-tools/components/BundleNameViewActivator'
import { SitecoreKeysOverrideActivator } from '@/features/dev-tools/components/SitecoreKeysOverrideActivator'
import { StateSelectActivator } from '@/features/dev-tools/components/StateLoggerActivator'

export interface DevToolsModalTemplateProps {
	buildVersion?: string
}

export const DevToolsModalTemplate = (props: DevToolsModalTemplateProps) => {
	const valid = props.buildVersion && props.buildVersion !== 'Not found'
	const commitHash = valid ? props.buildVersion?.split('-')[0] : undefined
	const pipelineNumber = valid ? props.buildVersion?.split('-')[1] : undefined

	return (
		<div className="flex flex-col w-full justify-between h-[300px]">
			<div className="flex flex-col gap-3">
				<SitecoreKeysOverrideActivator />
				<div style={{ marginLeft: '-1rem' }}>
					<BundleNameViewActivator />
				</div>
				<StateSelectActivator />
			</div>
			{commitHash && (
				<div>
					<div className="text-sm">Commit: {commitHash}</div>
					<div className="text-sm">Pipeline: {pipelineNumber}</div>
				</div>
			)}
		</div>
	)
}
