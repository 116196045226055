'use client'

import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useEffect } from 'react'
import { Button } from '../../uiComponents'

export const FlyerStep = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	const { pushLabelClickedEvent, pushEvent } = useGoogleAnalytics()
	const flyerBuilderState = useFlyerBuilderState((state) => ({
		destinationLabel: state.steps.destination.destination?.label,
		dateRange: state.steps.itinerary.itineraries[0].dateRange,
		applyOfferLabel: state.steps.priceList.priceList?.name,
	}))

	const hidePlaceholderImg = (hide: string) => {
		const placeholderImages = document.querySelectorAll('img[src="https://archive.org/download/placeholder-image/placeholder-image.jpg"]') as NodeListOf<HTMLElement>
		if (placeholderImages) {
			placeholderImages.forEach((placeholderImage) => {
				placeholderImage.style.visibility = hide === 'true' ? 'hidden' : 'visible'
			})
		}
	}

	const addLinkAnnotations = (pdf: jsPDF, flyerPage: HTMLElement, scaleFactor: number) => {
		const containerRect = flyerPage.getBoundingClientRect()
		const anchors = flyerPage.querySelectorAll('a')
		anchors.forEach((anchor) => {
			const href = anchor.getAttribute('href')
			if (href) {
				const rect = anchor.getBoundingClientRect()
				const x = (rect.left - containerRect.left) * scaleFactor
				const y = (rect.top - containerRect.top) * scaleFactor
				const width = rect.width * scaleFactor
				const height = rect.height * scaleFactor
				pdf.link(x, y, width, height, { url: href })
			}
		})
	}

	const handleDownload = async () => {
		const firstFlyerPage = document.getElementById('first-flyer-page')
		const secondFlyerPage = document.getElementById('second-flyer-page')
		const thirdFlyerPage = document.getElementById('third-flyer-page')
		if (!firstFlyerPage || !secondFlyerPage || !thirdFlyerPage) return
		const firstInitialScale = firstFlyerPage.style.scale
		const secondInitialScale = secondFlyerPage.style.scale
		const thirdInitialScale = thirdFlyerPage.style.scale

		firstFlyerPage.style.scale = '1'
		secondFlyerPage.style.scale = '1'
		thirdFlyerPage.style.scale = '1'
		hidePlaceholderImg('true')

		const convertImagesToBase64 = (element: HTMLElement) => {
			const images = element.querySelectorAll('img')
			const promises = Array.from(images).map((img) => {
				if (img.src === 'https://archive.org/download/placeholder-image/placeholder-image.jpg') {
					return Promise.resolve()
				} //skip placeholder img
				return new Promise<void>((resolve, reject) => {
					const canvas = document.createElement('canvas')
					canvas.width = img.width
					canvas.height = img.height
					const ctx = canvas.getContext('2d')
					const newImg = new Image()
					newImg.src = `/api/image-proxy?imageUrl=${encodeURIComponent(img.src)}`
					newImg.width = img.width
					newImg.height = img.height

					newImg.onload = () => {
						try {
							ctx?.drawImage(newImg, 0, 0, newImg.width, newImg.height)
							img.src = canvas.toDataURL('image/png')
							resolve()
						} catch (error) {
							console.error(`Error processing image: ${img.src}`, error)
							reject(error)
						}
					}

					newImg.onerror = (err) => {
						console.error(`Error loading image: ${newImg.src}`, err)
						reject(err)
					}
				}).catch((error) => {
					console.warn(`Error handling image promise for ${img.src}:`, error)
					// Resolve with a fallback or simply move on
					return undefined
				})
			})

			return Promise.allSettled(promises).then((results) => {
				results.forEach((result, index) => {
					if (result.status === 'rejected') {
						console.warn(`Image conversion failed for image at index ${index}:`, result.reason)
					}
				})
			})
		}

		await convertImagesToBase64(firstFlyerPage)
		await convertImagesToBase64(secondFlyerPage)
		await convertImagesToBase64(thirdFlyerPage)

		const secondFlyerPageSectionsLength = secondFlyerPage?.querySelectorAll('section').length
		const thirdFlyerPageSectionsLength = thirdFlyerPage?.querySelectorAll('section').length

		const pdf = new jsPDF()

		const captureCanvas = async (element: HTMLElement) => {
			return html2canvas(element, {
				scale: 4,
				allowTaint: true,
				useCORS: true,
				logging: true,
				onclone: (clonedDoc) => {
					clonedDoc.querySelectorAll('p').forEach((paragraph) => {
						if (paragraph.getAttribute('id') !== 'choose-destination') {
							paragraph.style.transform = 'translateY(-8px)'
						}
					})
					clonedDoc.querySelectorAll('.toLiftText').forEach((text) => {
						const HTMLElement = text as HTMLElement
						HTMLElement.style.transform = 'translateY(-8px)'
					})
				},
			})
		}

		const firstCanvas = await captureCanvas(firstFlyerPage)
		const secondCanvas = secondFlyerPageSectionsLength > 0 ? await captureCanvas(secondFlyerPage) : undefined
		const thirdCanvas = thirdFlyerPageSectionsLength > 0 ? await captureCanvas(thirdFlyerPage) : undefined

		// Debugging: Append canvas to document to inspect
		// document.body.appendChild(firstCanvas)
		// if (secondCanvas) document.body.appendChild(secondCanvas)
		// if (thirdCanvas) document.body.appendChild(thirdCanvas)

		const firstImgData = firstCanvas.toDataURL('image/png')
		const secondImgData = secondCanvas ? secondCanvas.toDataURL('image/png') : undefined
		const thirdImgData = thirdCanvas ? thirdCanvas.toDataURL('image/png') : undefined

		const imgProps = pdf.getImageProperties(firstImgData)
		const pdfWidth = pdf.internal.pageSize.getWidth()
		const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

		// This scale factor is used to convert DOM measurements to PDF coordinates.
		const scaleFactor = pdfWidth / firstFlyerPage.offsetWidth

		let position = 0

		pdf.addImage(firstImgData, 'PNG', 0, position, pdfWidth, pdfHeight)
		addLinkAnnotations(pdf, firstFlyerPage, scaleFactor)

		if (secondImgData || thirdImgData) {
			pdf.addPage()
		}

		firstFlyerPage.style.scale = firstInitialScale

		if (secondImgData) {
			position = 0
			pdf.addImage(secondImgData, 'PNG', 0, position, pdfWidth, pdfHeight)
			addLinkAnnotations(pdf, secondFlyerPage, scaleFactor)
			if (thirdImgData) {
				pdf.addPage()
			}

			secondFlyerPage.style.scale = secondInitialScale
		}

		if (thirdImgData) {
			position = 0
			pdf.addImage(thirdImgData, 'PNG', 0, position, pdfWidth, pdfHeight)
			addLinkAnnotations(pdf, thirdFlyerPage, scaleFactor)

			thirdFlyerPage.style.scale = thirdInitialScale
		}

		pdf.save('Title_of_the_flyer.pdf')
		flyerDownloadGA4Event()
		hidePlaceholderImg('false')
	}

	const downloadFlyerLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-DOWNLOAD-FLYER', 'Download flyer')

	const flyerDownloadGA4Event = () => {
		const destinationLabel = flyerBuilderState.destinationLabel || ''
		const departureDate = flyerBuilderState.dateRange || ''
		const applyOfferLabel = flyerBuilderState.applyOfferLabel || ''
		const labelClicked = `${destinationLabel} | ${departureDate} | ${applyOfferLabel}`
		pushEvent({ event: 'flyerDownload', eventName: 'flyer_downloaded', label_clicked: labelClicked, section_byf: 'BYF Download' })
	}

	useEffect(() => pushLabelClickedEvent({ event: 'stepView', eventName: 'step_view', label_clicked: 'Download flyer' }), [])
	return (
		<div>
			<h2 className="text-sm font-bold uppercase w-[372px]">{downloadFlyerLabel}</h2>
			<p className="mt-8">Title_of_the_flyer.pdf</p>
			<Button className="w-full mt-8" onClick={handleDownload}>
				{downloadFlyerLabel}
			</Button>
		</div>
	)
}
