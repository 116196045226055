import { IOption } from '@/features/elastic/store/types'
import { Destination, getDestinationsFromSitecore } from '@/features/flyer/lib/utils'
import { FlyerBuilderStateProvider } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { flyerBuilderInitialState } from '@/features/flyer/store/flyer-builder-state'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import SitecoreService from '@/features/sitecore/sitecore-service'
import { PreviewSyncronizer } from '../molecules/flyerBuilderUI/PreviewSyncronizer'
import { FlyerBuilderActions, FlyerBuilderPreview, FlyerBuilderStepper, FlyerBuilderStepperControls } from '../uiComponents'

const mapDestinationsToOptions = (destinations: Destination[], type: 'area' | 'region'): IOption[] =>
	destinations
		.filter((destination) => destination[type])
		.map((destination) => ({
			value: destination[type]!,
			label: destination.label,
			checked: false,
			disabled: false,
		}))

export const FlyerBuilderWrapper = async () => {
	const destinationBundle = await SitecoreService.getBundle(TranslationBundles.BUILD_YOUR_FLYER_DESTINATIONS)
	const destinations = getDestinationsFromSitecore(destinationBundle)
	const flyerBuilderInitialOptions: IOption[] = []

	flyerBuilderInitialOptions.push(...mapDestinationsToOptions(destinations, 'area'))
	flyerBuilderInitialOptions.push(...mapDestinationsToOptions(destinations, 'region'))

	flyerBuilderInitialState.steps.destination.avaiableDestinations = flyerBuilderInitialOptions

	return (
		<FlyerBuilderStateProvider initialState={flyerBuilderInitialState}>
			<PreviewSyncronizer />

			<section className="flex flex-col w-full h-full bg-white">
				<FlyerBuilderStepper />
				<div className="flex h-full max-h-byf-height">
					<div className="flex flex-col flex-3 bg-background gap-6 p-6">
						<FlyerBuilderPreview />
						<FlyerBuilderStepperControls />
					</div>
					<div className="flex-1">
						<FlyerBuilderActions />
					</div>
				</div>
			</section>
		</FlyerBuilderStateProvider>
	)
}
