'use client'

import { useEnvironmentState } from '@/utils/environment/store/useEnvironmentState'
import PlaceholderImage from '@public/images/placeholder-image.jpg'
import Image from 'next/image'
import React, { ComponentProps } from 'react'

export type PictureProps = Omit<ComponentProps<typeof Image>, 'src'> & { image: string; basepath?: 'websphere-base' | 'websphere-images' | 'included' }

/**
 * Load an image using the filename from the directory "public/images"
 * @param props next image props and image: the image name with extension as string (i.e. "alert.svg")
 */
export const DynamicPicture: React.FC<PictureProps> = (props) => {
	const environmentState = useEnvironmentState()
	const [isError, setIsError] = React.useState(false)

	const getImageFullUriOrNull = (): string => {
		if (props.basepath === 'websphere-base') return `${environmentState.getString('WEBSPHERE_BASE_ENDPOINT', '')}/${props.image}`
		if (props.basepath === 'websphere-images') return `${environmentState.getString('WEBSPHERE_IMAGES_ENDPOINT', '')}/${props.image}`
		if (props.basepath === 'included' || isValidUrl(props.image)) return props.image
		return `/images/${props.image}`
	}

	// eslint-disable-next-line prettier/prettier
	// prettier-ignore
	return (
		<Image
			unoptimized
			src={isError ? PlaceholderImage : getImageFullUriOrNull()}
			{...props}
			alt={props.alt}
			onError={() => {
				setIsError(true)
				console.warn(`Image not found: ${getImageFullUriOrNull()}, displaying placeholder image`)
			}}
		/>
	)
}

const isValidUrl = (url: string): boolean => {
	try {
		new URL(url)
		return true
	} catch {
		return false
	}
}
