import { type ClassValue, clsx } from 'clsx'
import { createHash } from 'crypto'
import { twMerge } from 'tailwind-merge'

export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs))
}

const touchMoveHandler = (event: TouchEvent) => {
	const target = event.target as HTMLElement
	const modalBody = document.querySelector('#modalBody') as HTMLElement

	if (modalBody && !modalBody.contains(target) && (target.tagName === 'BODY' || target.tagName === 'HTML')) {
		event.preventDefault()
	}
}

export const disabledScrollBehavior = () => {
	if (typeof window !== 'undefined') {
		document.body.style.overflow = 'hidden'

		// Block touch scroll on Apple devices
		document.addEventListener('touchmove', touchMoveHandler, { passive: false })
	}
}

export const enableScrollBehavior = () => {
	if (typeof window !== 'undefined') {
		document.body.style.overflow = 'visible'

		// Remove touch scroll blocking on Apple devices
		document.removeEventListener('touchmove', touchMoveHandler)
	}
}

// transform the date to the required format of News section
export const transformDate = (date: string, locale: string): string => {
	const parsedDate = new Date(date)
	if (isNaN(parsedDate.getTime())) return 'Invalid Date'
	return new Intl.DateTimeFormat(locale, { day: '2-digit', month: 'short', year: 'numeric' }).format(parsedDate)
}

/**
 * The input will be stringified and hashed using MD5
 */
export const hashMD5 = (selected: any): string => {
	return createHash('md5').update(JSON.stringify(selected)).digest('hex')
}

// destination area code mapped to destination text color
export const regionColors: Record<string, string> = {
	CAR: '#008894', // CARIBBEAN
	SOC: '#008894', // ANTILLES
	LGC: '#004071', // MSC GRAND VOYAGES
	EMW: '#635547', // DUBAI, ABU DHABI, QATAR
	FAE: '#EAAA00', // ASIA
	INW: '#DCB640', // SOUTH AFRICA
	MED: '#CC532C', // MEDDITERRANEAN
	MEW: '#CC532C', // MEDDITERRANEAN
	MEA: '#CC532C', // MEDDITERRANEAN
	NOA: '#991C26', // BERMUDA, CANADA, NEW ENGLAND
	ALA: '#5D7EBD', // ALASKA
	NOR: '#707375', // NORD EUROPA
	RED: '#D66965', // EGYPT, RED SEA, SAUDI ARABIA
	SOA: '#649A3F', // SOUTH AMERICA
	WEE: '#E0A71C', // CANARY ISLANDS, MADEIRA
	WOR: '#000033', // MSC WORLD CRUISE
	POS: '#003e73', // ???
	EAT: '#737478', // ???
	NCA: '#008896', // ???
	// ???: '#b01d2a', CHRISTMAS, NEW YEAR
}
