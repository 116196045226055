'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { BundleNameView } from '@/features/dev-tools/components/BundleNameView'
import { Promotion } from '@/features/sitecore/actions/promotions'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { calculateTimeLeft } from '@/utils/analytics/googleAnalyticsHelper'
import { useGoogleAnalytics } from '@/utils/hooks/useGoogleAnalitics'
import React from 'react'
import { DynamicPicture } from '../atoms/DynamicPicture'
import { Countdown, DiscountIcon, Tag, TextButton } from '../uiComponents'

export interface CardPromotionsProps {
	index: number
	promotion: Promotion
}

const CardPromotions = (props: CardPromotionsProps) => {
	const sitecoreStore = useSitecoreState((state) => state)
	const user = useLoggedUser()
	const { pushLabelClickedEvent } = useGoogleAnalytics()
	const seeDetailsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE_DETAILS_PROMOTIONS', 'See details')

	const sendClickPromotionsGA4Event = () => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({ ecommerce: null })
		window.dataLayer.push({
			event: 'select_promotion',
			eventName: 'select_promotion',
			user_id: user?.agency || '',
			network_id_u: user?.consortiumCode || '',
			agency_code_u: user?.agency || '',
			ecommerce: {
				items: [
					{
						promotion_id: props.promotion.bundleName?.split('_').pop(),
						promotion_name: props.promotion.title,
						creative_name: props.promotion.title,
						creative_slot: props.index + 1,
						location_id: 'Body',
						time_left: calculateTimeLeft(props.promotion.expireDate),
						section: user ? 'Private' : 'Public',
					},
				],
			},
		})
	}

	return (
		<div className="flex flex-col h-full" data-bundle-name={props.promotion.bundleName}>
			<div className="relative min-h-36">
				<DynamicPicture image={props.promotion.smallImage} alt="" className="object-cover object-center" fill />
			</div>
			<div className="flex flex-col bg-neutral-50 p-6 h-full">
				<div className="mb-auto">
					<BundleNameView bundleName={props.promotion.bundleName} />
					<h3 className="text-xl font-bold text-msc-blue">{props.promotion.title}</h3>
					<p className="text-light-purple font-bold mt-2">{props.promotion.subtitle}</p>
				</div>
				<div className={`${!props.promotion.promoValue ? 'mt-6' : 'mt-14'}`}>
					{props.promotion.promoValue && (
						<div className="flex items-center gap-2">
							{props.promotion.promoIcon ? <DynamicPicture image={props.promotion.promoIcon} alt="icon of the promotion discount" width={24} height={24} /> : <DiscountIcon />}
							<p className="text-sm font-semibold text-secondary">{props.promotion.promoValue}</p>
						</div>
					)}
					{((props.promotion.expireDate !== undefined && props.promotion.expireDate !== '') || (props.promotion.labels.length > 0 && props.promotion.labels[0] !== '')) && (
						<div className="flex flex-wrap gap-2 mt-2 min-h-16">
							{props.promotion.expireDate !== undefined && props.promotion.expireDate !== '' && <Countdown date={props.promotion.expireDate} />}
							{props.promotion.labels.length > 0 &&
								props.promotion.labels.map(
									(label, index) =>
										label !== '' && (
											<React.Fragment key={index}>
												<Tag>{label}</Tag>
											</React.Fragment>
										)
								)}
						</div>
					)}
					<div className="mt-3">
						<TextButton
							link={props.promotion.link}
							onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
								e.preventDefault()
								pushLabelClickedEvent({ event: 'seedetailslink', eventName: 'see_details_link', label_clicked: props.promotion.title })
								sendClickPromotionsGA4Event()
								setTimeout(() => {
									window.location.href = props.promotion.link
								}, 300)
							}}
						>
							{seeDetailsLabel}
						</TextButton>
					</div>
				</div>
			</div>
		</div>
	)
}

export { CardPromotions }
