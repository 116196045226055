import { PriceListEntry } from '../model/elastic-search'

export interface CabinPrice {
	price: number
	hsc: number | undefined
}

export interface Options {
	isHSCEnabledForCurrentMarket: boolean // If true, the hsc are retrieved from the pricelist.
	showHscSummedToBasePrice: boolean // If true, the cabin base price is summed to the hsc.
	considerOnlyAvailableCabins: boolean
	pricePer: 'adult' | 'cabin'
}

/**
 * If hsc is enabled for the current market, the price will contains the hsc.
 * If showHscSummedToBasePrice is true then returns {price: hsc included, hsc: undefined}
 * Otherwise returns {price: price without hsc, hsc: hsc}
 */
export function getMinPriceToShow(priceListEntries: PriceListEntry[], options: Options): CabinPrice | undefined {
	const minPrice = findMinPrice(priceListEntries, options)

	if (!minPrice) {
		console.error(`Could not find minimum price. Price list length: ${priceListEntries.length}. Options: ${JSON.stringify(options)}`)
		return undefined
	}

	return {
		price: options.showHscSummedToBasePrice ? minPrice.priceBreakdown.total : minPrice.priceBreakdown.basePrice,
		hsc: options.showHscSummedToBasePrice ? undefined : minPrice.priceBreakdown.hscAmount,
	}
}

interface FindMinPriceResult {
	priceListEntry: PriceListEntry
	priceBreakdown: CabinPriceBreakdown
}

/**
 * Finds the entry in the price list with the minimum price.
 *
 * @param priceList - An array of `PriceListEntry` objects to search through.
 * @param isHSCEnabledForCurrentMarket - A boolean indicating if HSC is enabled for the current market.
 * @param filter - An optional filter function to apply to the price list entries.
 * @returns The `PriceListEntry` with the minimum price or null if no entry is found.
 */
function findMinPrice(priceList: PriceListEntry[], options: Options): FindMinPriceResult | null {
	const candidates = options.considerOnlyAvailableCabins ? priceList.filter((entry) => entry.availability > 0) : priceList
	if (candidates.length === 0) {
		console.error(`No available cabins found in the price list. cruiseId: ${priceList?.[0]?.cruiseId}, priceId: ${priceList?.[0]?.priceId}`)
		return null
	}

	let minPriceEntry = null
	let minPriceBreakdown: CabinPriceBreakdown = { basePrice: Infinity, hscAmount: undefined, total: Infinity }
	candidates.forEach((candidate) => {
		const candidatePrice = extractCabinPriceBreakdown(candidate, options)
		if (candidatePrice && candidatePrice.total < minPriceBreakdown.total) {
			minPriceEntry = candidate
			minPriceBreakdown = candidatePrice
		}
	})

	if (!minPriceEntry) {
		if (options.isHSCEnabledForCurrentMarket) console.error('Could not find minimum price. Possible cause: Hsc enabled for current market but not found in the pricesb2b service response', priceList)
		else console.error(`Could not find minimum price. isHSCEnabledForCurrentMarket: ${options.isHSCEnabledForCurrentMarket}. Pricelist length: ${priceList.length}. Candidates length: ${candidates.length}.`)
	}

	return !minPriceEntry ? null : { priceListEntry: minPriceEntry, priceBreakdown: minPriceBreakdown }
}

interface CabinPriceBreakdown {
	basePrice: number
	hscAmount: number | undefined
	total: number
}

/**
 * Calculates the price with the hotel service charge (HSC) included if they are enabled for the market.
 * @param priceEntry A single price entry.
 * @returns The price with HSC included if enabled for the market, or null if the price should not be considered.
 */
function extractCabinPriceBreakdown(priceEntry: PriceListEntry, options: Options): CabinPriceBreakdown | null {
	const basePrice = priceEntry[options.pricePer === 'cabin' ? 'cabinPrice' : 'adultPrice']
	if (!options.isHSCEnabledForCurrentMarket) return { basePrice, hscAmount: undefined, total: basePrice }

	// Find the minimum service charge entry
	const minHscEntry = priceEntry.serviceCharges.reduce((min, current) => (current.totalCharge < min.totalCharge ? current : min), priceEntry.serviceCharges[0])

	// Error condition: don't consider this cabin for min price. HSC is enabled but not found in the price list.
	if (!minHscEntry) return null

	const hscAmount = minHscEntry?.[options.pricePer === 'cabin' ? 'totalCharge' : 'adultCharge']

	const total = basePrice + hscAmount
	return { basePrice, hscAmount, total }
}
