'use client'

import { isIOption, isIOptionArray, isIOptionGroup } from '@/features/elastic/lib/filterb2b-utils'
import { ArrayFilterType, FILTER_TYPE, IOption, IOptionGroup } from '@/features/elastic/store/types'
import { useElasticFilterState } from '@/features/elastic/store/useElasticFilterState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { CabinIcon, CalendarRangeIcon, Chip, CruiseFilters, DropdownFilter, FiltersIcon, LocationPinIcon, MapIcon, PriceIntervalIcon, RatesIcon, ShipIcon, TagsGroup } from '../uiComponents'

interface SearchCruiseFiltersProps {
	openAllFilters: boolean
	setOpenAllFilters: Dispatch<SetStateAction<boolean>>
	setActiveFiltersIndex: Dispatch<SetStateAction<number>>
}

export const SearchCruiseFilters = ({ openAllFilters, setOpenAllFilters, setActiveFiltersIndex }: SearchCruiseFiltersProps) => {
	const [shouldResetPriceRange, setShouldResetPriceRange] = useState(false)
	const filterListRef = useRef<HTMLDivElement>(null)
	const minPriceRange = 0
	const maxPriceRange = 20000
	const sitecoreState = useSitecoreState((state) => state)
	const elasticFilterState = useElasticFilterState((state) => ({
		options: state.options,
		toggleFilter: state.toggleArrayFilter,
		setPriceRange: state.setPriceRange,
		resetDuration: state.resetDuration,
		resetAllFilters: state.resetAllFilters,
	}))

	const allFiltersLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ALL_FILTERS', 'All filters')
	const embkPortLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PORT_DEP_NEW', 'Embarkation port')
	const durationLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-DURATION_NEW', 'Duration')
	const shipLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-SHIP_NEW', 'Ship')
	const destinationLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-DESTINATION_NEW', 'Destination')
	const cabinTypeLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CABIN_TYPE', 'Cabin type')
	const ratesLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISESRESULTS-ATTRIBUTES', 'Rates')
	const pricesLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_RANGE', 'Price Range')
	const resetFiltersLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-RESET_SELECTION_NEW', 'Reset all filters')

	const embPortOptions = elasticFilterState.options.embkPort
		.filter((e) => e.groupName)
		.sort((a, b) => {
			return a.groupName!.localeCompare(b.groupName!)
		})

	const leftCruiseFiltersSchema = [
		{
			type: 'radio',
			icon: <CalendarRangeIcon />,
			title: durationLabel,
			values: elasticFilterState.options.duration || [],
			onChange: (value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.DURATION, value),
		},
		{
			type: 'select',
			withActiveStatus: false,
			icon: <LocationPinIcon />,
			title: embkPortLabel,
			values: embPortOptions || [],
			onChange: (value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.EMBARK_PORT, value),
		},
		{
			type: 'select',
			withActiveStatus: false,
			icon: <MapIcon />,
			title: destinationLabel,
			values: elasticFilterState.options.area || [],
			onChange: (value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.AREA, value),
		},
		{
			type: 'select',
			withActiveStatus: false,
			icon: <ShipIcon />,
			title: shipLabel,
			values: elasticFilterState.options.ship || [],
			onChange: (value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.SHIP, value),
		},
	]

	const rightCruiseFiltersSchema = [
		{
			type: 'tag',
			icon: <CabinIcon />,
			title: cabinTypeLabel,
			values: elasticFilterState.options.cabinCategory || [],
			onChange: (value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.CABIN_CATEGORY, value),
		},
		{
			type: 'checkbox',
			icon: <RatesIcon />,
			title: ratesLabel,
			values: elasticFilterState.options.rates || [],
			onChange: (value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.RATES, value),
		},
		{
			type: 'range',
			icon: <PriceIntervalIcon />,
			title: pricesLabel,
			range: {
				min: minPriceRange,
				max: maxPriceRange,
				step: 500,
			},
		},
	]

	const handleDeleteOption = (filterType: ArrayFilterType, option: IOption) => {
		if (filterType == FILTER_TYPE.DURATION) {
			elasticFilterState.resetDuration()
		} else {
			elasticFilterState.toggleFilter(filterType, option)
		}
	}

	const filterTypeEntries = useMemo(() => Object.entries(FILTER_TYPE) as [string, ArrayFilterType][], [])
	const priceRangeValues = elasticFilterState.options.priceRange

	const chips = useMemo(() => {
		const newChips: ReactElement[] = []

		filterTypeEntries.forEach(([, filterType]) => {
			const optionValues = elasticFilterState.options[filterType]
			if (Array.isArray(optionValues)) {
				optionValues.forEach((option: IOption | IOptionGroup) => {
					if (isIOptionGroup(option)) {
						option.options?.forEach((innerOption: IOption) => {
							if (innerOption.checked) {
								newChips.push(
									<Chip key={`${filterType}-${innerOption.label}`} onDelete={() => handleDeleteOption(filterType, innerOption)}>
										{innerOption.label}
									</Chip>
								)
							}
						})
					} else if (isIOption(option) && option.checked && option.value !== '') {
						newChips.push(
							<Chip key={`${filterType}-${option.label}`} onDelete={() => handleDeleteOption(filterType, option)}>
								{option.label}
							</Chip>
						)
					}
				})
			}
		})

		if (priceRangeValues && (priceRangeValues.min !== minPriceRange || priceRangeValues.max !== maxPriceRange)) {
			newChips.push(
				<Chip
					key="priceRange"
					onDelete={() => {
						elasticFilterState.setPriceRange(minPriceRange, maxPriceRange)
						setShouldResetPriceRange(true)
					}}
				>
					{`€${priceRangeValues.min} - €${priceRangeValues.max}`}
				</Chip>
			)
		}

		return newChips
	}, [filterTypeEntries, elasticFilterState.options, priceRangeValues, minPriceRange, maxPriceRange])

	// Update active filters index when chips change
	useEffect(() => {
		setActiveFiltersIndex(chips.length)
	}, [chips.length, setActiveFiltersIndex])

	const handleResetAllFilters = () => {
		elasticFilterState.resetAllFilters()
		elasticFilterState.setPriceRange(minPriceRange, maxPriceRange)
		elasticFilterState.resetDuration()
		setShouldResetPriceRange(true)
	}

	return (
		<div className="w-full flex flex-col md:pt-5 pb-6">
			{/** Top Filters **/}
			<div className="hidden relative md:flex w-full justify-center flex-wrap gap-3 z-30">
				<DropdownFilter label={embkPortLabel} leftIcon={<LocationPinIcon />} onChange={(value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.EMBARK_PORT, value)} dropdownItems={embPortOptions || []} hasInput={true} />
				<DropdownFilter label={destinationLabel} leftIcon={<MapIcon />} onChange={(value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.AREA, value)} dropdownItems={elasticFilterState.options?.area || []} />
				<DropdownFilter label={shipLabel} leftIcon={<ShipIcon />} onChange={(value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.SHIP, value)} dropdownItems={elasticFilterState.options?.ship || []} />
				<DropdownFilter label={cabinTypeLabel} leftIcon={<CabinIcon />} onChange={(value: IOption) => elasticFilterState.toggleFilter(FILTER_TYPE.CABIN_CATEGORY, value)} dropdownItems={elasticFilterState.options?.cabinCategory || []} />
				<DropdownFilter label={allFiltersLabel} leftIcon={<FiltersIcon />} negative={true} onClick={() => setOpenAllFilters(!openAllFilters)} />
			</div>
			{/** Expanded Filters **/}
			<div
				className={`bg-white md:px-6 transition-all ${openAllFilters ? '' : 'overflow-hidden'}`}
				style={{
					height: openAllFilters && filterListRef.current ? `${filterListRef.current!.clientHeight}px` : '0px',
				}}
			>
				<div className="w-full flex flex-col md:flex-row gap-6" ref={filterListRef}>
					<div className="flex-1">
						{leftCruiseFiltersSchema.map((el, index) => {
							const highlightedValues = isIOptionArray(el.values) ? el.values.filter((el: IOption) => el.highlighted) : el.values.flatMap((el) => el.options).filter((el: IOption) => el.highlighted)
							return (
								<React.Fragment key={index}>
									<CruiseFilters type={el.type} icon={el.icon} title={el.title} values={el.values} withActiveStatus={el.withActiveStatus} onChange={el.onChange} />
									{el.values && <TagsGroup values={highlightedValues || []} onChange={el.onChange} />}
								</React.Fragment>
							)
						})}
					</div>
					<div className="flex-1">
						{rightCruiseFiltersSchema.map((el, index) => {
							return (
								<React.Fragment key={index}>
									<CruiseFilters type={el.type} icon={el.icon} title={el.title} values={el.values} range={el.range} onChange={el.onChange} handleRangeChange={elasticFilterState.setPriceRange} shouldResetPriceRange={shouldResetPriceRange} setShouldResetPriceRange={setShouldResetPriceRange} />
								</React.Fragment>
							)
						})}
					</div>
				</div>
			</div>

			{chips?.length > 0 && (
				<div className="md:pt-6">
					<div className="hidden md:flex flex-wrap gap-2">{chips}</div>
					<p className="text-sm text-secondary underline pt-3 cursor-pointer w-fit" onClick={handleResetAllFilters}>
						{resetFiltersLabel}
					</p>
				</div>
			)}
		</div>
	)
}
