'use client'

import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { useRouter } from 'next/navigation'
import { DynamicPicture } from '../uiComponents'

const MscLogo = () => {
	const router = useRouter()
	const market = useCurrentMarket()
	const handleRedirectHome = () => router.push(`/${market.locale}/home`)
	return (
		<div onClick={handleRedirectHome} className="cursor-pointer absolute left-1/2 transform -translate-x-1/2 pr-6 md:pr-[85px] desktop-min-1200px:pr-0 desktop-min-1200px:static desktop-min-1200px:translate-x-0">
			<DynamicPicture image="pages/sdl/img/B2B_TA_Logo_neg_header.svg" basepath="websphere-base" alt="MSCBook logo" width={126} height={42} />
		</div>
	)
}

export { MscLogo }
