'use client'

import { IOption, IOptionGroup } from '@/features/elastic/store/types'
import React from 'react'
import { CheckboxGroup, CheckboxProps, DropdownFilter, RadioGroup, RangeSlider, RangeSliderProps, TagProps, TagsGroup } from '../uiComponents'

interface FilterBase<T> {
	type: string
	icon: React.ReactElement
	title: string
	values?: T
	range?: any
	selectedValues?: any
	withActiveStatus?: any
	shouldResetPriceRange?: any
	setShouldResetPriceRange?: any
	onChange?: (...args: any) => void
	handleRangeChange?: any
}

interface RadioValues extends FilterBase<{ id: string; label: string }[]> {
	type: 'radio' | string
}

interface SelectValues extends FilterBase<IOption[] | IOptionGroup[]> {
	type: 'select' | string
}

interface TagValues extends FilterBase<TagProps[]> {
	type: 'tag' | string
	selectedValues: string[]
	onChange?: (...args: any) => void
}

interface CheckboxValues extends FilterBase<CheckboxProps[]> {
	type: 'checkbox'
	onChange: (...args: any) => void
}

interface RangeSliderValues extends FilterBase<any> {
	type: 'range'
	range: RangeSliderProps
	shouldResetPriceRange: boolean
	setShouldResetPriceRange: (value: boolean) => void
	handleRangeChange: () => void
}

type CruiseFiltersProps = RadioValues | SelectValues | TagValues | CheckboxValues | RangeSliderValues

export const CruiseFilters = ({ type, icon, title, values, range, withActiveStatus, onChange, handleRangeChange, shouldResetPriceRange, setShouldResetPriceRange }: CruiseFiltersProps) => {
	// Need to disable es-lint rule because it didn't like the switch case indentation
	/* eslint-disable indent */
	const renderFilter = () => {
		switch (type) {
			case 'radio':
				return <RadioGroup values={values as IOption[]} onChange={onChange} />
			case 'select':
				return <DropdownFilter dropdownItems={values as IOption[] | IOptionGroup[]} onChange={onChange} hasInput={true} withActiveStatus={withActiveStatus} />
			case 'tag':
				return <TagsGroup values={values as IOption[]} onChange={onChange} />
			case 'checkbox':
				return <CheckboxGroup values={values as IOption[]} onChange={onChange} />
			case 'range':
				return <RangeSlider range={range as any} handleRangeChange={handleRangeChange} shouldResetPriceRange={shouldResetPriceRange} setShouldResetPriceRange={setShouldResetPriceRange} />
			default:
				return null
		}
	}
	/* eslint-enable indent */

	return (
		<div className="w-full flex flex-col pt-8">
			<div className="flex items-center gap-2">
				{icon}
				<p className="text-sm text-msc-blue font-semibold whitespace-nowrap">{title}</p>
			</div>
			<div>{renderFilter()}</div>
		</div>
	)
}
