'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { staticGA4ParamsFromUser } from '../analytics/googleAnalyticsHelper'

export type LabelClickedEvent = Record<'event' | 'eventName' | 'label_clicked', string>

export const useGoogleAnalytics = () => {
	const user = useLoggedUser()
	const market = useCurrentMarket()

	const pushEvent = (data: any) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			...staticGA4ParamsFromUser(user, market),
			...data,
		})
	}

	const pushLabelClickedEvent = (event: LabelClickedEvent) => {
		pushEvent(event)
	}

	return { pushEvent, pushLabelClickedEvent }
}
