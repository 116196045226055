'use client'
import { Checkbox } from '@/components/uiComponents'
import { DevTools } from '../configs/devToolsConfigs'
import { useDevToolsState } from '../store/useDevToolsState'

export const BundleNameViewActivator = () => {
	const store = useDevToolsState()

	const handleChangeValue = async () => {
		store.set(DevTools.SHOW_BUNDLE_NAMES, !store.showBundleNames)
	}

	return <Checkbox name="show-bundle-names" label="Show bundle names of promo, news, info, ..." checked={store.showBundleNames} onChange={handleChangeValue} />
}
