'use client'

// Wrapper component for react-select.
// Shows placeholder even when a value is selected.

import Select, { components, CSSObjectWithLabel, GroupBase, Props, ValueContainerProps } from 'react-select'

const customStyles = {
	control: (provided: CSSObjectWithLabel) => ({
		...provided,
		borderRadius: '6px',
	}),
	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		height: '42px',
		borderRadius: '16px',
	}),
	indicatorSeparator: (provided: CSSObjectWithLabel) => ({
		...provided,
		display: 'none',
	}),
	valueContainer: (provided: any) => ({
		...provided,
		position: 'relative',
		overflow: 'visible',
	}),
	singleValue: (provided: any) => ({
		...provided,
		paddingTop: '5px',
		marginLeft: '10px',
		marginRight: '10px',
	}),
}

// Custom ValueContainer to keep placeholder visible
const CustomValueContainer = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: ValueContainerProps<Option, IsMulti, Group>) => {
	const { children, hasValue, selectProps } = props

	return (
		<components.ValueContainer {...props}>
			{hasValue && (
				<div
					style={{
						position: 'absolute',
						top: '2px',
						left: '17px',
						fontSize: '10px',
						color: '#4d4b4b',
						pointerEvents: 'none',
					}}
				>
					{selectProps.placeholder}
				</div>
			)}
			{children}
		</components.ValueContainer>
	)
}

// eslint-disable-next-line prettier/prettier
// prettier-ignore
const FloatingPlaceholderSelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: Props<Option, IsMulti, Group>) => (
	<Select
		{...props}
		styles={customStyles}
		components={{ ValueContainer: CustomValueContainer }}
	/>
)

export { FloatingPlaceholderSelect }
