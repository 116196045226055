import { CabinPrice } from '@/features/elastic/lib/min-price-calculation'
import { PriceList } from '@/features/elastic/lib/pricesb2b-utils'
import { PricesB2BResponse } from '@/features/elastic/model/elastic-search'
import { IOption } from '@/features/elastic/store/types'
import { Step, stepOrder } from '@/features/flyer/configs/steps'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { Destination, Offer } from '../lib/utils'
import { Season } from '../model/season'

// Step interfaces
export interface Steps {
	destination: DestinationStep
	itinerary: ItineraryStep
	priceList: PriceListStep
	agentDetails: AgentDetailsStep
	notes: NotesStep
}

interface DestinationStep {
	avaiableDestinations: IOption[]
	avaiableShips: IOption[]
	destination?: Destination
	imageSrc?: string
	isPolicyAccepted: boolean
	headline?: string
	copy?: string
}

export interface CruiseOption {
	cruiseId: string
	available: boolean
	embarkationDate: string
	disembarkationDate: string
}

interface Itinerary {
	// ship select
	ship?: IOption
	avaiableSeasons: Season[]

	// season select
	season?: Season
	avaiableEmbkPorts: IOption[]

	// embarkation port select
	embarkationPort?: IOption
	pricesB2BResponse?: PricesB2BResponse
	availableCruises: CruiseOption[]

	// date range select
	cruise?: CruiseOption
	itineraryCode?: string
	dateRange?: string
	departureWeekDay?: string
	daysNights?: string
	price?: CabinPrice
}

interface ItineraryStep {
	itineraries: Itinerary[]
}

interface PriceListStep {
	priceList?: PriceList
	replaceWithHeroImage: boolean
	promotionImage?: string
	offer?: Offer
	flightInformation?: string
}

interface AgentDetailsStep {
	logo?: string
	name?: string
	email?: string
	phoneNumber?: string
	socialInfo?: string
	saveAgencyContactData: boolean
}

interface NotesStep {
	additionalNote?: string
}

// Actions interface
export interface FlyerBuilderActions {
	getActiveStep: () => Step
	setActiveStep: (step: number) => void
	areStepsAlreadyFilledAfter: (step: keyof Steps) => boolean
	eraseStepsAfter: (step: keyof Steps) => void
	updateStepData: <K extends keyof Steps>(step: K, data: Partial<Steps[K]>) => FlyerBuilderState
	addItinerary: () => void
	updateItinerary: (index: number, data: Partial<Itinerary>) => FlyerBuilderState
	removeItinerary: (index: number) => void
	setNextButtonEnabled: (enabled: boolean) => void
}

// Main state interface
export interface FlyerBuilderState {
	activeStep: number
	nextButtonEnabled: boolean
	steps: Steps
}

// Initial state
export const flyerBuilderInitialState: FlyerBuilderState = {
	activeStep: 1,
	nextButtonEnabled: false,
	steps: {
		destination: {
			avaiableDestinations: [],
			avaiableShips: [],
			destination: undefined,
			imageSrc: undefined,
			isPolicyAccepted: false,
			headline: undefined,
			copy: undefined,
		},
		itinerary: {
			itineraries: [
				{
					ship: undefined,
					avaiableSeasons: [],

					season: undefined,
					avaiableEmbkPorts: [],

					embarkationPort: undefined,
					pricesB2BResponse: undefined,
					availableCruises: [],

					cruise: undefined,
					itineraryCode: undefined,
					dateRange: undefined,
					departureWeekDay: undefined,
					daysNights: undefined,
					price: undefined,
				},
			],
		},
		priceList: {
			priceList: undefined,
			replaceWithHeroImage: false,
			promotionImage: undefined,
			offer: undefined,
			flightInformation: undefined,
		},
		agentDetails: {
			logo: undefined,
			name: undefined,
			email: undefined,
			phoneNumber: undefined,
			socialInfo: undefined,
			saveAgencyContactData: false,
		},
		notes: {
			additionalNote: undefined,
		},
	},
} as const

const itineraryPriorities: { [key in keyof Required<Itinerary>]: number } = {
	ship: 0,
	avaiableSeasons: 0,

	season: 1,
	avaiableEmbkPorts: 1,

	embarkationPort: 2,
	pricesB2BResponse: 2,
	availableCruises: 2,

	cruise: 3,
	itineraryCode: 3,
	dateRange: 3,
	departureWeekDay: 3,
	daysNights: 3,
	price: 3,
}

const stepsToInit: { [key in keyof Partial<Steps>]?: (keyof Steps)[] } = {
	destination: ['itinerary', 'priceList'],
	itinerary: ['priceList'],
}

// Store implementation
export const createFlyerBuilderState = (initialState: FlyerBuilderState) => {
	return create<FlyerBuilderState & FlyerBuilderActions>()(
		immer((set, get) => ({
			...initialState,

			getActiveStep: () => {
				return stepOrder[get().activeStep - 1]
			},

			setNextButtonEnabled: (enabled: boolean) => {
				set((state) => {
					state.nextButtonEnabled = enabled
				})
			},

			setActiveStep: (step: number) =>
				set((state) => {
					state.activeStep = step
				}),

			areStepsAlreadyFilledAfter: (step) => {
				// Check if at least one of the following steps contains data => if at least one field is filled => if *some* fields are different from the initial state

				// eslint-disable-next-line prettier/prettier
				// prettier-ignore
				return stepsToInit[step]?.some((stepToInit) =>
					Object.entries(get().steps[stepToInit]).some(([key, value]) => {
						const initialValue = flyerBuilderInitialState.steps[stepToInit][key as keyof Steps[keyof Steps]]
						return value && JSON.stringify(value) !== JSON.stringify(initialValue)
					})
				) ?? false
			},

			eraseStepsAfter: (step) => {
				set((state) => {
					stepsToInit[step]?.forEach((stepToInit) => {
						state.steps[stepToInit as keyof Steps] = flyerBuilderInitialState.steps[stepToInit] as any
					})
				})
			},

			updateStepData: (step, data) => {
				set((state) => {
					state.steps[step] = {
						...state.steps[step],
						...data,
					}
				})
				return get()
			},

			addItinerary: () =>
				set((state) => {
					state.steps.itinerary.itineraries.push({
						ship: undefined,
						avaiableSeasons: [],

						season: undefined,
						avaiableEmbkPorts: [],

						embarkationPort: undefined,
						pricesB2BResponse: undefined,
						availableCruises: [],

						cruise: undefined,
						itineraryCode: undefined,
						dateRange: undefined,
						departureWeekDay: undefined,
						daysNights: undefined,
						price: undefined,
					})
				}),

			updateItinerary: (index, data) => {
				const itineraryState: any = {}
				const priorities = Object.keys(data).map((key) => itineraryPriorities[key as keyof ItineraryStep['itineraries'][0]])
				const maxPriority = Math.max(...priorities)
				Object.keys(itineraryPriorities).forEach((key) => {
					const priority = itineraryPriorities[key as keyof ItineraryStep['itineraries'][0]]
					if (priority > maxPriority) {
						itineraryState[key] = flyerBuilderInitialState.steps.itinerary.itineraries[0][key as keyof ItineraryStep['itineraries'][0]]
					}
				})

				set((state) => {
					state.steps.itinerary.itineraries[index] = {
						...state.steps.itinerary.itineraries[index],
						...data,
						...itineraryState,
					}
				})
				return get()
			},

			removeItinerary: (index) =>
				set((state) => {
					state.steps.itinerary.itineraries.splice(index, 1)
				}),
		}))
	)
}
