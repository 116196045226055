'use client'
import React, { useState } from 'react'
import Image from 'next/image'
import infoIcon from '../../../../public/images/infoHoverIcon.png'

interface InfoIconType {
	popoverMessageTitle?: string
	popoverMessageBody?: string
	className?: string
}
const InfoIconWithPopover = ({ popoverMessageTitle, popoverMessageBody, className }: InfoIconType) => {
	const [isMouseOverInfoIcon, setIsMouseOverInfoIcon] = useState<boolean>(false)

	const handleOnMouseEnter = () => {
		setIsMouseOverInfoIcon(true)
	}

	const handleOnMouseLeave = () => {
		setIsMouseOverInfoIcon(false)
	}

	const termsAndConditionspopover = (
		<div role="tooltip" area-label="terms and conditions popover" className={`p-4 w-80 absolute  bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-white shadow-md text-[#1a1a1a] text-sm font-normal leading-6 z-[1000] shadow-md `}>
			<span className="font-bold shadow-2xl">{popoverMessageTitle}</span>
			<p>{popoverMessageBody}</p>
		</div>
	)

	return (
		<div className={className}>
			<span>
				<Image src={infoIcon} alt="information icon" width={16} height={16} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className="inline-block ml-2 pb-1 cursor-pointer"></Image>
				{isMouseOverInfoIcon && termsAndConditionspopover}
			</span>
		</div>
	)
}

export { InfoIconWithPopover }
