// Import all required locales
import { CountryCode, CurrentMarket } from '@/features/markets/models/market'
import { DateTimeFormatter, LocalDate } from '@js-joda/core'
import { de } from 'date-fns/locale/de'
import { enGB } from 'date-fns/locale/en-GB'
import { es } from 'date-fns/locale/es'
import { fr } from 'date-fns/locale/fr'
import { it } from 'date-fns/locale/it'
import { nl } from 'date-fns/locale/nl'
import { pt } from 'date-fns/locale/pt'
import { tr } from 'date-fns/locale/tr'
import { registerLocale } from 'react-datepicker'

// Register all required locales
registerLocale('en-GB', enGB)
registerLocale('es', es)
registerLocale('de', de)
registerLocale('fr', fr)
registerLocale('nl', nl)
registerLocale('pt', pt)
registerLocale('it', it)
registerLocale('tr', tr)

export const localesMap = {
	'en-GB': enGB,
	es: es,
	de: de,
	fr: fr,
	nl: nl,
	pt: pt,
	it: it,
	tr: tr,
}

export type LocaleKey = keyof typeof localesMap

// Market code to locale mapping
const marketLocaleMap: { [key: string]: string } = {
	// Americas
	ARG: 'es',
	BOL: 'es',
	BRA: 'pt',
	CAN: 'en-GB',
	'CA-FR': 'fr',
	'BE-NL': 'nl',
	CHL: 'es',
	COL: 'es',
	CRI: 'es',
	ECU: 'es',
	MEX: 'es',
	NIC: 'es',
	PAN: 'es',
	PRY: 'es',
	PER: 'es',
	URY: 'es',
	USA: 'en-US',
	VEN: 'es',

	// Europe
	AUT: 'de',
	BEL: 'fr',
	BGR: 'en-GB',
	CZE: 'en-GB',
	CRO: 'en-GB',
	CYP: 'en-GB',
	DNK: 'en-GB',
	FIN: 'en-GB',
	FRA: 'fr',
	GER: 'de',
	GBR: 'en-GB',
	GRE: 'en-GB',
	HUN: 'en-GB',
	IRL: 'en-GB',
	ITA: 'it',
	LVA: 'en-GB',
	LTU: 'en-GB',
	HOL: 'nl',
	NOR: 'en-GB',
	POL: 'en-GB',
	POR: 'pt',
	ROM: 'en-GB',
	RUS: 'en-GB',
	SPA: 'es',
	SWE: 'en-GB',
	SWI: 'de',
	TUR: 'tr',
	UKR: 'en-GB',

	// Asia Pacific
	AUS: 'en-GB',
	BGD: 'en-GB',
	CHI: 'en-GB',
	IND: 'en-GB',
	IDN: 'en-GB',
	JPN: 'en-GB',
	KOR: 'en-GB',
	MYS: 'en-GB',
	NZL: 'en-GB',
	SGP: 'en-GB',
	LKA: 'en-GB',
	THA: 'en-GB',

	// Middle East & Africa
	EGY: 'en-GB',
	ISR: 'en-GB',
	JOR: 'en-GB',
	LBN: 'en-GB',
	MAR: 'en-GB',
	QAT: 'en-GB',
	ZAF: 'en-GB',
	TUN: 'en-GB',
	ARE: 'en-GB',
}

export const getLocaleByMarket = (marketCode: string | CurrentMarket): string => {
	if (typeof marketCode === 'object') {
		return marketLocaleMap[marketCode.locale.toUpperCase()] || marketLocaleMap[marketCode.code] || 'en-GB'
	}

	return marketLocaleMap[marketCode] || 'en-GB'
}

export const convertDateString = (dateString: string) => {
	const [day, month, year] = dateString.split('/')
	return `${year}-${month}-${day}`
}

//--------------------------------------------------------------------------------------//
//                               Joda dates with patterns                               //
//--------------------------------------------------------------------------------------//

const datePatterns = {
	standardPattern: DateTimeFormatter.ofPattern('dd/MM/yyyy'),
	isoPattern: DateTimeFormatter.ofPattern('yyyy-MM-dd'),
}

const marketPatterns: Partial<{ [key in CountryCode]: string }> = {
	us: 'MM/dd/yyyy',
}

export const parseDate = (dateString: string, pattern: keyof typeof datePatterns): LocalDate => {
	return LocalDate.parse(dateString, datePatterns[pattern])
}

export const formatDate = (date: LocalDate, pattern: keyof typeof datePatterns): string => {
	return date.format(datePatterns[pattern])
}

export const convertDate = (dateString: string, fromFormat: keyof typeof datePatterns, toFormat: keyof typeof datePatterns): string => {
	const parsedDate = parseDate(dateString, fromFormat)
	return formatDate(parsedDate, toFormat)
}

export const convertIsoToMarket = (dateString: string, market: CurrentMarket): string => {
	const parsedDate = parseDate(dateString, 'isoPattern')
	if (!marketPatterns[market.countryCode]) return formatDate(parsedDate, 'standardPattern')
	const formatter = DateTimeFormatter.ofPattern(marketPatterns[market.countryCode]!)
	return parsedDate.format(formatter)
}

export const convertMarketToIso = (dateString: string, market: CurrentMarket): string => {
	const pattern = marketPatterns[market.countryCode] ? DateTimeFormatter.ofPattern(marketPatterns[market.countryCode]!) : datePatterns.standardPattern
	const parsedDate = LocalDate.parse(dateString, pattern)
	return formatDate(parsedDate, 'isoPattern')
}

export const getWeekDayFromIsoDate = (isoDateString: string, market: CurrentMarket) => {
	const date = new Date(isoDateString)
	return date.toLocaleDateString(getLocaleByMarket(market), { weekday: 'long' })
}
