'use client'

import { Button, UploadIcon } from '@/components/uiComponents'
import { useFlyerBuilderState } from '@/features/flyer/store/FlyerBuilderStateProvider'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import React from 'react'

const AgentLogoUploader = () => {
	const flyerBuilderState = useFlyerBuilderState((state) => ({
		updateStepData: state.updateStepData,
		agentDetails: state.steps.agentDetails,
	}))

	const sitecoreStore = useSitecoreState((state) => state)
	const uploadLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-UPLOAD-LABEL', 'Upload')
	const uploadAgencyLogo = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-UPLOAD-AGENCY-LOGO', 'Upload your agency logo')
	const uploadAgencyDisclaimer = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-UPLOAD-AGENCY-DISCLAIMER', '300dpi min and must not exed 2mb')

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => flyerBuilderState.updateStepData('agentDetails', { logo: reader.result as string })
			reader.readAsDataURL(file)
		}
	}

	return (
		<div className="flex flex-col gap-10 mb-16">
			<h2 className="uppercase font-bold text-sm">{uploadAgencyLogo}</h2>
			<div className="flex flex-col items-center justify-center gap-2 bg-background border-2 border-darker-grey border-dashed py-10">
				<input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} id="file-upload" />
				<label htmlFor="file-upload">
					<Button onClick={() => (document.querySelector('#file-upload') as HTMLInputElement)?.click()}>
						<UploadIcon color="white" /> {uploadLabel}
					</Button>
				</label>
				<p className="text-xs">{uploadAgencyDisclaimer}</p>
			</div>
		</div>
	)
}

export { AgentLogoUploader }
