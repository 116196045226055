export enum Result {
	SUCCESS = 'success',
	USER_LOCKED = 'userLocked',
	PASSWORD_NOT_COMPLIANT = 'passwordNotCompliant',
	CONSENT_TO_ACCEPT = 'consentToAccept',
	NEW_CONSENT_VERSION_TO_ACCEPT = 'newConsentVersionToAccept',
	WRONG_CREDENTIALS = 'wrongCredentials',
	PASSOWORD_EXPIRED = 'passwordExpired',
	FAILURE = 'failure',
	REGISTRATION_REQUIRED = 'registrationRequired',
}

export interface AuthenticateResponse {
	result: Result
	agentData: {
		firstName: string
		lastName: string
		email: string
		agency: string
		market: string
		office: string
		password: string
	}
	additionalData: {
		newConsentVersionToAccept: 'true' | 'false'
	}
	agentProperties: {
		active: 'YES' | 'NO'
		administrator: 'YES' | 'NO'
		agencyType: string
		cannotCancelBookings: 'YES' | 'NO'
		cannotCancelItems: 'YES' | 'NO'
		cannotConfirmBooking: 'YES' | 'NO'
		cannotConfirmGroupBooking: 'YES' | 'NO'
		cannotMakeBookings: 'YES' | 'NO'
		cannotManageGroup: 'YES' | 'NO'
		cannotPrintDocuments: 'YES' | 'NO'
		cannotReviseItems: 'YES' | 'NO'
		cannotViewCharges: 'YES' | 'NO'
		canFitToGroup: 'YES' | 'NO'
		canGuaranteeClearance: 'YES' | 'NO'
		canPayByCC: 'YES' | 'NO'
		canPayByCheck: 'YES' | 'NO'
		canPayByNPS: 'YES' | 'NO'
		cCPaymentMandatoryToConfirm: 'YES' | 'NO'
		cCPaymentMandatoryToConfirmGroup: 'YES' | 'NO'
		language: string
		language2: string
		maskCommissonAndNetAmounts: 'YES' | 'NO'
		subAgent: 'YES' | 'NO'
		viewInvoice: 'YES' | 'NO'
		consortiumCode: string
	}
}
