import React from 'react'

interface UploadIconInterface {
	color?: string
}

export const UploadIcon = ({ color = '#000033' }: UploadIconInterface) => {
	return (
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.5 16V7.85L4.9 10.45L3.5 9L8.5 4L13.5 9L12.1 10.45L9.5 7.85V16H7.5ZM0.5 5V2C0.5 1.45 0.695833 0.979167 1.0875 0.5875C1.47917 0.195833 1.95 0 2.5 0H14.5C15.05 0 15.5208 0.195833 15.9125 0.5875C16.3042 0.979167 16.5 1.45 16.5 2V5H14.5V2H2.5V5H0.5Z" fill={color} />
		</svg>
	)
}
