import { logMethodDuration } from '@/utils/logging/logging'

export function createPerformanceProxy<T extends object>(targetObject: T): T {
	return new Proxy(targetObject, {
		get: (target, prop) => {
			const originalMethod = target[prop as keyof T]

			if (typeof originalMethod === 'function') {
				return async (...args: any[]) => {
					const startTime = performance.now()
					// eslint-disable-next-line @typescript-eslint/ban-types
					const result = await (originalMethod as Function).apply(target, args)
					const endTime = performance.now()
					const duration = endTime - startTime
					logMethodDuration(String(prop), duration)
					return result
				}
			}

			return originalMethod
		},
	})
}
