'use client'

import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { useAsyncEffect } from '@/utils/hooks/useAsyncEffect'
import { useQueryClient } from '@tanstack/react-query'
import React, { createContext, useEffect, useRef } from 'react'
import { createElasticFilterStore, ElasticFilterStoreProps } from './elastic-filter-state'
import { createRatesOptions } from './utils/option-utils'

interface ProviderProps {
	children: React.ReactNode
	initialState: ElasticFilterStoreProps['initialState']
}

type ElasticFilterStore = ReturnType<typeof createElasticFilterStore>

export const ElasticFilterStoreContext = createContext<ElasticFilterStore | null>(null)

export const useElasticFilterLabels = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	return {
		cabin: {
			oceanView: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED-OCVIEW', 'Ocean view'),
			balcony: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED-BALCONY', 'Balcony'),
			interior: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED-INTERIOR', 'Interior'),
			yacht: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-YACHT_CABIN', 'Yacht'),
			suite: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-SUITE_CABIN', 'Suite'),
		},
		duration: {
			anyDur: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ANY_DUR', 'Any duration'),
			nights: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-NIGHTS', 'Nights'),
		},
		rates: {
			CHK_AIR: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_AIR', 'Air'),
			CHK_EXCLUSIVES: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-VOYAGERS_EXCLUSIVES_NAME', 'Voyagers Exclusives'),
			CHK_INCLUDED: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_INCLUDED', 'Included'),
			CHK_SPECIAL: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_SPECIAL', 'Special'),
			CHK_VOYAGES: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_VOYAGES', 'Voyages'),
			'PRICE_TYPE-A_NEW': sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_TYPE-A_NEW', 'Price Type A'),
			'PRICE_TYPE-B_NEW': sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_TYPE-B_NEW', 'Price Type B'),
			'PRICE_TYPE-C_NEW': sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_TYPE-C_NEW', 'Price Type C'),
		},
		ratesToShow: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED_FILTERS', '').split(','),
		price: {
			step: sitecoreStore.getNumber(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_RANGE_STEP', 500),
			from: sitecoreStore.getNumber(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_RANGE_FROM', 0),
			to: sitecoreStore.getNumber(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_RANGE_TO', 20000),
		},
	}
}

export function ElasticFilterProvider({ children, initialState }: ProviderProps) {
	const queryClient = useQueryClient()
	const storeRef = useRef<ElasticFilterStore | null>(null)
	const sitecoreStore = useSitecoreState((state) => state)

	const areaAggregations = Object.values(sitecoreStore.getBundle(TranslationBundles.AREA_AGGREGATIONS))

	if (!storeRef.current) {
		const labels = {
			cabin: {
				oceanView: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED-OCVIEW', 'Ocean view'),
				balcony: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED-BALCONY', 'Balcony'),
				interior: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED-INTERIOR', 'Interior'),
				yacht: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-YACHT_CABIN', 'Yacht'),
				suite: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-SUITE_CABIN', 'Suite'),
			},
			duration: {
				anyDur: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ANY_DUR', 'Any duration'),
				nights: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-NIGHTS', 'Nights'),
			},
			rates: {
				CHK_AIR: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_AIR', 'Air'),
				CHK_EXCLUSIVES: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-VOYAGERS_EXCLUSIVES_NAME', 'Voyagers Exclusives'),
				CHK_INCLUDED: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_INCLUDED', 'Included'),
				CHK_SPECIAL: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_SPECIAL', 'Special'),
				CHK_VOYAGES: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-CHK_VOYAGES', 'Voyages'),
				'PRICE_TYPE-A_NEW': sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_TYPE-A_NEW', 'Price Type A'),
				'PRICE_TYPE-B_NEW': sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_TYPE-B_NEW', 'Price Type B'),
				'PRICE_TYPE-C_NEW': sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_TYPE-C_NEW', 'Price Type C'),
			},
			ratesToShow: sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-ADVANCED_FILTERS', '').split(','),
			price: {
				step: sitecoreStore.getNumber(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_RANGE_STEP', 500),
				from: sitecoreStore.getNumber(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_RANGE_FROM', 0),
				to: sitecoreStore.getNumber(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-PRICE_RANGE_TO', 20000),
			},
		}

		const ratesOptions = createRatesOptions(labels.rates, labels.ratesToShow)
		initialState.options.rates = ratesOptions

		storeRef.current = createElasticFilterStore({
			initialState,
			queryClient,
			additionalData: { areaAggregations },
			labels,
		})
	}

	useAsyncEffect(async () => await storeRef.current?.getState().init(), [])

	useEffect(() => {
		if (storeRef.current) storeRef.current.getState().init()
	}, [])

	return <ElasticFilterStoreContext.Provider value={storeRef.current}>{children}</ElasticFilterStoreContext.Provider>
}
