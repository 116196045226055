'use client'

import { createConfigForAuthSession, createConfigForHeaderMenu } from '@/features/caching/config/tanstack'
import { persister } from '@/utils/lib/get-query-client'
import { defaultShouldDehydrateQuery } from '@tanstack/query-core'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { persistQueryClientSave } from '@tanstack/react-query-persist-client'
import { useEffect, useState } from 'react'

export const usePersistentHeaderMenu = () => {
	const session = useQuery(createConfigForAuthSession())
	const queryClient = useQueryClient()
	const tanstackConfigForHeaderMenuUncached = createConfigForHeaderMenu({ cache: false })
	const _menu = useQuery(tanstackConfigForHeaderMenuUncached)
	const [hasRun, setHasRun] = useState(false)

	useEffect(() => {
		queryClient.refetchQueries(tanstackConfigForHeaderMenuUncached)
	}, [session.data?.user, queryClient])

	if (!persister || !_menu.data) return
	const menu = _menu.data

	if (!!session.data?.user && !hasRun) {
		persistQueryClientSave({
			queryClient,
			persister,
			dehydrateOptions: {
				shouldDehydrateQuery: (query) => {
					const isHeaderMenuQuery = query.queryKey[0] === tanstackConfigForHeaderMenuUncached.queryKey[0]
					return isHeaderMenuQuery && defaultShouldDehydrateQuery(query)
				},
			},
		})
		setHasRun(true)
	}

	return menu
}
