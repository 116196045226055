import { useContext } from 'react'
import { useStore } from 'zustand'
import { ElasticFilterStoreContext } from './ElasticFilterProvider'
import { ElasticFilterState } from './types'

export function useElasticFilterState<T>(selector: (state: ElasticFilterState) => T): T {
	const store = useContext(ElasticFilterStoreContext)

	if (!store) throw new Error('Missing ElasticFilterProvider')
	return useStore(store, selector)
}
