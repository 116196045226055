'use client'

import { CurrentMarket } from '@/features/markets/models/market'
import { useQuery } from '@tanstack/react-query'
import { createConfigForCurrentMarket } from '../config/tanstack'

/**
 * Get the current market from the tanstack cache (client side)
 * @returns the current market otherwise raise an error
 */
export default function useCurrentMarket(): CurrentMarket {
	const { data } = useQuery(createConfigForCurrentMarket())

	if (!data) throw new Error('No current market found')
	return data
}
