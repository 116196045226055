import { createLoggingProxy } from '../services/actions/proxies/logging-proxy'
import { createPerformanceProxy } from '../services/actions/proxies/performance-proxy'
import { createProxiedActions } from '../services/services'
import * as Cookies from './actions/cookies'
import * as Headers from './actions/headers'
import * as JSONCookies from './actions/json-cookies'
import * as TextCookies from './actions/text-cookies'
import * as WebsphereCookies from './actions/websphere-cookies'

const HttpHeadersService = createProxiedActions<typeof JSONCookies & typeof TextCookies & typeof WebsphereCookies & typeof Cookies & typeof Headers>({
	modules: [
		JSONCookies,
		TextCookies,
		WebsphereCookies,
		Cookies,
		Headers,
	],
	proxies: [
		createLoggingProxy,
		createPerformanceProxy,
	],
})

export default HttpHeadersService
