'use client'

import { createContext, useContext, useRef } from 'react'
import { StoreApi, useStore } from 'zustand'
import { createDevToolsStore, DevToolsState, DevToolsStateFunctions } from './dev-tools-state'

interface DevToolsProviderProps {
	children: React.ReactNode
	enabled: boolean
}

const DevToolsStoreContext = createContext<StoreApi<DevToolsState & DevToolsStateFunctions> | null>(null)

export function DevToolsProvider(props: DevToolsProviderProps) {
	const storeRef = useRef<StoreApi<DevToolsState & DevToolsStateFunctions> | null>(null)

	if (!storeRef.current) {
		storeRef.current = createDevToolsStore(props.enabled)
	}

	return <DevToolsStoreContext.Provider value={storeRef.current}>{props.children}</DevToolsStoreContext.Provider>
}

export function useDevToolsState() {
	const store = useContext(DevToolsStoreContext)

	if (!store) throw new Error('useDevToolsState must be used within DevToolsProvider')

	return useStore(store)
}
