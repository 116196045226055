import { useEffect, useState } from 'react'

/**
 * Hook to check if the viewport is desktop size
 */
export const useViewportType = () => {
	const [viewport, setViewport] = useState<'isMobile' | 'isTablet' | 'isDesktop' | 'isDesktopZoomed' | 'isDesktopLarge' | 'isDesktopXL'>('isDesktop')

	const handleResize = () => {
		if (window.innerWidth < 768) {
			setViewport('isMobile')
		} else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
			setViewport('isTablet')
		} else if (window.innerWidth >= 1024 && window.innerWidth < 1280) {
			setViewport('isDesktop')
		} else if (window.innerWidth >= 1280 && window.innerWidth < 1350) {
			setViewport('isDesktopZoomed')
		} else if (window.innerWidth >= 1350 && window.innerWidth < 1800) {
			setViewport('isDesktopLarge')
		} else {
			setViewport('isDesktopXL')
		}
	}

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return viewport
}
